<div class="container">
  <div class="row" *ngIf="loading">
    <div class="col-lg-12">
      <div class="mx-auto text-center">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
  <div class="row justify-center p-b-40 mt-4" *ngIf="!loading">
    <div class="col-lg-9">
      <h4 *ngIf="isEdit;else add" class="bold text-blue">Edit item of a <span class="font-33">{{collectionName}}</span> Collection</h4>
      <ng-template #add><h4 class="bold text-blue">Add item to a <span class="font-33">{{collectionName}}</span> Collection</h4></ng-template>

      <hr />
      <form [formGroup]="collectionItemForm" (ngSubmit)="oncollectionItemSubmit()">
        <div class="form-group">
          <label for="name">Name *</label>
          <input type="text" id="name" formControlName="name" class="form-control"
            [ngClass]="{ 'is-invalid': (submitted && f.name.errors) || (f.name.touched && f.name.errors) }"/>
          <div *ngIf="(submitted && f.name.errors) || (f.name.touched && f.name.errors)" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Name is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="name">Item Image *</label>
          <input
          formControlName="image"
          (change)="handleFileInput($event?.target?.files, 'image')"
          accept=".png, .jpg, .jpeg"
            type="file"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.image.errors && !isEdit }"
          />
          <div *ngIf="submitted && !isEdit && f.image.errors" class="invalid-feedback">
            Image is required
          </div>
        </div>
        <div class="form-group">
          <label for="collection_item_type_id">Category *</label>
          <ng-select formControlName="collection_item_type_id" placeholder="Select category" [items]="categories"
            bindLabel="name" bindValue="id" [ngClass]="{
              'is-invalid': (submitted && f.collection_item_type_id.errors)
            }">
          </ng-select>
          <div *ngIf="submitted && f.collection_item_type_id.errors" class="invalid-feedback">
            <div *ngIf="f.collection_item_type_id.errors.required">
              Category is required
            </div>
          </div>

        </div>
        <div class="form-group">
          <label for="description">Description *</label>
          <textarea type="text" maxlength="600" formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.description.errors }">
          </textarea>
          <div class="mt-1">
          <label class="float-right">Characters Remaining: {{f.description.value.length}}/600</label>
          </div>
          <div *ngIf = "(submitted && f.description.errors) || (f.description.touched && f.description.errors)" class="invalid-feedback">
            The description must be a string.
          </div>
        </div>

        <div class="form-group">
          <label for="edition">Edition *</label>
          <input type="text" formControlName="edition" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.edition.errors }" />
          <div *ngIf = "(submitted && f.edition.errors) || (f.edition.touched && f.edition.errors)" class="invalid-feedback">
            The edition must be a string.
          </div>
        </div>
        <div class="form-group">
          <label for="graded">Graded *</label>
          <input type="text" formControlName="graded" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.graded.errors }" />
          <div *ngIf = "(submitted && f.graded.errors) || (f.graded.touched && f.graded.errors)" class="invalid-feedback">
            The graded must be a string.
          </div>
        </div>

        <div class="form-group">
          <label for="year">Year *</label>

          <select formControlName="year" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.year.errors }">
            <option *ngFor="let year of range" value="{{year}}">{{year}}</option>
          </select>
          <div *ngIf="(submitted && f.year.errors) || (f.year.touched && f.year.errors)" class="invalid-feedback">
            <div *ngIf="f.year.errors.required">Year is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="population">Population *</label>
          <input type="number" formControlName="population" class="form-control" min = "0" (keypress) = "onKeyPress($event)"  [ngClass]="{ 'is-invalid': submitted && f.population.errors }"/>
          <div *ngIf="(submitted && f.population.errors) || (f.population.touched && f.population.errors)" class="invalid-feedback">
            The population must be a integer.
          </div>
        </div>
        <div class="form-group">
          <label for="population">Publisher *</label>
          <input type="text" formControlName="publisher" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.publisher.errors }"/>
          <div *ngIf="(submitted && f.publisher.errors) || (f.publisher.touched && f.publisher.errors)" class="invalid-feedback">
            The publisher name must be a string.
          </div>
        </div>
        <div class="form-group">
          <label for="price">Price *</label>
          <input type="number" formControlName="price" id="price" class="form-control"
            [ngClass]="{ 'is-invalid': (submitted && f.price.errors) || (f.price.touched && f.price.errors) }" min = "1" (keypress) = "onKeyPress($event)"/>
          <div *ngIf="(submitted && f.price.errors) || (f.price.touched && f.price.errors) " class="invalid-feedback">
            <div *ngIf="f.price.errors.required">Price is required</div>
          </div>
          <div *ngIf="isPriceZero && submitted" class="text-danger mt-1">
            <span>Price must be greater than 0</span>
          </div>
        </div>
        <div class="form-group">
          <label for="available_for_sale">Sale Status *</label>
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" [value]="AvailableForSale.OnSale" id="yes"
              formControlName="available_for_sale" [ngClass]="{ 'is-invalid': (submitted && f.available_for_sale.errors) }" >
            <label class="custom-control-label" for="yes">Available for sale</label>
          </div>

          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="no" [value]="AvailableForSale.NotForSale"
              formControlName="available_for_sale" [ngClass]="{ 'is-invalid': (submitted && f.available_for_sale.errors) }" checked>
            <label class="custom-control-label" for="no">Not for sale</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="auction" [value]="AvailableForSale.OnAuction"
              formControlName="available_for_sale" [ngClass]="{ 'is-invalid': (submitted && f.available_for_sale.errors) }" checked />
            <label class="custom-control-label" for="auction">On auction</label>
          </div>
          <div *ngIf="submitted && f.available_for_sale.errors" class="invalid-feedback">
            <div *ngIf="f.available_for_sale.errors.required">
              Please select any status.
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="f.available_for_sale.value === AvailableForSale.OnSale">
          <label>Sale start date</label>
          <app-datetime-picker formControlName="available_at" name="available_at"
            [isError]="submitted && f.available_at.errors">
          </app-datetime-picker>
          <div *ngIf="submitted && f.available_at.errors" class="invalid-feedback display-block">
            <ng-container *ngIf="f.available_at.errors.required">
              Sale start date is required and should be in 'dd/mm/yyyy hh:mm:ss' format
            </ng-container>
          </div>
          <div *ngIf="isAvailAtGreater" class="invalid-feedback display-block">
              The available_at date and time must be  greater than current date and time .
          </div>
        </div>
        <ng-container *ngIf="f.available_for_sale.value === AvailableForSale.OnAuction">
          <div class="form-group">
            <label>Auction start date</label>
            <app-datetime-picker formControlName="start_date" name="start_date"
              [isError]="submitted && f.start_date.errors">
            </app-datetime-picker>
            <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback display-block">
              <ng-container *ngIf="f.start_date.errors.required">
                Auction start date is required and should be in 'dd/mm/yyyy hh:mm:ss' format
              </ng-container>
            </div>
            <div *ngIf="isStartDateGreater" class="invalid-feedback display-block">
              The Start date and time  must be  greater than current date and time  .
           </div>
          </div>
          <div class="form-group">
            <label>Auction end date</label>
            <app-datetime-picker formControlName="end_date" name="end_date" [isError]="submitted && f.end_date.errors">
            </app-datetime-picker>
            <div *ngIf="submitted && f.end_date.errors" class="invalid-feedback display-block">
              <ng-container *ngIf="f.end_date.errors.required">
                Auction end date is required and should be in 'dd/mm/yyyy hh:mm:ss' format
              </ng-container>
            </div>
            <div *ngIf="isEndDateGreater" class="invalid-feedback display-block">
              The End date and time must be  greater than current date and time .
           </div>
          </div>

        </ng-container>
        <div class="form-group">
          <button [disabled]="loading" class="btn btn-primary" *ngIf = "!isEdit">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Add
          </button>
        </div>
        <div class="form-group" *ngIf = "isEdit">
          <button [disabled]="loading" class="btn btn-primary" (click) = "oncollectionItemUpdate()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
