import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MoralisService } from '../../services/moralis.service';

@Component({
  selector: 'app-moralis-card',
  templateUrl: './moralis-card.component.html',
  styleUrls: ['./moralis-card.component.scss'],
})
export class MoralisCardComponent implements OnInit {
  @Input() data: any = {};
  @Input() router;

  digiAddressMatic = environment.maticCoinContractAddresses.digiAddressMatic;
  usdcMaticAddress = environment.maticCoinContractAddresses.usdcAddressMatic;
  usdtMaticAddress = environment.maticCoinContractAddresses.usdtAddressMatic;
  maticCoinAddress = environment.maticCoinContractAddresses.maticCoinAddress;
  owner: string;
  address: string;
  ownerUsername: string;
  auctionOwner: string;
  physical: string;
  network: string;
  image = '/assets/images/cards/loading.png';
  backImage = '/assets/images/cards/loading.png';
  name = '...';
  description = '...';
  tokenAddress;

  constructor(private moralis: MoralisService) {}

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.loadOffChainData();
  }

  async loadOffChainData(): Promise<void> {
    console.log(this.data);
    
    if (this.data) {
      if (
        this.data.tokenData &&
        this.data.tokenData.image &&
        this.data.tokenData.image.changingThisBreaksApplicationSecurity &&
        this.data.tokenData.image.changingThisBreaksApplicationSecurity.indexOf(
          'ipfs://'
        ) != -1
      ) {
        const rep =
          this.data.tokenData.image.changingThisBreaksApplicationSecurity.replace(
            /^ipfs?:\/\//,
            ''
          );
        this.data.tokenData.image = `https://digible.mypinata.cloud/ipfs/${rep}`;
      }
      if (
        this.data.tokenData &&
        this.data.tokenData.image &&
        this.data.tokenData.image.changingThisBreaksApplicationSecurity &&
        this.data.tokenData.image.changingThisBreaksApplicationSecurity.indexOf(
          'ipfs://'
        ) != 1
      ) {
        this.data.tokenData.image =
          this.data.tokenData.image.changingThisBreaksApplicationSecurity;
      }
      if (this.data.tokenData && this.data.tokenData.attributes) {
        this.physical = this.data.tokenData.attributes.find(
          (x) => x.trait_type === 'Physically Backed'
        )?.value;
      }
      if (this.data.pendingAuction) {
        const card = await this.moralis.getNftDataFromMoralis(
          this.data.nftAddress,
          this.data.id,
          this.data.network
        );
        if (card.tokenData) {
          this.physical = card.tokenData.attributes.find(
            (x) => x.trait_type === 'Physically Backed'
          )?.value;
        }
        const newObj = { ...this.data, ...card };
        this.data = newObj;
      }
    }
  }
}
