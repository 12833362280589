<div class="container page-top">
  <div class="row">
    <div class="col-md-8 col-lg-9 p-t-10 p-b-6 mobile-hidden">
      <div class="section-title">
        <span class="icon binoculars"></span>
        <span>For Sale</span>
      </div>
    </div>
    <div class="col-md-4 col-lg-3 right" *ngIf="!showSwitchToEthereum">
      <ng-select
        placeholder="Filter By"
        [items]="filterBy"
        bindLabel="name"
        bindValue="id"
        (change)="changeFilter()"
        [(ngModel)]="typeFilter"
      >
      </ng-select>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>
  <div class="row justify-center" *ngIf="showSwitchToEthereum">
    <div class="col-lg-12 text-center mt-4">
      <p>
        This section of the dApp only works if you connect your wallet to the
        Ethereum Network.
      </p>
    </div>
    <div class="mx-auto text-center mt-2">
      <button class="btn btn-primary btn-menu" (click)="switchToEthereum()">
        <span class="btn-title">Switch to Ethereum</span>
      </button>
    </div>
  </div>
  <div *ngIf="!showSwitchToEthereum">
    <div class="row" *ngIf="nftList == null">
      <div class="col">
        <div class="mx-auto text-center mt-4 mb-4">
          <app-loading></app-loading>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="nftList && nftList.length == 0">
      <div class="col">
        <div class="mx-auto text-center mt-4 mb-4">
          <b>There are no NFTs for sale at this moment. Come back soon!</b>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="nftList != null">
      <div
        *ngFor="let nft of nftList"
        class="col-xl-3 col-lg-4 col-md-6 col-sm-12"
      >
        <app-digi-card
          class="c-card-container"
          [data]="nft"
        ></app-digi-card>
      </div>
    </div>

    <!-- <div class="row center p-t-10">
      <div class="col-lg-12">
        <button *ngIf="loading" class="btn btn-primary mt-4 mb-4">. . .</button>
        <button
          *ngIf="!loading && nftList != null && !endReached"
          class="btn btn-primary mt-4 mb-4"
          (click)="loadMore()"
        >
          Load more
        </button>
      </div>
    </div> -->
  </div>
</div>
