import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MarketplaceService } from 'src/app/services/marketplace.service';
import { OffchainService } from 'src/app/services/offchain.service';
import { NftService } from 'src/app/services/nft.service';
import { WalletService } from 'src/app/services/wallet.service';
import { Network } from 'src/app/types/network.enum';
import { DigiCard } from 'src/app/types/digi-card.types';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-explorer',
  templateUrl: './for-sale.component.html',
  styleUrls: ['./for-sale.component.scss'],
})
export class ForSaleComponent implements OnInit {
  static nftListCached: DigiCard[] = null;
  static cacheUntil: Date = null;
  static lastOffset: number;
  showSwitchToEthereum;
  nftList: DigiCard[] = null;
  showSwitchToMatic = false;
  network: Network;

  loading = false;
  currentOffset = 0;
  endReached = false;
  readonly limit = 44;
  typeFilter = 'ALL';
  unfilteredNftList: DigiCard[] = null;
  filterBy = [
    { name: 'All', id: 'ALL' },
    { name: 'Price Ascending', id: 'PRICE UP' },
    { name: 'Price Descending', id: 'PRICE DOWN' },
  ];

  constructor(
    private readonly nft: NftService,
    private readonly market: MarketplaceService,
    private readonly cdr: ChangeDetectorRef,
    private readonly wallet: WalletService,
    private readonly offchain: OffchainService
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.checkNetwork();
  }

  // TODO: Filtering in for sale. Filter by price and more.
  changeFilter(): void {
    this.loading = true;

    setTimeout(async () => {
      if (this.typeFilter === 'ALL') {
        this.nftList = this.unfilteredNftList;
        this.loading = false;
        return;
      }
      if (this.typeFilter === 'PRICE UP') {
        this.nftList = this.nftList.sort((l, r) => l.price - r.price);
        this.loading = false;
        return;
      }
      if (this.typeFilter === 'PRICE DOWN') {
        this.nftList = this.nftList.sort((l, r) => r.price - l.price);
        this.loading = false;
        return;
      }
      const filteredList = [];
      for (const nft of this.unfilteredNftList) {
        let cached = localStorage.getItem('is_physical_' + nft.id);
        if (cached === undefined) {
          cached = (await this.offchain.getNftData(nft.id, nft.network)).physical
            ? '1'
            : '0';
          localStorage.setItem('is_physical_' + nft.id, cached);
        }
        if (this.typeFilter === 'PHYSICAL') {
          if (cached === '1') {
            filteredList.push(nft);
          }
        } else {
          if (cached === '0') {
            filteredList.push(nft);
          }
        }
      }
      this.nftList = filteredList;
      if (this.nftList.length === 0 && !this.endReached) {
        this.loadMore();
      }
      this.loading = false;
    }, 200);
  }

  async checkNetwork(): Promise<void> {
    const network = await this.wallet.getNetwork();
    this.cdr.detectChanges();
  }

  async loadData(): Promise<void> {
    /* if (
      ForSaleComponent.cacheUntil > new Date() &&
      ForSaleComponent.nftListCached
    ) {
      this.nftList = ForSaleComponent.nftListCached;
      this.currentOffset = ForSaleComponent.lastOffset;
      return;
    } */
    this.currentOffset = 0;
    this.endReached = false;
    this.nftList = await this.market.getLastSales(this.limit);
/*     const nftList = [];
    nftListMatic.map((item:any) => {
      item.network = 'MATIC'
      nftList.push(item)
    }) */
    // this.nftList = nftList
    this.unfilteredNftList = this.nftList;
    console.log(this.unfilteredNftList);
    
    // this.setCache();
    this.cdr.detectChanges();
  }

  async switchToEthereum(): Promise<void> {
    await this.wallet.switchToEth();
  }

  async loadMore(): Promise<void> {
    this.loading = true;
    this.currentOffset = this.currentOffset + this.limit;
    const newNfts:any = await this.market.getLastSales(this.limit, this.currentOffset);
    console.log(newNfts);
    
    const newNftList = [];
    newNfts.sales.map((item:any) => {
      item.network = 'MATIC'
      newNftList.push(item)
    })

    if (newNfts.total < this.limit + this.currentOffset) {
      this.endReached = true;
    }
    this.nftList = [...this.nftList, ...newNftList];
    console.log(this.nftList);
    this.setCache();
    this.loading = false;
  }

  private setCache(): void {
    ForSaleComponent.nftListCached = this.nftList;
    ForSaleComponent.lastOffset = this.currentOffset;
    const date = new Date();
    date.setMinutes(date.getMinutes() + 30);
    ForSaleComponent.cacheUntil = date;
  }
}
