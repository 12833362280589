<a
  *ngIf="data.is20Item === false"
  [routerLink]="
    '/details/' + data.network + '/' + data.token_id + '/' + data.token_address
  "
  class="c-card"
  [ngClass]="{ auction: auction === true, back: backSide === true }"
>
  <div class="d-flex flex-row items-center p-2">
    <div class="c-collection__circle">
      <img
        *ngIf="ownerImage"
        width="100%"
        height="100%"
        [src]="ownerImage"
        alt=""
      />
      <img
        *ngIf="!ownerImage"
        width="100%"
        height="100%"
        src="/assets/images/home/home-1.png"
        alt=""
      />
    </div>
    <span *ngIf="ownerUsername" class="c-card__info">{{
      ownerUsername | truncate: [10, "..."]
    }}</span>
    <span *ngIf="!ownerUsername" class="c-card__info"
      >{{ owner | slice: 0:3 }}...{{ owner | slice: 39:64 }}</span
    >
    <div
      class="ml-auto"
      *ngIf="
        owner !== address &&
        price &&
        !auction &&
        view !== 'details' &&
        view !== 'profile' &&
        view !== 'create-sell' &&
        owner !== '0x000000000000000000000000000000000000dEaD'
      "
    >
      <span>BUY NOW</span>
    </div>
    <div
      class="ml-auto"
      *ngIf="
        owner !== address &&
        !price &&
        !auction &&
        view !== 'details' &&
        view !== 'profile' &&
        view !== 'create-sell' &&
        owner !== '0x000000000000000000000000000000000000dEaD'
      "
    >
      <span class="small">NOT FOR SALE</span>
    </div>

    <!-- <div
      class="ml-auto"
      *ngIf="
        owner === address &&
        view !== 'profile' &&
        view !== 'create-sell' &&
        view !== 'details'
      "
    >
      <span>It's Yours</span>
    </div> -->
    <!--  <div
      *ngIf="
        owner !== address &&
        auction &&
        view !== 'details' &&
        view !== 'create-sell' &&
        view !== 'profile' &&
        owner !== '0x000000000000000000000000000000000000dEaD'
      "
    >
      <button>
        <span *ngIf="price != null && !auction">BUY NOW</span>
        <span *ngIf="price != null && auction">BID NOW</span>
        <span *ngIf="price == null">DETAILS</span>
      </button>
    </div> -->
    <span
      class="ml-auto"
      *ngIf="owner === '0x000000000000000000000000000000000000dEaD' && physical"
      >CARD CLAIMED
    </span>
    <span
      class="ml-auto"
      *ngIf="
        owner === '0x000000000000000000000000000000000000dEaD' && !physical
      "
      >CARD BURNED
    </span>

    <button
      *ngIf="view === 'details'"
      class="invisible-button ml-auto"
      (click)="calledimageZoomZoom()"
    >
      <span class="icon search"></span>
    </button>
  </div>
  <figure class="c-card__figure">
    <lib-ngx-image-zoom
      *ngIf="!isVideo && view === 'details'"
      class="c-card__figure__video"
      [zoomMode]="'click'"
      [enableLens]="true"
      [thumbImage]="data.tokenData.image"
      [fullImage]="data.tokenData.image"
    ></lib-ngx-image-zoom>
    <img
      *ngIf="data.tokenData && data.tokenData.image"
      class="c-card__figure__video"
      [src]="data.tokenData.image"
      alt="image"
    />

    <video
      *ngIf="isVideo"
      oncanplay="this.muted = true"
      id="video-plyer"
      ref="videoRef"
      class="c-card__figure__video bg-video"
      autoplay
      muted
      playsinline
      loop
      [src]="data.tokenData.image"
    ></video>
    <div
      *ngIf="data.auction && data.auctionOrSaleData.endDate"
      class="c-card__countdown"
    >
      <countdown #cd [config]="config"></countdown>
      <span class="ml-2">Left</span>
      <span class="icon auctions p-0"></span>
    </div>
  </figure>
  <figure class="c-card__figure" *ngIf="backSide">
    <lib-ngx-image-zoom
      *ngIf="!isBackVideo && view === 'details'"
      class="c-card__figure__video"
      [zoomMode]="'click'"
      [enableLens]="true"
      [thumbImage]="backImage"
      [fullImage]="backImage"
    ></lib-ngx-image-zoom>
    <video
      *ngIf="isBackVideo"
      ref="videoRef"
      class="c-card__figure__video"
      autoplay
      muted
      playsinline
      loopO
      [src]="backImage"
    ></video>
    <p>{{ auctionOrSaleData }}</p>
    <div *ngIf="auctionOrSaleData && endDate" class="c-card__countdown">
      <countdown #cd [config]="config"></countdown>
      <span class="ml-2">Left</span>
      <span class="icon auctions p-0"></span>
    </div>
  </figure>
  <div *ngIf="view !== 'details'" class="c-card__body">
    <div class="c-card__top">
      <h1
        *ngIf="data.tokenData && data.tokenData.name"
        class="c-card__title m-0"
      >
        <span>{{ data.tokenData.name }}</span>
      </h1>
      <div
        class="c-card__price"
        *ngIf="
          data.auctionOrSaleData &&
          data.auctionOrSaleData.paymentCurrency &&
          data.price > -1
        "
      >
        <span class="mr-2">${{ data.price }}</span>
        <span
          *ngIf="
            data.auctionOrSaleData.paymentCurrency.toLowerCase() ===
            digiAddressMatic.toLowerCase()
          "
          class="icon digi-currency"
        ></span>
        <span
          *ngIf="
            data.auctionOrSaleData.paymentCurrency.toLowerCase() ===
            usdcMaticAddress.toLowerCase()
          "
          class="icon usdc"
        ></span>
        <span
          *ngIf="
            data.auctionOrSaleData.paymentCurrency.toLowerCase() ===
            usdtMaticAddress.toLowerCase()
          "
          class="icon usdt"
        ></span>
        <span
          *ngIf="
            data.auctionOrSaleData.paymentCurrency.toLowerCase() ===
            linkMaticAddress.toLowerCase()
          "
          class="icon chainlink"
        ></span>
        <span
          *ngIf="
            data.auctionOrSaleData.paymentCurrency.toLowerCase() ===
            maticCoinAddress.toLowerCase()
          "
          class="icon polygon"
        ></span>
      </div>
      <div
        class="c-card__price"
        *ngIf="
          data.auctionOrSaleData &&
          data.auctionOrSaleData.paymentcurrencyAddress &&
          data.price > -1
        "
      >
        <span class="mr-2">${{ data.price }}</span>
        <span
          *ngIf="
            data.auctionOrSaleData.paymentcurrencyAddress.toLowerCase() ===
            digiAddressMatic.toLowerCase()
          "
          class="icon digi-currency"
        ></span>
        <span
          *ngIf="
            data.auctionOrSaleData.paymentcurrencyAddress.toLowerCase() ===
            usdcMaticAddress.toLowerCase()
          "
          class="icon usdc"
        ></span>
        <span
          *ngIf="
            data.auctionOrSaleData.paymentcurrencyAddress.toLowerCase() ===
            usdtMaticAddress.toLowerCase()
          "
          class="icon usdt"
        ></span>
        <span
          *ngIf="
            data.auctionOrSaleData.paymentcurrencyAddress.toLowerCase() ===
            linkMaticAddress.toLowerCase()
          "
          class="icon chainlink"
        ></span>
        <span
          *ngIf="
            data.auctionOrSaleData.paymentcurrencyAddress.toLowerCase() ===
            maticCoinAddress.toLowerCase()
          "
          class="icon polygon"
        ></span>
      </div>
    </div>
    <div
      *ngIf="data.tokenData && data.tokenData.description"
      class="c-card__description"
    >
      <p>{{ data.tokenData.description | truncate: [120, "..."] }}</p>
    </div>
    <div
      *ngIf="data.tokenData && !data.tokenData.description"
      class="c-card__description"
    >
      <p>No description has been created for this NFT.</p>
    </div>
  </div>
  <div class="c-card__footer">
    <div class="c-card__footer-inner">
      <div *ngIf="physical === 'Yes'" class="d-flex flex-row">
        <span class="c-card__safe icon safe mr-2"></span>
        <span class="c-card__info">Physically backed</span>
      </div>
      <div
        *ngIf="view !== 'details' && data.network === 'ETH'"
        class="d-flex flex-row"
      >
        <span class="c-card__safe icon ethereum mr-2"></span>
        <span class="c-card__info">ERC-20</span>
      </div>
      <div
        *ngIf="view !== 'details' && data.network === 'MATIC'"
        class="d-flex flex-row"
      >
        <span class="c-card__safe icon polygon mr-2"></span>
        <span class="c-card__info">MATIC</span>
      </div>
    </div>
  </div>
</a>
<a
  *ngIf="data.is20Item === true"
  class="c-card"
  [routerLink] = "['/details/offchain', data.network, data.id, data.token_address]"
  [queryParams]="{
    collectionId: data?.collection_id,
    is20Collection: data?.is20Item
  }"
>
  <div class="d-flex flex-row items-center p-2">
    <div class="c-collection__circle">
      <img
        *ngIf="data && data.collection.logo_path"
        width="100%"
        height="100%"
        [src]="data.collection.logo_path"
        alt=""
      />
      <img
        *ngIf="data && !data.collection.logo_path"
        width="100%"
        height="100%"
        src="/assets/images/home/home-1.png"
        alt=""
      />
    </div>
    <span *ngIf="data.collection.name" class="c-card__info">{{
      data.collection.name | truncate: [10, "..."]
    }}</span>
  </div>
  <figure class="c-card__figure">
    <img class="c-card__figure__video" [src]="data.image_path" alt="image" />
  </figure>
  <div class="c-card__body">
    <div class="c-card__top">
      <h1 class="c-card__title">
        <span>{{ data.name }}</span>
      </h1>
      <div class="c-card__price" *ngIf="data.price">
        <span class="mr-2">${{ data.price }} </span>
      </div>
    </div>
    <div class="c-card__description">
      <p *ngIf="data && data.description">
        {{ data.description | truncate: [120, "..."] }}
      </p>
    </div>
    <div *ngIf="data && !data.description" class="c-card__description">
      <p>No description has been created for this NFT.</p>
    </div>
  </div>
  <!-- <div class="c-card__footer">
    <div class="c-card__footer-inner">
      <div *ngIf="physical" class="d-flex flex-row">
        <span class="c-card__safe icon safe mr-2"></span>
        <span class="c-card__info">Physical Item</span>
      </div>
      <div *ngIf="!physical" class="d-flex flex-row">
        <span class="c-card__safe icon nft mr-2"></span>
        <span class="c-card__info">NFT ONLY</span>
      </div>
      <div *ngIf="isInEth" class="d-flex flex-row">
        <span class="c-card__safe icon ethereum mr-2"></span>
        <span class="c-card__info">ERC-20</span>
      </div>
      <div *ngIf="isInMatic" class="d-flex flex-row">
        <span class="c-card__safe icon polygon mr-2"></span>
        <span class="c-card__info">MATIC</span>
      </div>
    </div>
  </div> -->
</a>
