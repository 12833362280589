<div class="container page-top">
  <div class="row c-page-title">
    <div class="col-12 p-t-10 p-b-6">
      <div class="section-title">
        <span class="icon user"></span>
        <span>Digi Track</span>
      </div>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>

  <div class="row justify-center p-b-20">
    <div class="col-xl-6 col-lg-6 col-md-10 col-sm-12">
      <span class="c-about__description">
        DigiTrack is where you control your physical card via our
        platform. <br /><br />
        You can choose for it to be stored at DigiSafe or you can sell it
        or auction it on our platform at a later date, maybe when the card
        appreciates? Totally up to you! All in a decentralized manner.
        <br /><br />
        Simply login with your wallet that your NFT is stored on and
        access your physical collection online! Once you sell your
        physical card, your NFT will get burned to eliminate any duplicate
        issues.
      </span>
    </div>
  </div>
</div>
