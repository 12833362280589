<div class="container">
  <div class="row">
      <div class="col-12">
        <div class="input-group col-12">
          <div class="form-outline col-5">
            <input id="search-focus" placeholder="Search by item name" [(ngModel)]="itemName" type="search" class="form-control" />
          </div>
          <button type="button" class="btn btn-primary col-1" (click)="getMyAuctions(currentPage, itemName)">
            <i class="fas fa-search"></i>
          </button>
        </div>
        <div class="empty-card bg-light text-center py-5" *ngIf="!auctions?.length && !loading">
          <h3><b> You have no auctions item. </b></h3>
        </div>
          <div *ngIf="isVerifiedEmail; else notVerified">
            <div *ngIf="auctions.length > 0">

              <div class="col-lg-12 mb-4 mt-4">
                <h1 class="bold text-blue text-center">My Auctions</h1>
              </div>
              <div class="col-lg-12 mb-4 mt-4">
                          <div class="container-image">
                              <ng-container>
                                  <table class="table table-striped">
                                      <thead>
                                      <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Collection Name</th>
                                        <th scope="col">Item Name</th>
                                        <th scope="col">Base Price</th>
                                        <th scope="col">Last Price</th>
                                        <th scope="col">Seller Name</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr *ngFor="let item of auctions, let i = index">
                                        <th scope="row">{{auctionNumber + i+1 }}</th>
                                        <td>{{ item.collection_item.collection.name}}</td>
                                        <td>{{ item.collection_item.name }}</td>
                                        <td>{{ item.base_price }}</td>
                                        <td>{{ item.last_price }}</td>
                                        <td>{{ item.seller.name }}</td>
                                      </tr>
                                      </tbody>
                                    </table>

                              </ng-container>
                          </div>
                      </div>
                      <div class="d-flex justify-content-between p-2" *ngIf="auctionDataSize >= 10">
                        <ngb-pagination class="mx-auto" [collectionSize]="auctionDataSize" [pageSize]="auctionsPerPage"
                          [(page)]="currentPage" (pageChange)="onPageChange(currentPage)"></ngb-pagination>
                      </div>
            </div>

              </div>
              <ng-template #notVerified>
                <div class="container">
                  <div class="row">
                  <div class="col-lg-12">
                    <div class="mx-auto text-center">
                      <h1 class="mt-5">Please verify your email address before continuing, <b>it may be in your spam/junk folder.</b></h1>
                    </div>
                  </div>
                </div>
                </div>

              </ng-template>
      </div>
  </div>
</div>

