import { Component } from '@angular/core';

@Component({
  selector: 'app-digi-road-map',
  templateUrl: './digi-road-map.component.html',
  styleUrls: ['./digi-road-map.component.scss'],
})
export class AboutDigiRoadMapComponent {
  
}
