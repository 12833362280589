<div class="container">
  <div class="row" *ngIf="loading">
    <div class="col-lg-12">
      <div class="mx-auto text-center">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!loading && isVerifiedEmail; else notVerified">
    <div class="col-lg-12 mb-4 mt-4">
      <h1 class="bold text-blue">My Collections</h1>
      <p>
        Create, curate, and manage collections of unique NFTs to share and sell.
      </p>
      <a class="btn btn-primary" [routerLink]="['/collection/create', collectionUser]"
        >Create Collection</a
      >
    </div>
    <!-- Tab panes -->
    <div class="col-12">
      <ul class="nav nav-tabs mb-4" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Approved</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Pending</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#tabs-3" role="tab">Rejected</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#tabs-4" role="tab">Sold</a>
        </li>
      </ul>
    </div>

    <!-- Tab content -->
    <div class="tab-content col-12">
      <div class="tab-pane active" id="tabs-1" role="tabpanel">
        <h1 class="bold text-blue mt-2">Approved Collections</h1>
    <div class="col-lg-12">
      <div class="input-group col-12 mb-5">
        <div class="form-outline col-5">
          <input id="search-focus" placeholder="Search by collection name" [(ngModel)]="approvedCollectionName" type="search" class="form-control" />
        </div>
        <button type="button" class="btn btn-primary col-1" (click)="getApprovedCollections(approveCurrentPage, approvedCollectionName)">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <div class="empty-card bg-light text-center py-5" *ngIf="!approvedCollections?.length && !loading">
        <h3><b> You have no collection. </b></h3>
      </div>
      <div class="row" id="list-result" *ngIf="!loading">
        <div
          *ngFor="let collection of approvedCollections; let i = index"
          class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
        >
          <a href="collection/{{ collection.id }}/{{ currentUser.id}}" class="c-collection">
            <div class="c-collection__image">
              <img
                *ngIf="collection.featured_path"
                width="100%"
                height="100%"
                [src]="collection.featured_path"
                alt=""
              />
              <img
                *ngIf="!collection.imageUrl"
                width="100%"
                height="100%"
                src="/assets/images/home/home-1.png"
                alt=""
              />
            </div>
            <div
              class="c-collection__circle"
              [ngStyle]="{
                'background-image':
                  collection && collection.logo_path
                    ? 'url(' + collection.logo_path + ')'
                    : ''
              }"
            >
              <span
                *ngIf="collection && !collection.logo_path"
                class="icon collections"
              ></span>
            </div>
            <div class="c-collection__body">
              <h2 class="c-collection__title mb-2">
                {{ collection.name }}
              </h2>
            </div>
          </a>
        </div>
      </div>
      <div class="d-flex justify-content-between p-2" *ngIf="approvedCollectionDataSize >= 10">
        <ngb-pagination class="mx-auto" [collectionSize]="approvedCollectionDataSize" [pageSize]="approvedCollectionPerPage"
          [(page)]="approveCurrentPage" (pageChange)="onPageChange(approveCurrentPage)"></ngb-pagination>
      </div>
    </div>
      </div>
      <div class="tab-pane" id="tabs-2" role="tabpanel">
        <h1 class="bold text-blue mt-2">Pending Collections</h1>
    <div class="col-lg-12">
      <div class="input-group col-12 mb-5">
        <div class="form-outline col-5">
          <input id="search-focus" placeholder="Search by collection name" [(ngModel)]="pendingCollectionName" type="search" class="form-control" />
        </div>
        <button type="button" class="btn btn-primary col-1" (click)="getPendingCollections(pendingCurrentPage, pendingCollectionName)">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <div class="empty-card bg-light text-center py-5" *ngIf="!pendingCollections?.length && !loading">
        <h3><b> You have no collection. </b></h3>
      </div>
      <div class="row" id="list-result" *ngIf="!loading">
        <div
          *ngFor="let collection of pendingCollections; let i = index"
          class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
        >
          <a href="collection/{{ collection.id }}/{{ currentUser.id}}" class="c-collection">
            <div class="c-collection__image">
              <img
                *ngIf="collection.featured_path"
                width="100%"
                height="100%"
                [src]="collection.featured_path"
                alt=""
              />
              <img
                *ngIf="!collection.imageUrl"
                width="100%"
                height="100%"
                src="/assets/images/home/home-1.png"
                alt=""
              />
            </div>
            <div
              class="c-collection__circle"
              [ngStyle]="{
                'background-image':
                  collection && collection.picture
                    ? 'url(' + collection.picture + ')'
                    : ''
              }"
            >
              <span
                *ngIf="collection && !collection.picture"
                class="icon collections"
              ></span>
            </div>
            <div class="c-collection__body">
              <h2 class="c-collection__title mb-2">
                {{ collection.name }}
              </h2>
            </div>
          </a>
        </div>
      </div>
      <div class="d-flex justify-content-between p-2" *ngIf="pendingCollectionDataSize >= 10">
        <ngb-pagination class="mx-auto" [collectionSize]="pendingCollectionDataSize" [pageSize]="pendingCollectionPerPage"
          [(page)]="pendingCurrentPage" (pageChange)="onPendingPageChange(pendingCurrentPage)"></ngb-pagination>
      </div>
    </div>
      </div>
      <div class="tab-pane" id="tabs-3" role="tabpanel">
        <h1 class="bold text-blue mt-2">Rejected Collections</h1>
    <div class="col-lg-12">
      <div class="input-group col-12 mb-5">
        <div class="form-outline col-5">
          <input id="search-focus" placeholder="Search by collection name" [(ngModel)]="rejectedCollectionName" type="search" class="form-control" />
        </div>
        <button type="button" class="btn btn-primary col-1" (click)="getRejectedCollections(rejectedCurrentPage, rejectedCollectionName)">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <div class="empty-card bg-light text-center py-5" *ngIf="!rejectedCollections?.length && !loading">
        <h3><b> You have no collection. </b></h3>
      </div>
      <div class="row" id="list-result" *ngIf="!loading">
        <div
          *ngFor="let collection of rejectedCollections; let i = index"
          class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
        >
          <a href="collection/{{ collection.id }}/{{ collectionUser}}" class="c-collection">
            <div class="c-collection__image">
              <img
                *ngIf="collection.featured_path"
                width="100%"
                height="100%"
                [src]="collection.featured_path"
                alt=""
              />
              <img
                *ngIf="!collection.imageUrl"
                width="100%"
                height="100%"
                src="/assets/images/home/home-1.png"
                alt=""
              />
            </div>
            <div
              class="c-collection__circle"
              [ngStyle]="{
                'background-image':
                  collection && collection.picture
                    ? 'url(' + collection.picture + ')'
                    : ''
              }"
            >
              <span
                *ngIf="collection && !collection.picture"
                class="icon collections"
              ></span>
            </div>
            <div class="c-collection__body">
              <h2 class="c-collection__title mb-2">
                {{ collection.name }}
              </h2>
            </div>
          </a>
        </div>
      </div>
      <div class="d-flex justify-content-between p-2" *ngIf="rejectedCollectionDataSize >= 10">
        <ngb-pagination class="mx-auto" [collectionSize]="rejectedCollectionDataSize" [pageSize]="rejectedCollectionPerPage"
          [(page)]="rejectedCurrentPage" (pageChange)="onRejectPageChange(rejectedCurrentPage)"></ngb-pagination>
      </div>
    </div>
      </div>
      <div class="tab-pane" id="tabs-4" role="tabpanel">
        <h1 class="bold text-blue mt-2">Sold Collections</h1>
    <div class="col-lg-12">
      <div class="input-group col-12 mb-5">
        <div class="form-outline col-5">
          <input id="search-focus" placeholder="Search by collection name" [(ngModel)]="soldCollectionName" type="search" class="form-control" />
        </div>
        <button type="button" class="btn btn-primary col-1" (click)="getSoldCollections(soldCurrentPage, soldCollectionName)">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <div class="empty-card bg-light text-center py-5" *ngIf="!SoldCollections?.length && !loading">
        <h3><b> You have no collection. </b></h3>
      </div>
      <div class="row" id="list-result" *ngIf="!loading">
        <div
          *ngFor="let collection of SoldCollections; let i = index"
          class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
        >
          <a href="collection/{{ collection.id }}/{{ collectionUser}}" class="c-collection">
            <div class="c-collection__image">
              <img
                *ngIf="collection.featured_path"
                width="100%"
                height="100%"
                [src]="collection.featured_path"
                alt=""
              />
              <img
                *ngIf="!collection.imageUrl"
                width="100%"
                height="100%"
                src="/assets/images/home/home-1.png"
                alt=""
              />
            </div>
            <div
              class="c-collection__circle"
              [ngStyle]="{
                'background-image':
                  collection && collection.picture
                    ? 'url(' + collection.picture + ')'
                    : ''
              }"
            >
              <span
                *ngIf="collection && !collection.picture"
                class="icon collections"
              ></span>
            </div>
            <div class="c-collection__body">
              <h2 class="c-collection__title mb-2">
                {{ collection.name }}
              </h2>
            </div>
          </a>
        </div>
      </div>
      <div class="d-flex justify-content-between p-2" *ngIf="soldCollectionDataSize >= 10">
        <ngb-pagination class="mx-auto" [collectionSize]="rejectedCollectionDataSize" [pageSize]="soldCollectionPerPage"
          [(page)]="soldCurrentPage" (pageChange)="onSoldPageChange(soldCurrentPage)"></ngb-pagination>
      </div>
    </div>
      </div>
    </div>





  </div>
  </div>
  <ng-template #notVerified>
    <div class="row">
      <div class="col-lg-12">
        <div class="mx-auto text-center">
          <h1 class="mt-5">Please verify your email address before continuing, <b>it may be in your spam/junk folder.</b></h1>
        </div>
      </div>
    </div>
</ng-template>

<!-- <div class="row center p-t-10">
    <div class="col-lg-12">
      <button *ngIf="loading" class="btn btn-primary mt-4 mb-4">. . .</button>
      <button
        *ngIf="!loading && nextPage"
        class="btn btn-primary mt-4 mb-4"
        (click)="loadMore()"
      >
        Load more
      </button>
    </div>
  </div> -->

