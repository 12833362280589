<div class="c-profile__hero"
  [ngStyle]="{
    'background-image':
    collection && collection.banner_path
        ? 'url(' + collection.banner_path + ')'
        : ''
  }"
>
  <div class="c-profile__hero-overlay"></div>
  <span
    *ngIf="collection && collection.logo_path"
    class="c-profile__avatar"
    [ngStyle]="{ 'background-image': 'url(' + collection.logo_path + ')' }"
  ></span>
  <span
    *ngIf="!collection || !collection.logo_path"
    class="c-profile__avatar"
  ></span>
  <!-- <div *ngIf="isYourProfile" class="c-profile__edit">
  <button id="editDescriptionModal" data-toggle="modal" data-target="#addDescriptionModal"
    class="btn btn-primary mb-4" #editDescriptionModal>
    <span>Edit</span>
  </button>
</div> -->
</div>
<div class="row p-t-40 p-b-40">
  <div class="container p-t-40">
    <div class="col-lg-6 m-auto center">
      <div class="c-profile__title">
        <div class="c-profile__creator icon-creator"></div>
        <h1 *ngIf="collection && collection.name">{{ collection.name }}</h1>
      </div>
      <br>
      <div class="c-profile__title">
        <h2 *ngIf="collection">{{'by ' + collection.user.data.name }}</h2>
      </div>
      <div class="c-profile__social">
        <div class="social">
          <a
            [href]="collection.twitter"
            *ngIf="collection && collection.twitter"
            target="_blank"
            ><i class="icon fab fa-twitter"></i
          ></a>
          <a
            [href]="collection.twitch"
            *ngIf="collection && collection.twitch"
            target="_blank"
            ><i class="icon fab fa-twitch"></i
          ></a>

          <a
            [href]="collection.telegram"
            *ngIf="collection && collection.telegram"
            target="_blank"
            ><i class="icon fab fa-telegram"></i
          ></a>

          <a
            [href]="collection.discord"
            *ngIf="collection && collection.discord"
            target="_blank"
            ><i class="icon fab fa-tiktok"></i
          ></a>

          <a
            [href]="collection.tiktok"
            *ngIf="collection && collection.tiktok"
            target="_blank"
            ><i class="icon fab fa-tiktok"></i
          ></a>

          <a
            [href]="collection.instagram"
            *ngIf="collection && collection.instagram"
            target="_blank"
            ><i class="icon fab fa-instagram"></i
          ></a>

          <a
            [href]="collection.website"
            *ngIf="collection && collection.website"
            target="_blank"
            ><i class="icon fa fa-desktop"></i
          ></a>
        </div>
      </div>
      <div>
        <div class="profile-description" *ngIf="collection && collection.description"
      [ngClass]="{'expand-description': isArrowClose}">
        <p>
          {{ collection.description }}
        </p>
        <div [ngClass]="{'description-shadow': !isArrowClose && showToggleArrow}"></div>
      </div>
      <div class="toggle-icon" *ngIf="isArrowClose" (click) = "onClickArrow()">
        <i class="fa fa-caret-down"></i>
      </div>
      <div class="toggle-icon" *ngIf="!isArrowClose && showToggleArrow" (click) = "onClickArrow()">
        <i class="fa fa-caret-up"></i>
      </div>
      </div>

      <div class="c-profile__details">
        <div class="c-profile__detail-item">
          <span>{{ collectionItems.length }}</span>
          <span>Items</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="c-collection-wrapper">
  <div class="app-filter filter-scroll" [ngClass]="{'filter-sidebar-close': isFilterSidebarClose}">
    <div class="app-filter-inner">
      <div class="d-flex">
        <h3 [ngClass]="{'d-none': isFilterSidebarClose}" class="section-title bold p-2 mb-0 mt-2 flex-1-class">
          Filter Assets
        </h3>
        <div class="filter-arrow" *ngIf = "isFilterSidebarClose" (click) = "onClickFilterSidebarArrow()">
          <i class="fas fa-arrow-right"></i>
        </div>
        <div class="filter-arrow" *ngIf = "!isFilterSidebarClose" (click) = "onClickFilterSidebarArrow()">
          <i class="fas fa-arrow-left"></i>
        </div>
        <div [ngClass]="{'d-none': isFilterSidebarClose}" (click)="clearAllFiltersClick()" class="clear-all-filters mt-4">
          Clear Filters
        </div>
      </div>
      <ul [ngClass]="{'d-none': isFilterSidebarClose}" class="c-filter__list">
        <li class="c-filter__item">
          <div class="c-filter__title" (click)="toggleMenu($event, filterenum.Status)">
            <span class="title-span">Status</span>
            <span (click)="arrowToggleMenu(statusArrow)" class="chevron">
              <div *ngIf="!isOpen(filterenum.Status)">
                <i class="fas fa-caret-down"></i>
              </div>
              <div *ngIf="isOpen(filterenum.Status)">
                <i class="fas fa-caret-up"></i>
              </div>
          </span>
          </div>
          <div class="c-filters__list" #statusArrow>
            <div #allData id="allData"
            (click)="clickAllDataFilter(allData)"
              class="btn btn-primary mb-2 w-100"
            >
              All Data
            </div>
            <div #onChain id="onChain"
            (click)="clickOnChainStatusFilter(onChain)"
              class="btn btn-primary mb-2 w-100"
            >
              On-chain
            </div>
            <div #offChain id="offChain"
            (click)="clickOffChainStatusFilter(offChain)"
              class="btn btn-primary mb-2 w-100"
            >
              Off-chain
            </div>
            <!-- <div
              (click)="clickApprovedStatusFilter()"
              class="btn btn-primary mb-2 w-100"
            >
              Approved
            </div> -->
            <div #auction id="auction"
            (click)="clickAuctionStatusFilter(auction)"
              class="btn btn-primary mb-2 w-100"
            >
              Auction
            </div>
            <div #buyNow id="buyNow"
            (click)="clickBuyNowStatusFilter(buyNow)"
              class="btn btn-primary mb-2 w-100"
            >
              Buy Now
            </div>
            <div #latest id="latest"
            (click)="applyLatestFilter(latest)"
              class="btn btn-primary w-100"
            >
              Latest
            </div>
          </div>
        </li>
        <li class="c-filter__item">
          <div class="c-filter__title" (click)="toggleMenu($event, filterenum.Price)">
            <span class="title-span price">Price</span>
            <span (click)="arrowToggleMenu(priceArrow)" class="chevron">
              <div *ngIf="!isOpen(filterenum.Price)">
                <i class="fas fa-caret-down"></i>
              </div>
              <div *ngIf="isOpen(filterenum.Price)">
                <i class="fas fa-caret-up"></i>
              </div>
            </span>
          </div>
          <div class="c-filters__list price" #priceArrow>
            <p class="ml-auto mr-2"> Currency: $/USD</p>
            <p class="text-center">Please select a price range below.</p>
            <div class="d-flex flex-row items-center mb-4">
              <input
                [formControl]="priceFilter.get('minPrice')"
                class="c-filters__input"
                type="number"
                placeholder="Min"
                min="0"
                (keypress) = "onKeyPress($event)"
              />
              <p class="mb-0 ml-2 mr-2">to</p>
              <input
                [formControl]="priceFilter.get('maxPrice')"
                class="c-filters__input"
                type="number"
                placeholder="Max"
                min="0"
                (keypress) = "onKeyPress($event)"
              />
            </div>
            <div class="text-center text-danger" style="margin-top: -15px;" *ngIf="value">
              <h6 class="pt-0"><small>Minimum must be less than the maximum!</small></h6>
            </div>
            <button class="btn btn-primary" (click)="applyPriceFilter()">
              Apply
            </button>
          </div>
        </li>
        <li (click)="toggleMenu($event, filterenum.OffChain)" class="c-filter__item">
          <div class="c-filter__title">
            <span class="title-span">Off-chain Collections</span>
            <span (click)="arrowToggleMenu(ofChainArrow)" class="chevron">
              <div *ngIf="!isOpen(filterenum.OffChain)">
                <i class="fas fa-caret-down"></i>
              </div>
              <div *ngIf="isOpen(filterenum.OffChain)">
                <i class="fas fa-caret-up"></i>
              </div>
            </span>
          </div>
          <ul class="c-filters__list" #ofChainArrow>
            <span
              (click)="clearOffChainCollectionFilter()"
              class="c-filter-item-title category-clear-filter-btn"
              >Clear Filter</span
            >
            <li
              class="c-filter__item-inner"
              *ngFor="let collection of offChainCollections; let i = index"
            >
              <a class="d-flex">
                <div class="c-filter__circle">
                  <img
                    *ngIf="collection.picture"
                    width="100%"
                    height="100%"
                    [src]="collection.picture"
                    alt=""
                  />
                  <img
                    *ngIf="!collection.picture"
                    width="100%"
                    height="100%"
                    src="/assets/images/home/home-1.png"
                    alt=""
                  />
                </div>
                <span
                  (click)="applyOffChainCollectionFilter(collection)"
                  class="c-filter-item-title"
                  >{{ collection.name }}</span
                >
              </a>
            </li>
          </ul>
        </li>
        <li class="c-filter__item">
          <div class="c-filter__title" (click)="toggleMenu($event, filterenum.OnChain)">
            <span class="title-span">On-chain Collections</span>
            <span (click)="arrowToggleMenu(onChainArrow)" class="chevron">
              <div *ngIf="!isOpen(filterenum.OnChain)">
                <i class="fas fa-caret-down"></i>
              </div>
              <div *ngIf="isOpen(filterenum.OnChain)">
                <i class="fas fa-caret-up"></i>
              </div>
            </span>
          </div>
          <ul class="c-filters__list" #onChainArrow>
            <li
              class="c-filter__item-inner"
              *ngFor="let collection of onChainCollections; let i = index"
            >
              <a class="d-flex" [routerLink]="['/profile', collection.id]">
                <div class="c-filter__circle">
                  <img
                    *ngIf="collection.picture"
                    width="100%"
                    height="100%"
                    [src]="collection.picture"
                    alt=""
                  />
                  <img
                    *ngIf="!collection.picture"
                    width="100%"
                    height="100%"
                    src="/assets/images/home/home-1.png"
                    alt=""
                  />
                </div>
                <span class="c-filter-item-title">{{
                  collection.username
                }}</span></a
              >
            </li>
          </ul>
        </li>
        <li class="c-filter__item">
          <div class="c-filter__title" (click)="toggleMenu($event, filterenum.Category)">
            <span class="title-span">Categories</span>
            <span (click)="arrowToggleMenu(categoryArrow)" class="chevron">
              <div *ngIf="!isOpen(filterenum.Category)">
                <i class="fas fa-caret-down"></i>
              </div>
              <div *ngIf="isOpen(filterenum.Category)">
                <i class="fas fa-caret-up"></i>
              </div>
          </span>
          </div>
          <ul class="c-filters__list" #categoryArrow>
            <span
              (click)="clearCategoryFilter()"
              class="c-filter-item-title category-clear-filter-btn"
              >Clear Filter</span
            >
            <li
              class="c-filter__item-inner"
              [ngClass]="{'select-item': category?.selected}"
              *ngFor="let category of collectionsCategories; let i = index"
            >
              <div class="d-flex">
                <div class="c-filter__circle">
                  <img
                    *ngIf="!category?.picture"
                    width="100%"
                    height="100%"
                    src="/assets/images/home/home-1.png"
                    alt=""
                  />
                </div>
                <span
                  (click)="applyCategoryFilter(category)"
                  class="c-filter-item-title"
                  >{{ category.name }}</span
                >
              </div>
            </li>
          </ul>
        </li>
        <li class="c-filter__item">
          <div class="c-filter__title" (click)="toggleMenu($event, filterenum.Blockchain)">
            <span class="title-span">Blockchains</span>
            <span (click)="arrowToggleMenu(blockChainArrow)" class="chevron">
              <div *ngIf="!isOpen(filterenum.Blockchain)">
                <i class="fas fa-caret-down"></i>
              </div>
              <div *ngIf="isOpen(filterenum.Blockchain)">
                <i class="fas fa-caret-up"></i>
              </div>
          </span>
          </div>
          <ul class="c-filters__list"  #blockChainArrow>
            <li (click)="changeFilter('auction')" class="c-filter__item-inner">
              <div class="c-filter__circle blockchains">
                <span class="c-card__safe icon polygon"></span>
              </div>
              <span>Polygon</span>
            </li>
            <li (click)="changeFilter('auction')" class="c-filter__item-inner">
              <div class="c-filter__circle blockchains">
                <span class="c-card__safe icon ethereum"></span>
              </div>
              <span>Ethereum</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="main-body">
    <div class="pl-4 pr-4">
      <div class="row" *ngIf="collectionItems === null">
        <div class="col">
          <div class="mx-auto text-center mt-4 mb-4">
            <app-loading></app-loading>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <button class="btn btn-primary my-3" (click)="createCollectionItemAction()"
        >Create Collection Item</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 filter-alerts">
          <div class="alert-box-item" *ngFor="let alert of alerts" >
            <ngb-alert [type]="alert.type"  (closed)="close(alert)">{{ alert.message }}</ngb-alert>
          </div>
          <div class="alert-box-item" *ngIf="alerts.length != 0" >
            <ngb-alert type="light" (closed)="clearAllFiltersClick()">Clear All</ngb-alert>
          </div>

        </div>
      </div>
      <div class="row" *ngIf="filterCollectionItems !== null">
        <div class="col-sm-12">
          <p>{{ filterCollectionItems.length }} results</p>
        </div>
        <div class="alert alert-primary w-100 text-center" role="alert" *ngIf="!filterCollectionItems?.length">
          No item to display
        </div>
      <div class="row" *ngIf="collectionItems !== null">
        <div class="col-sm-12">
           <!-- <p>{{ nftListFiltered.length }} results</p> -->
        </div>
        <div
          *ngFor="let item of filterCollectionItems; let i = index"
          class="col-xxl-3 col-xl-4 col-lg-6 col-sm-12"
        >
          <app-off-chain-card
            class="c-card-container"
            [data]="item"
          ></app-off-chain-card>
        </div>
      </div>
    </div>
    <button (click)="toggleFilter()" class="c-filter-button">Filter</button>
  </div>
</div>

<!-- <div class="row center p-t-10">
  <div class="col-lg-12">
    <button *ngIf="loading" class="btn btn-primary mt-4 mb-4">. . .</button>
    <button
      *ngIf="!loading && nextPage"
      class="btn btn-primary mt-4 mb-4"
      (click)="loadMore()"
    >
      Load more
    </button>
  </div> -->
