import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/Authentication.service';
import { CollectionsService } from 'src/app/services/collections.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  cartAllItems = [];
  cartPluckItems = [];
  items = [];
  closeResult = '';
  returnUrl: string = 'collections';
  secretKey: string = 'SK-FV3CFCGM-TN3PZ4XW-CDYGWZAC-M6EAEMVR';
  isVerifiedEmail: boolean = false;
  currentUser: any;
  public loading: boolean = false;
  public submitted: boolean = false;
  orderForm: FormGroup;
  currency: string;
  isOrderSuccess: boolean;
  quantity: Array<number> = [];
  collectionItemId: Array<number> = [];

  constructor(private cartService: CartService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private collectionsService: CollectionsService,
    private router: Router) {
      this.authenticationService.currentUser.subscribe(
        (x) => (this.currentUser = x)
      );
    }

  ngOnInit(): void {
    this.currentUser = this.currentUser.data;
    this.isVerifiedEmail = this.currentUser.verified;
    this.cartItemsInit();

    this.orderForm = this.formBuilder.group({
      api_key: ['', Validators.required],
      api_pass: ['', Validators.required]
    });
  }


  get f() {
    return this.orderForm.controls;
  }


  cartItemsInit(): void {
    this.cartPluckItems = [];
    this.cartAllItems = [...this.cartService.getCartItems()];

    const item = this.cartAllItems[0];
    if (item) {
    this.cartPluckItems.push(item);
    }
    this.cartAllItems.forEach((cartItem) => {
        if (cartItem.id !== item.id) {
          if (cartItem) {
            this.cartPluckItems.push(cartItem);
          }
        }
    });
  }
  onClick(){
    if(this.isOrderSuccess){
      this.cartService.toResetCart();
      for (var i = this.cartPluckItems.length - 1; i >= 0; i--) {
        this.cartPluckItems.splice(i, 1);
        }
    }
  }

  handleClickIncreaseBtn(item): void {
    if (item) {
      this.cartService.addToCart(item);
    }
  }

  handleClickDecreaseBtn(item): void {
    if (item && item.count > 1) {
      this.cartService.removeFromCart(item);
    }
  }

  removeItem(item): void {
    this.cartService.removeFromCart(item);
    this.cartItemsInit();
  }

  placeOrder():void {

  }



  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  orderList(){
    this.router.navigate(['/user-order-details/', this.currentUser?.id, this.currentUser?.role[0]]);

    this.modalService.dismissAll();
    this.cartService.resetCart();
  }

  onOrderSubmit(content) {
    // this.submitted = true;
    // this.alertService.clear();
    // if (this.orderForm.invalid) {
    //   return;
    // }
    let itemList= [];


    this.loading = true;

  this.cartAllItems.forEach(element => {
    itemList.push({'collection_item_id': element.id, 'quantity': element.count,
     'discount': 0,});
  });
  let data;
  this.items = itemList;
  this.currency= 'USD';
  this.secretKey = 'SK-FV3CFCGM-TN3PZ4XW-CDYGWZAC-M6EAEMVR'
  // 'authenication': {'api_key': this.f.api_key.value, 'api_pass': this.f.api_pass.value},
    this.cartService
      .placeUserOrder(this.currency, this.secretKey, this.items).subscribe(
        response => {
          this.isOrderSuccess = true;
          this.alertService.success('Order successfully', true);
          this.loading = false;
          this.open(content);
        },
      err => {
        this.isOrderSuccess = false;
        this.alertService.error('Order Fail', true);
        this.open(content);
      }
      );

  }

}
