<a
  *ngIf="data"
  [routerLink]="'/details/' + data.network + '/' + data.token_id + '/' + data.token_address"
  class="c-card"
>
  <figure class="c-card__figure">
    <img
      *ngIf="data && data.tokenData"
      class="c-card__figure__video"
      [src]="data.tokenData.image"
      alt="image"
    />

    <!-- <div
      *ngIf="auction && endDate && view !== 'profile'"
      class="c-card__countdown"
    >
      <countdown #cd [config]="config"></countdown>
      <span class="ml-2">Left</span>
      <span class="icon auctions p-0"></span>
    </div> -->
  </figure>

  <div class="c-card__body">
    <div class="c-card__top"> 
      <h1 class="c-card__title" *ngIf="data && data.tokenData">
        <span>{{ data.tokenData.name }}</span>
      </h1>
      <!-- <div class="c-card__price">
        <span class="mr-2">${{ price || data.price }}</span>        <span
          *ngIf="auction && auctionOrSaleData && auctionOrSaleData.paymentCurrency === digiAddressMatic"
          class="icon digi-currency"
        ></span>
        <span
          *ngIf="auction && auctionOrSaleData && auctionOrSaleData.paymentCurrency === usdcMaticAddress"
          class="icon usdc"
        ></span>
        <span
          *ngIf="!auction && data.price"
          class="icon usdc"
        ></span>
        <span
          *ngIf="auction && auctionOrSaleData && auctionOrSaleData.paymentCurrency === usdtMaticAddress"
          class="icon usdt"
        ></span>
        <span
          *ngIf="auction && auctionOrSaleData && auctionOrSaleData.paymentCurrency === maticCoinAddress"
          class="icon polygon"
        ></span>
      </div> -->
    </div>
    <div
      *ngIf="data.tokenData && data.tokenData.description"
      class="c-card__description"
    >
      <p>{{ data.tokenData.description | truncate: [120, "..."] }}</p>
    </div>
    <div
      *ngIf="data.tokenData && !data.tokenData.description"
      class="c-card__description"
    >
      <p>No description has been created for this NFT.</p>
    </div>
  </div>
  <div class="c-card__footer">
   <!--  <ng-container class="mb-4" *ngIf="view !== 'details'">
      <ul class="c-card__list">
        <li *ngFor="let desc_item of description | keyvalue: keepOriginalOrder">
          <div
            *ngIf="
              desc_item.key !== 'backCardImage' &&
              desc_item.key !== 'description'
            "
            class="row"
          >
            <div class="bold text-capitalize col-6">
              <span>{{ desc_item.key }}:</span>
            </div>
            <div class="col-6">
              <span>{{ desc_item.value || "..." }}</span>
            </div>
          </div>
        </li>
      </ul>
    </ng-container> -->
    <div class="c-card__footer-inner">
      <div *ngIf="physical === 'Yes'" class="d-flex flex-row">
        <span class="c-card__safe icon safe mr-2"></span>
        <span class="c-card__info">Physically backed</span>
      </div>
      <div
        *ngIf="data.network === 'ETH'"
        class="d-flex flex-row"
      >
        <span class="c-card__safe icon ethereum mr-2"></span>
        <span class="c-card__info">ERC-20</span>
      </div>
      <div
        *ngIf="data.network === 'MATIC'"
        class="d-flex flex-row"
      >
        <span class="c-card__safe icon polygon mr-2"></span>
        <span class="c-card__info">MATIC</span>
      </div>
      <div
        *ngIf="owner && ownerUsername"
        class="d-flex flex-row"
      >
        <span class="c-card__safe icon collections mr-2"></span>
        <span class="c-card__info"> {{ ownerUsername }}</span>
      </div>
    </div>
  </div>
</a>
