<div class="add-comment comment comment-level-2" *ngIf="addReplyExpended">
  <div class="message-icon">
    <i class="fas fa-envelope"></i>
  </div>
  <div class="comment-input" [formGroup]="replyForm">
    <input class="form-control form-control-sm" type="text" formControlName="comment" placeholder="Add a reply.." />
    <div class="comment-send" (click)="addReply()"><i class="fab fa-telegram-plane"></i></div>
  </div>
</div>
<ng-container *ngIf="replyCount && repliesExpended">
  <div class="comment comment-level-2" *ngFor="let reply of replies">
    <div class="message-icon">
      <i class="fas fa-envelope"></i>
    </div>
    <div>
      <div class="comment-label">
        <span class="comment-name">{{reply.user.data.name}}</span>
        <span class="comment-time"> - {{reply.created_at | dateFromNow}}</span>
      </div>
      <div class="comment-content">{{reply.comment}}</div>
    </div>
  </div>
</ng-container>
<div class="comment comment-level-2 mt-0">
  <div class="comment-action">
    <button type="button" class="btn btn-link p-0" *ngIf="!addReplyExpended" (click)="addReplyExpand()">Add a
      reply</button>
    <span *ngIf="!addReplyExpended && replyCount">|</span>
    <button type="button" class="btn btn-link p-0" *ngIf="replyCount" (click)="getReplies()">
      {{repliesExpended ? 'Hide replies' : 'Show ' + replyCount + ' replies'}}
    </button>
  </div>
</div>