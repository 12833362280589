<div class="container">
    <div class="row justify-center mt-5" *ngIf="isVerifiedSuccess">
            <i class="fas fa-check success "></i>
            <div>
                <h4 class="verifiedemail text-center">Success!</h4>
                <h4 class="verifiedemail">Your email address has been verified!</h4>
            </div>
    </div>

    <div class="row mt-3" *ngIf="!isVerifiedSuccess">
        <div class="col-12 text-center">
            <i class="fas fa-times-circle warning"></i>
        </div>

        <div class="col-12 text-center">
            <h3 class="verifiedemail text-center">Warning!</h3>
            <h3 class="verifiedemail">Email verification failed!</h3>

        </div>
        <div class="col-10 text-center">
            <h5 class="verifiedemail text-center">Please try again.</h5>
            <button class="btn btn-primary float-right" (click) = "showEmailForm()">Click here</button>
        </div>
    </div>
    <div class="row justify-center items-center" *ngIf="!isVerifiedSuccess && isEmailFail">
      <div class="col-lg-6 p-t-10 p-b-6">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="email">Email</label>
            <input type="text" formControlName="email" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
            </div>
          </div>
          <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
</div>
