<div class="container">
  <div class="row">
    <div class="col-lg-12 col-sm-12">
      <div class="loading"></div>
    </div>
  </div>
  <div class="row p-t-20 justify-center">
    <div class="col-lg-6 col-sm-12">
      <div class="c-loading__description">
        <b>
          Your transaction is being processed, once confirmed the data will load
          automatically. This process may take longer depending on the
          congestion of the network. If your page doesn't load try refreshing.
        </b>
        <i
          >Note: If you speed up the transaction the redirect will fail - either
          go to your profile or the card details to see the changes.</i
        >
      </div>
    </div>
  </div>
</div>
