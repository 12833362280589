import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketplaceService } from 'src/app/services/marketplace.service';
import { MaticService } from 'src/app/services/matic.service';
import { TokensService } from 'src/app/services/tokens.service';
import { WalletsService } from 'src/app/services/wallets.service';
import { VerifiedWalletsService } from 'src/app/services/verified-wallets.service';
import { MarketCard } from 'src/app/types/market-card.types';
import { Profile } from 'src/app/types/profile.type';
import { environment } from 'src/environments/environment';
import { MathService } from '../../services/math.service';
import { NftService } from '../../services/nft.service';
import { WalletService } from '../../services/wallet.service';
import { Network } from '../../types/network.enum';
import { PendingDigiCard } from '../../types/pending-digi-card.types';
import { OffchainService } from 'src/app/services/offchain.service';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { CollectionsService } from 'src/app/services/collections.service';
import { CommentType } from 'src/app/types/comments.type';
import { AuthenticationService } from 'src/app/services/Authentication.service';
import { MoralisService } from 'src/app/services/moralis.service';
import { AlertService } from 'src/app/services/alert.service';
import moment from 'moment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => active', [
        style({ opacity: 0 }),
        animate(10000, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ProfileComponent implements OnInit {
  @ViewChild('addTokenModal') addTokenModal: ElementRef;
  public commentType = CommentType.Collection;
  address;
  displayAddress;
  imageUrlPreviewIcon;
  imageUrlPreviewHero;
  profile: Profile;
  myCards = [];
  myCardsFiltered = [];
  otherNfts: MarketCard[];
  pendingAuctions: PendingDigiCard[];
  pendingTransfersFromMatic = [];
  network;
  stableSymbol = environment.stableCoinSymbol;
  digiBalance = '...';
  stableBalance = '...';
  canMint = false;
  isYourProfile = false;
  isItemUser = false;
  loading = false;
  activityHistory = null;
  loadFiles = [];
  tokenName;
  inputAddress;
  verifiedAddress;
  maticNfts = [];
  ethNFTs = [];
  nftArray = [];
  ipfs = [];
  heroPicture;
  currentUser: any;
  picture;
  is20Collection = false;
  collectionId;
  currentPage = 1;
  collectionItems = [];
  collectionData: any;
  isVerifiedEmail: boolean = false;
  showToggleArrow: boolean = false;
  tokenType: string;
  isArrowClose: boolean = false;
  itemUserId: number;

  typeFilter = 'ALL';
  filterBy = [
    { name: 'All', id: 'ALL' },
    { name: 'Ethereum Network', id: 'ETH' },
    { name: 'MATIC Network', id: 'MATIC' },
    { name: 'Physically Backed', id: 'PHYSICAL' },
    { name: 'Digital Only', id: 'DIGITAL' },
    /* { name: 'Recently Added', id: 'RECENT' },
    { name: 'Date Created', id: 'DATE CERATED' },
    { name: 'Alphabetical', id: 'ALPHABETICAL' }, */
  ];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly walletService: WalletService,
    private readonly nft: NftService,
    private readonly math: MathService,
    private readonly cdr: ChangeDetectorRef,
    private readonly tokens: TokensService,
    private readonly wallets: WalletsService,
    private readonly verifiedWallets: VerifiedWalletsService,
    private readonly router: Router,
    private readonly moralis: MoralisService,
    private readonly matic: MaticService,
    private authenticationService: AuthenticationService,
    private readonly marketplace: MarketplaceService,
    private readonly offchain: OffchainService,
    private readonly collectionService: CollectionsService,
    private alertService: AlertService
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
      this.itemUserId = this.currentUser.data.id;
  }

  ngOnInit(): void {
    this.currentUser = this.currentUser?.data;
    this.isVerifiedEmail = this.currentUser?.verified;
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.is20Collection) {
        this.is20Collection = true;
      }
    });
    this.route.params.subscribe(async (queryParams) => {
      this.collectionId = queryParams.address;
      if (this.is20Collection) {
        this.collectionService.getAllPublicCollectionItems(this.collectionId, this.currentPage).subscribe(items => {
          this.collectionItems = items.data.filter(item => ((item.available_for_sale === 1
            && moment(item.available_at).diff(moment(new Date())) <= 0) || item?.collection?.user_id === this.currentUser?.id ) || item.available_for_sale === 2 || item.available_for_sale === 0);
          this.collectionItems = this.collectionItems.sort((a, b) => b.id - a.id);
           this.findItemtUser();
        })
        this.collectionService.getCollectionById(this.collectionId).then(collection => {
          this.collectionData = collection;
          if(this.collectionData?.description.length >= 330) {
            this.showToggleArrow = true;
          }
        })
      } else {
        this.verifiedAddress = await this.verifiedWallets.getVerifiedAddress(
          queryParams.address
        );
        if (this.verifiedAddress) {
          this.address = queryParams.address;
          this.displayAddress = this.truncate(queryParams.address, 15, '...');
        } else {
          if (
            !this.walletService.getWeb3().utils.isAddress(queryParams.address)
          ) {
            this.router.navigate(['/auctions']);
            return;
          }
          this.address = queryParams.address;
          this.displayAddress = this.truncate(queryParams.address, 15, '...');
        }
        this.loadData();
      }
    });
    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        this.loadData();
      });
    }
  }
  async dropped(files: NgxFileDropEntry[], imageType: string): Promise<void> {
    if (files.length === 0) {
      return;
    }
    if (imageType === 'hero_picture') {
      this.heroPicture = files[0];
      const droppedFile = files[0];
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: any) => {
        const toBase64 = (someFile) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(someFile);
            reader.onerror = (error) => reject(error);
            reader.onload = () => {
              this.imageUrlPreviewHero = reader.result;
              resolve(reader.result);
            };
          });
        const baseString: any = await toBase64(file);
      });
      this.loadFiles.push({ hero_picture: this.heroPicture });
    }
    if (imageType === 'picture') {
      this.picture = files[0];
      const droppedFile = files[0];
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: any) => {
        const toBase64 = (someFile) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(someFile);
            reader.onerror = (error) => reject(error);
            reader.onload = () => {
              this.imageUrlPreviewIcon = reader.result;
              resolve(reader.result);
            };
          });
        const baseString: any = await toBase64(file);
      });
      this.loadFiles.push({ picture: this.picture });
    }

    if (!this.loadFiles[0].picture) {
      this.picture = this.profile.picture;
    }
    if (!this.loadFiles[0].hero_picture) {
      this.heroPicture = this.profile.hero_picture;
    }
  }

  onClickArrow(){
    this.isArrowClose = !this.isArrowClose;
  }

  findItemtUser(){
    const index = this.collectionItems.findIndex(item => item?.collection?.user_id === this.currentUser?.id);
    if(index > -1) {
      this.isItemUser = true;
    }
  }

  functionAfterForEach() {
    setTimeout(() => {
      console.log("CALLED UPDATE callback");

     try {
      this.wallets.updProfileData(
        this.address,
        this.picture,
        this.heroPicture,
        this.profile.username,
        this.profile.description,
        this.profile.twitter,
        this.profile.instagram,
        this.profile.email,
        this.profile.tiktok,
        this.profile.twitch
      );
     } catch(e) {
       console.log(e);
     }
      window.location.reload();
    }, 1000);
  }

  async updateProfile(): Promise<void> {
    if (this.loadFiles.length < 1) {
      try {
        await this.wallets.updProfileData(
          this.address,
          this.profile.picture,
          this.profile.hero_picture,
          this.profile.username,
          this.profile.description,
          this.profile.twitter,
          this.profile.instagram,
          this.profile.email,
          this.profile.tiktok,
          this.profile.twitch
        );
        window.location.reload();
      } catch (e) {
        alert('error: ' + e.data);
      }
      return;
    }
    let ctr = 0;
    this.loadFiles.forEach((item, index, array) => {
      if (item.picture) {
        (item.picture.fileEntry as FileSystemFileEntry).file((file: File) => {
          this.loading = true;
          try {
            this.offchain
              .uploadFile(
                '0x49c92d11f1cbb03e808d51982140a7b77eae92aac8ab453b44333715a5b471760b175f7112ff6be10a17bcc731024e456762affc3bd510256c758f7720007a7f1c',
                file,
                item.relativePath
              )
              .then((res) => {
                this.picture = res.uri;
              });
          } catch (e) {
            alert('error: ' + e.data);
          }
          this.loading = false;
          ctr++;
          if (ctr === array.length) {
            this.functionAfterForEach();
          }
        });
      }
      if (item.hero_picture) {
        (item.hero_picture.fileEntry as FileSystemFileEntry).file(
          (file: File) => {
            this.loading = true;
            try {
              this.offchain
                .uploadFile(
                  '0x49c92d11f1cbb03e808d51982140a7b77eae92aac8ab453b44333715a5b471760b175f7112ff6be10a17bcc731024e456762affc3bd510256c758f7720007a7f1c',
                  file,
                  item.relativePath
                )
                .then((res) => {
                  this.heroPicture = res.uri;
                });
            } catch (e) {
              alert('error: ' + e.data);
            }
            this.loading = false;
            ctr++;
            if (ctr === array.length) {
              this.functionAfterForEach();
            }
          }
        );
      }
    });
  }

  async loadData() {
    this.profile = await this.wallets.getFullProfile(this.address);
    this.myCards = null;
    this.myCardsFiltered = null;
    this.otherNfts = null;
    this.pendingAuctions = null;
    this.digiBalance = '...';
    this.stableBalance = '...';
    this.activityHistory = null;
    this.isYourProfile = false;
    this.checkYourProfile();
    this.checkNetwork();
    this.loadNFTs();
    this.loadActivityHistory();
  }

  changeFilter(): void {
    this.loading = true;

    setTimeout(async () => {
      if (this.typeFilter === 'ALL') {
        this.myCardsFiltered = this.myCards;
        this.loading = false;
        return;
      }
      if (this.typeFilter === 'MATIC') {
        this.myCardsFiltered = await this.moralis.getAllNFTsByWalletAddress(
          this.address
        );
        this.loading = false;
        return;
      }
      if (this.typeFilter === 'ETH') {
        this.myCardsFiltered = this.ethNFTs;
        this.loading = false;
        return;
      }
      if (this.typeFilter === 'ALPHABETICAL') {
        this.myCardsFiltered = [
          ...(await this.moralis.getAllNFTsByWalletAddress(this.address)),
          ...this.maticNfts,
        ];
        this.loading = false;
        return;
      }
      if (this.typeFilter === 'PHYSICAL') {
        var filtered = [];
        for (var i = 0; i < this.myCards.length; i++) {
          if (this.myCards[i].physical === true) {
            filtered.push(this.myCards[i]);
          }
        }
        this.myCardsFiltered = filtered;
        return;
      }
      if (this.typeFilter === 'DIGITAL') {
        var filtered = [];
        for (var i = 0; i < this.myCards.length; i++) {
          if (this.myCards[i].physical === false) {
            filtered.push(this.myCards[i]);
          }
        }
        this.myCardsFiltered = filtered;
        return;
      }
    }, 200);
  }
  truncate(fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return (
      fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars)
    );
  }

  async loadActivityHistory(): Promise<void> {
    const transactions = await this.moralis.getNFTTransfers(this.address);
    const lastBidsMatic = (
      await this.nft.getLastBidsByUser(this.address, 5, 'MATIC')
    ).map((bid: any) => {
      bid.action = 'bid';
      return bid;
    });
    lastBidsMatic.map(async (bid) => {
      bid.isDigi = true;
      bid.network = 'MATIC';
      bid.tokenId = (
        await this.nft.getAuctionById(bid.auctionId, 'MATIC')
      ).tokenId;
    });
    this.activityHistory = [...transactions.result].sort(
      (a, b) => (a.created > b.created && -1) || 1
    );
    this.cdr.detectChanges();
  }

  async checkYourProfile(): Promise<void> {
    if (!(await this.walletService.getAccount())) {
      return;
    }
    this.isYourProfile =
      this.address.toLowerCase() ===
      (await this.walletService.getAccount()).toLowerCase();
    if (this.isYourProfile) {
      this.loadPendingAuctions();
    }
  }

  async loadPendingAuctions(): Promise<void> {
    this.pendingAuctions = await this.nft.pendingAuctions(50, 'MATIC');
    console.log(this.pendingAuctions);

    this.cdr.detectChanges();
  }

  async loadAllNFTs(): Promise<void> {
    // Need to write function to check token_address
    // prop on returned objects and pass it to API to get Data
    const cardList = [];
    for (let i = 0; i < this.myCards.length; i++) {
      cardList.push(
        await this.offchain.getNftData(
          this.myCards[i].id,
          this.myCards[i].network,
          this.myCards[i].token_address
        )
      );
    }
    this.myCardsFiltered = cardList;
    this.myCards = cardList;
  }

  async loadNFTs(): Promise<void> {
    try {
      this.myCards = await this.moralis.getAllNFTsByWalletAddress(this.address);
      this.myCardsFiltered = this.myCards;
      console.log(this.myCards);
    } catch (e) {
      console.error(e);
    }
  }

  async onChangeInputAddress(): Promise<void> {
    this.tokenName = null;
    if (!this.walletService.getWeb3().utils.isAddress(this.inputAddress)) {
      console.log('Invalid address');
      return;
    }

    this.tokenName = '...';
    this.tokenName = await this.nft.getExternalNftName(this.inputAddress);
  }

  async addToken(): Promise<void> {
    if (this.tokenName && this.tokenName !== '...') {
      this.tokens.addToken(this.inputAddress);
      this.addTokenModal.nativeElement.click();
      this.loadData();
      return;
    }
  }

  async loadBalances(): Promise<void> {
    let readOnly = false;
    if ((await this.walletService.getNetwork()) === Network.UNSUPPORTED) {
      readOnly = true;
    }
    this.nft.digiBalance(this.address, readOnly).then((balance) => {
      this.digiBalance = this.math.toHumanValue(balance + '', 18) + '';

      this.cdr.detectChanges();
    });
    this.nft.stableBalance(this.address, readOnly).then((balance) => {
      this.stableBalance = this.math.toHumanValue(balance + '') + '';
      this.cdr.detectChanges();
    });
  }

  loadPendingTransfersFromMatic(): void {
    this.pendingTransfersFromMatic = this.matic.loadTransferHash();
  }

  async completeTransferFromMatic(hash: string): Promise<void> {
    this.loading = true;
    await this.matic.claimTransferredFromMatic(hash);
    window.location.reload()
  }

  async cancel(auctionId: number, network: string): Promise<void> {
    this.loading = true;
    try {
      await this.nft.cancel(auctionId, network);
    } catch (e) {}
    this.loading = false;
    this.loadData();
  }

  async claim(auctionId: number, network: string): Promise<void> {
    this.loading = true;
    try {
      await this.nft.claim(auctionId, network);
    } catch (e) {}
    this.loading = false;
    this.loadData();
  }


  async checkNetwork(): Promise<void> {
    this.walletService.getNetwork().then((network: Network) => {
      if (network === Network.ETH) {
        this.network = 'Ethereum';
      } else if (network === Network.MATIC) {
        this.network = 'Matic';
      } else {
        this.network = 'Invalid';
      }
      this.cdr.detectChanges();
    });
  }

  switchToMatic(): void {
    this.walletService.switchToMatic();
  }

  createCollectionItemAction(item) {
    this.router.navigate(['/collection-item/create/', item.id, this.itemUserId], {
      relativeTo: this.route,
      queryParams: {
        collection: this.collectionId,
        userId: this.currentUser.id,
        collectionName:this.collectionItems[0]?.collection?.name,
        isEdit: this.isItemUser
      },
      queryParamsHandling: 'merge',
    });
  }

  itemListRowClicked(item): void {
    this.router.navigate(['/item-details', item.id], {
      relativeTo: this.route,
      queryParams: {
        collection: this.collectionId
      },
      queryParamsHandling: 'merge',
    });
  }
}
