<div class="container page-top">
  <div class="row">
    <div class="col-12 p-t-10 p-b-6">
      <span class="section-title"
        ><span class="icon box"></span>Create Auction</span
      >
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>

  <div class="row" *ngIf="showSwitchToMatic">
    <div class="col-12 text-center mt-4">
      <p class="m-0">
        This section of the dApp only works if you connect your wallet to Matic
        Network.
      </p>
    </div>
    <br />
    <div class="mx-auto text-center mt-2">
      <button class="btn btn-primary btn-menu" (click)="switchToMatic()">
        <span class="btn-title">Switch to Matic</span>
      </button>
    </div>
  </div>

  <div class="row p-t-40" *ngIf="loading && !showSwitchToMatic">
    <div class="col-lg-12">
      <div class="mx-auto text-center">
        <app-loading-blockchain></app-loading-blockchain>
      </div>
    </div>
  </div>

  <div
    class="row space-evenly p-t-30 p-b-40"
    *ngIf="!showSwitchToMatic && !loading"
  >
    <div class="col-lg-4 p-b-40">
      <a class="c-card">
        <figure class="c-card__figure">
          <img class="c-card__figure__video" [src]="data.image" alt="image" />
        </figure>

        <div class="c-card__body">
          <div class="c-card__top">
            <h1 class="c-card__title">
              <span>{{ data.name }}</span>
            </h1>
          </div>
          <div *ngIf="data && data.description" class="c-card__description">
            <p>{{ data.description | truncate: [120, "..."] }}</p>
          </div>
          <div *ngIf="data && !data.description" class="c-card__description">
            <p>No description has been created for this NFT.</p>
          </div>
        </div>
        <div class="c-card__footer">
          <div class="c-card__footer-inner">
            <div *ngIf="data.physical" class="d-flex flex-row">
              <span class="c-card__safe icon safe mr-2"></span>
              <span class="c-card__info">Physically backed</span>
            </div>
            <div *ngIf="data.network === 'ETH'" class="d-flex flex-row">
              <span class="c-card__safe icon ethereum mr-2"></span>
              <span class="c-card__info">ERC-20</span>
            </div>
            <div *ngIf="data.network === 'MATIC'" class="d-flex flex-row">
              <span class="c-card__safe icon polygon mr-2"></span>
              <span class="c-card__info">MATIC</span>
            </div>
            <div
              *ngIf="data.owner && data.ownerUsername"
              class="d-flex flex-row"
            >
              <span class="c-card__safe icon collections mr-2"></span>
              <span class="c-card__info"> {{ data.ownerUsername }}</span>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col-lg-8">
      <div class="row">
        <div class="col-lg-12">
          <div class="card min-height shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary txt-title">
                Auction prices
              </h6>
            </div>

            <div class="card-body">
              <div class="row center">
                <div class="col-lg-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [(ngModel)]="buyNowOption"
                      id="buyNowOption"
                    />
                    <label class="form-check-label" for="buyNowOption">
                      Buy now option?
                    </label>
                  </div>
                </div>
              </div>
              <div class="row justify-center">
                <div class="col-lg-6 p-t-10" *ngIf="buyNowOption">
                  <form class="user">
                    <div class="form-group">
                      <input
                        class="form-control form-control-user"
                        type="number"
                        placeholder="Buy now price"
                        [(ngModel)]="buyNowPrice"
                        (ngModelChange)="onChangeBuyNowInputAmount()"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </form>
                </div>
                <div class="col-lg-6 p-t-10">
                  <form class="user">
                    <div class="form-group">
                      <input
                        class="form-control form-control-user"
                        type="number"
                        placeholder="Auction start price"
                        [(ngModel)]="minPrice"
                        (ngModelChange)="onChangeInputAmount()"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </form>
                </div>
                <div class="col-lg-6 p-t-10">
                  <div class="user">
                    <div class="form-group">
                      <ng-select
                        placeholder="Select Payment Currency"
                        [items]="currencyDropDownMatic"
                        bindLabel="name"
                        bindValue="id"
                        (change)="currencyHandler()"
                        [(ngModel)]="paymentCurrency"
                      >
                      </ng-select>
                      <div class="mt-4" *ngIf="allowedCurrency == 0">
                        <button
                          class="btn btn-primary full-width"
                          (click)="approveCurrency()"
                        >
                          Approve {{ paymentCurrencyName }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row center justify-center">
                <div class="col-lg-12 p-t-10">
                  <p class="desc-small">
                    The listing will show the final starting price as the first
                    minimum bid, which includes the 5% platform fee to be paid
                    by the buyer. Net proceeds is the amount you will receive
                    upon completion of the auction, which includes both the
                    buyer's 5% fee, the seller's 5% fee, along with any creator
                    royalty fee (if applicable).
                  </p>
                </div>
                <div class="col-lg-12 p-t-10" *ngIf="minPrice">
                  Final start price: {{ listingPrice }}<br />
                  Net Proceeds: {{ receiveAmount }}<br />
                </div>
                <div class="col-lg-12 p-t-10" *ngIf="buyNowPrice">
                  Final buy now price: {{ listingPriceBuyNow }}<br />
                  Net Proceeds of buy now: {{ receiveAmountBuyNow }}<br />
                </div>
                <div class="col-lg-12 p-t-10" *ngIf="hasRoyalty">
                  This NFT has a royalty fee, you will receive
                  {{ royaltyFee }}% less.
                </div>
              </div>
            </div>

            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary txt-title">
                <span *ngIf="!selectedDate">Auction end date</span>
                <span *ngIf="selectedDate">
                  End date (Local):
                  {{ selectedDate | date: "dd/MM/YYYY HH:mm:ss" }}
                </span>
              </h6>
            </div>

            <div class="card-body">
              <div class="row center">
                <div class="col-lg-12" *ngIf="!selectedDate">
                  <!--<dl-date-time-picker
                    startView="day"
                    maxView="year"
                    minView="minute"
                    minuteStep="5"
                    [(ngModel)]="selectedDate"
                    (ngModelChange)="checkIfCanApprove()"
                  >-->
                  <dl-date-time-picker
                    startView="day"
                    maxView="year"
                    minView="minute"
                    minuteStep="5"
                    [(ngModel)]="selectedDate"
                  >
                  </dl-date-time-picker>
                </div>

                <div class="col-lg-12" *ngIf="selectedDate">
                  <div class="row justify-center">
                    <div class="col-lg-6" *ngIf="isApproved">
                      <button
                        class="btn btn-primary full-width"
                        (click)="selectedDate = null"
                      >
                        Change Date
                      </button>
                    </div>

                    <div class="col-lg-12 p-b-20" *ngIf="!isApproved">
                      <div class="row justify-center">
                        <div class="col-lg-6">
                          <button
                            class="btn btn-primary full-width"
                            (click)="selectedDate = null"
                          >
                            Change Date
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6" *ngIf="!isApproved || allowedCurrency === 0">
                      <span *ngIf="!enoughBalance"
                        >You need to hold at least 3,000 $DIGI to create an
                        auction.</span
                      >
                      <button
                        class="btn btn-primary full-width"
                        (click)="approve()"
                      >
                        Approve
                      </button>
                    </div>

                    <div class="col-lg-6">
                      <span *ngIf="!enoughBalance && isApproved"
                        >You need to hold at least 3,000 $DIGI to create an
                        auction.</span
                      >
                      <button
                        *ngIf="enoughBalance"
                        class="btn btn-primary full-width"
                        (click)="create()"
                        [disabled]="!isApproved"
                      >
                        Create Auction
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
