<section class="c-home">
  <div class="c-home__overlay"></div>
  <div class="c-home__bg"></div>
  <div class="c-home__container d-flex">
    <div style="align-items: center" class="row">
      <div class="col-lg-12 text-center">
        <h1 class="c-home__title">
          <span class="text-blue">Digible.</span>
          <br />
          <span class="c-home__title-span">
            The world's first NFT marketplace backed by rare physical
            collectibles.
          </span>
        </h1>
      </div>
    </div>
    <div class="row justify-center">
      <div class="col-6 col-md-4 col-lg-3 col-xl-2 right">
        <a
          [routerLink]="['for-sale']"
          class="c-home__button btn btn-secondary mb-2"
        >
          <span>Buy</span>
        </a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 col-xl-2 left">
        <a
          [routerLink]="['/for-sale/create']"
          class="c-home__button btn btn-primary mb-2"
        >
          <span>Sell</span>
        </a>
      </div>
    </div>
  </div>
</section>
<section class="container p-b-40">
  <div class="row">
    <div class="col-md-8 col-lg-9 p-t-10 p-b-6">
      <div class="section-title">
        <span class="icon light"></span>
        <span>Newest</span>
      </div>
    </div>
    <div class="col-md-4 col-lg-3 right">
      <ng-select
        placeholder="Filter By"
        [items]="filterBy"
        bindLabel="name"
        bindValue="id"
        (change)="changeFilter()"
        [(ngModel)]="typeFilter"
      >
      </ng-select>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>

  <div class="row" *ngIf="nftList === null">
    <div class="col">
      <div class="mx-auto text-center mt-4 text-white">
        <app-loading></app-loading>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="nftList && nftList.length === 0">
    <div class="col">
      <div class="mx-auto text-center mt-4">
        <b>No data available</b>
      </div>
    </div>
  </div>

  <div class="row m-auto pt-4" id="list-result" *ngIf="nftList != null">
    <div
      *ngFor="let nft of unfilteredNftList"
      class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
    >
      <app-moralis-card
        class="c-card-container"
        [data]="nft"
      ></app-moralis-card>
    </div>
  </div>

  <div class="row center p-t-10">
    <div class="col-lg-12">
      <button *ngIf="loading" class="btn btn-primary mt-4 mb-4">. . .</button>
      <button
        *ngIf="!loading && nftList != null && !endReached"
        class="btn btn-primary mt-4 mb-4"
        (click)="loadMore()"
      >
        Load more
      </button>
    </div>
  </div>
</section>
