import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Admin Components
import { AdminComponent } from './components/admin/admin.component';
// Home
import { HomeComponent } from './components/home/home.component';

// Web 2 components
import { CollectionComponent } from './components/collection/collection.component';
import { CreateCollectionComponent } from './components/create-collection/create-collection.component';
import { CreateCollectionItemComponent } from './components/create-collection-item/create-collection-item.component';

// Web 3 components
import { ProfileComponent } from './components/profile/profile.component';
import { RankingsComponent } from './components/rankings/rankings.component';
import { SearchComponent } from './components/search/search.component';
import { StakingComponent } from './components/staking/staking.component';
import { ClaimCardComponent } from './components/claim-card/claim-card.component';
import { CollectionsComponent } from './components/collections/collections.component';
import { CreateAuctionComponent } from './components/create-auction/create-auction.component';
import { CreateCardComponent } from './components/create-card/create-card.component';
import { CreateSellPriceComponent } from './components/create-sell-price/create-sell-price.component';
import { CreateSellComponent } from './components/create-sell/create-sell.component';
import { DetailsComponent } from './components/details/details.component';
import { DuelDetailsComponent } from './components/duel-details/duel-details.component';
import { DuelsComponent } from './components/duels/duels.component';
import { ExplorerComponent } from './components/explorer/explorer.component';
// import { LeaderboardComponent } from './components/leaderboard/leaderboard.component';
import { ForSaleComponent } from './components/for-sale/for-sale.component';
import { DigiWaxComponent } from './components/digiwax/digiwax.component';
import { LeaderboardComponent } from './components/leaderboard/leaderboard.component';
import { PrivateSaleComponent } from './components/private-sale/private-sale.component';
import { TradeComponent } from './components/trade/trade.component';
import { StakingV2Component } from './components/staking-v2/staking-v2.component';

// About page components
import { AboutDigiTeamComponent } from './components/about/digi-team/digi-team.component';
import { AboutDigiDuelComponent } from './components/about/digi-duel/digi-duel.component';
import { AboutDigiFarmComponent } from './components/about/digi-farm/digi-farm.component';
import { AboutDigiSafeComponent } from './components/about/digi-safe/digi-safe.component';
import { AboutDigiGradeComponent } from './components/about/digi-grade/digi-grade.component';
import { AboutDigiTrackComponent } from './components/about/digi-track/digi-track.component';
import { AboutDigiRoadMapComponent } from './components/about/road-map/digi-road-map.component';

import { FaqComponent } from './components/about/faq/faq.component';
import { TermsAndConditionsComponent } from './components/about/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/about/privacy-policy/privacy-policy.component';

// User Account/Auth Components
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component'
import { ChangePasswordComponent } from './components/change-password/change-password.component'
import { RegisterComponent } from './components/register/register.component';
import { MyCollectionsComponent } from './components/my-collections/my-collections.component';
import { becomeASellerComponent } from './components/become-seller/become-seller.component';
import { UserAccountComponent } from './components/user-account/user-account.component';
import { MyFavouritesComponent } from './components/my-favourites/my-favourites.component';

import { LayoutComponent } from './layout/layout.component';
import { PurchaseComponent } from './components/purchase/purchase.component';
import { SellerDetailComponent } from './components/seller-detail/seller-detail.component'
import { CollectionDetailComponent } from './components/collection-detail/collection-detail.component'

import { AuthGuard } from './helpers/auth.guard';
import { UsersListComponent } from './components/users-list/users-list.component';
import { ItemDetailsComponent } from './components/item-details/item-details.component';
import { UserOrderDetailComponent } from './components/user-order-detail/user-order-detail.component'
import { EducationalComponent } from './components/educational/educational.component'
import { CartComponent } from './components/cart/cart.component';
import { MessagingComponent } from './components/messaging/messaging.component';
import { EmailVerifyMessageComponent } from './components/email-verify-message/email-verify-message.component';
import { MyAuctionsComponent } from './components/my-auctions/my-auctions.component';
import { AuctionsComponent } from './components/auctions/auctions.component';
import { CollectionHistoryComponent } from './components/admin/collection-history/collection-history.component';
import { SellerItemDetailComponent } from './seller-item-detail/seller-item-detail.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'forget-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'change-password/:token',
        component: ChangePasswordComponent,
      },
      { path: 'register', component: RegisterComponent },
      {
        path: 'user/:id',
        canActivate: [AuthGuard],
        component: UserAccountComponent,
      },
      {
        path: 'messages',
        canActivate: [AuthGuard],
        component: MessagingComponent,
      },
      {
        path: 'email/verify-success',
        component: EmailVerifyMessageComponent,
      },
      {
        path: 'email/verify-failure',
        component: EmailVerifyMessageComponent,
      },
      {
        path: 'my-favourites',
        component: MyFavouritesComponent,
      },
      {
        path: 'my-auctions',
        component: MyAuctionsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'my-collections/:role',
        component: MyCollectionsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'collection/create/:role',
        component: CreateCollectionComponent,
        canActivate: [AuthGuard]
      }, {
        path: 'collection-item/create/:id/:userId',
        component: CreateCollectionItemComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'collection/:id/:userId',
        component: CollectionComponent,
      },
      {
        path: 'stake-v2',
        component: StakingV2Component,
      },
      {
        path: 'trade',
        component: TradeComponent,
      },
      {
        path: 'digiwax',
        component: DigiWaxComponent,
      },
      {
        path: 'collections',
        component: CollectionsComponent,
      },
     /*  {
        path: 'collections/leaderboard',
        component: LeaderboardComponent,
      }, */
      {
        path: 'search',
        component: SearchComponent,
      },
      {
        path: 'explore',
        component: ExplorerComponent,
      },
      {
        path: 'for-sale',
        component: ForSaleComponent,
      },
      {
        path: 'explore/create',
        component: CreateSellComponent,
      },
      {
        path: 'for-sale/create/:network/:tokenAddress/:id',
        component: CreateSellPriceComponent,
      },
      {
        path: 'purchase',
        component: PurchaseComponent,
      },
      {
        path: 'stake',
        component: StakingComponent,
      },
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'sale',
        component: PrivateSaleComponent,
      },
      {
        path: 'auctions',
        component: AuctionsComponent,
      },
      {
        path: 'auctions/create/:network/:id/:tokenAddress',
        component: CreateAuctionComponent,
      },
      {
        path: 'rankings',
        component: RankingsComponent,
      },
      {
        path: 'details/onchain/:network/:id/:tokenAddress',
        component: DetailsComponent,
      },
      {
        path: 'details/offchain/:network/:id/:tokenAddress',
        component: DetailsComponent,
      },
      {
        path: 'claim/:id',
        component: ClaimCardComponent,
      },
      {
        path: 'profile/:address',
        component: ProfileComponent,
      },
      {
        path: 'create',
        component: CreateCardComponent,
      },
      {
        path: 'duels',
        component: DuelsComponent,
      },
      {
        path: 'duel/:id',
        component: DuelDetailsComponent,
      },
      {
        path: 'admin',
        /* data: {
          role: 'ADMIN'
        }, */
        // canActivate: [AuthGuard],
        component: AdminComponent,
      },
      {
        path: 'admin/collections',
        component: CollectionHistoryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'digi-team',
        component: AboutDigiTeamComponent,
      },
      {
        path: 'digi-duel',
        component: AboutDigiDuelComponent,
      },
      {
        path: 'digi-farm',
        component: AboutDigiFarmComponent,
      },
      {
        path: 'digi-safe',
        component: AboutDigiSafeComponent,
      },
      {
        path: 'digi-grade',
        component: AboutDigiGradeComponent,
      },
      {
        path: 'digi-track',
        component: AboutDigiTrackComponent,
      },
      {
        path: 'digi-road-map',
        component: AboutDigiRoadMapComponent,
      },
      {
        path: 'become-a-seller',
        component: becomeASellerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'faq',
        component: FaqComponent,
      },
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
      },
      {
        path: 'admin/seller-detail/:id',
        component: SellerDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'admin/collection-detail/:id',
        component: CollectionDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'admin/user-list',
        component: UsersListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'item-details/:id',
        component: ItemDetailsComponent,
      },
      {
        path: 'cart',
        component: CartComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'admin/category',
        loadChildren: () => import('./category/category.module').then(module => module.CategoryModule)
      },
      {
        path: 'admin/system-admin',
        loadChildren: () => import('./system-admin/system-admin.module').then(module => module.SystemAdminModule)
      },
      {
        path: 'user-order-details/:id/:role',
        component: UserOrderDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'seller-item-history',
        component: SellerItemDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'education',
        component: EducationalComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
