import { Component } from '@angular/core';

@Component({
  selector: 'app-digi-farm',
  templateUrl: './digi-farm.component.html',
  styleUrls: ['./digi-farm.component.scss'],
})
export class AboutDigiFarmComponent {
  
}
