import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WalletService } from 'src/app/services/wallet.service';
import { environment } from 'src/environments/environment';
import { MarketplaceService } from '../../services/marketplace.service';
import { MathService } from '../../services/math.service';
import { NftService } from '../../services/nft.service';
import { Moralis } from 'moralis';

@Component({
  selector: 'app-create-sell-price',
  templateUrl: './create-sell-price.component.html',
  styleUrls: ['./create-sell-price.component.scss'],
})
export class CreateSellPriceComponent implements OnInit {
  data: any = {};
  preData: any = {};
  id;
  network;
  address;
  loading = false;
  inputAmount;
  stableSymbol = environment.stableCoinSymbol;
  digibleNftAddress;
  isApproved;
  fee;
  royaltyFee;
  sale;
  selectedDate;
  hasRoyalty = false;
  listingPrice;
  enoughBalance;
  receiveAmount;
  paymentCurrency;
  paymentCurrencyName;
  allowedCurrency;
  currencyDropDownMatic = [
    {
      name: 'DIGI',
      id: environment.maticCoinContractAddresses.digiAddressMatic,
    },
    {
      name: 'USDC',
      id: environment.maticCoinContractAddresses.usdcAddressMatic,
    },
    {
      name: 'USDT',
      id: environment.maticCoinContractAddresses.usdtAddressMatic,
    },
    {
      name: 'LINK',
      id: environment.maticCoinContractAddresses.linkAddressMatic,
    },
    /* { name: 'MATIC', id: environment.maticCoinContractAddresses.maticCoinAddress },
    { name: 'ETH', id: environment.maticCoinContractAddresses.ethAddressMatic }, */
  ];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly nft: NftService,
    private readonly market: MarketplaceService,
    private readonly math: MathService,
    private readonly router: Router,
    private readonly wallet: WalletService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const date = new Date();
    date.setDate(date.getDate() + 14); // In two weeks
    this.selectedDate = date;
    this.paymentCurrency = this.currencyDropDownMatic[0].id;
    this.route.params.subscribe((queryParams) => {
      this.preData = {
        id: queryParams.id,
        network: queryParams.network,
        tokenAddress: queryParams.tokenAddress,
      };
    });
    this.loadData();
  }

  async checkAllowed() {
    this.allowedCurrency = await this.market.checkAllowedForSale(
      environment.marketplaceAddressMatic,
      this.data.network,
      this.paymentCurrency
    );
    console.log(this.allowedCurrency);
  }

  currencyHandler() {
    this.currencyDropDownMatic.forEach((item) => {
      if (item.id === this.paymentCurrency) {
        this.paymentCurrencyName = item.name
      }
    });
    this.checkAllowed();
  }

  async loadData(): Promise<void> {
    let chain;
    if (environment.production) {
      chain = 'Matic';
    } else {
      chain = 'mumbai';
    }
    this.data = {
      ...(await Moralis.Web3API.token.getTokenIdMetadata({
        address: this.preData.tokenAddress,
        token_id: this.preData.id,
        chain: chain,
      })),
      ...this.preData,
    };
    console.log('AA', this.data);
    this.getCardDetails().then((res) => {
      this.checkAllowed();
      this.checkSale();
      this.checkApprove();
      this.loadHasLoyalty();
      this.loadFee();
    });
    /*   this.checkMinAmount();
    this.loadFees();
    this.loadRoyalty(); */
  }

  async approveCurrency(): Promise<void> {
    this.loading = true;
    let currency;
    try {
      await this.nft.approve(
        this.market.getMarketplaceAddress(this.data.network),
        this.data.network,
        this.paymentCurrency
      );
    } catch (e) {
      console.log(e);
    }
    this.checkAllowed();
    this.loading = false;
  }

  async checkSale(): Promise<void> {
    const sale = await this.market.getSaleForToken(
      this.data.tokenAddress,
      parseInt(this.data.id, undefined),
      this.data.network
    );

    if (sale && sale.available) {
      this.sale = sale;
    }
  }

  async loadFee(): Promise<void> {
    this.fee = await this.market.getFee(this.data.network);
  }

  async approveSell(): Promise<void> {
    this.loading = true;
    try {
      await this.nft.setApprovalForAll(
        this.market.getMarketplaceAddress(this.data.network),
        this.data.network,
        this.data.tokenAddress
      );
      this.checkApprove();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  async getCardDetails(): Promise<void> {
    this.data.tokenAddress = await this.wallet.getAccount();
    console.log(this.data);

    if (this.data.token_uri) {
      let url = this.data.token_uri;
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          console.log('TOKEN_URI DATA: ', data);
          this.data.image = data.image;
          this.data.name = data.name;
          this.data.description = data.description;
          this.data.owner = this.data.owner_of;
          this.data.ownerAddress = this.data.owner_of;
          this.data.network = this.data.network;
          this.data.physical = data.physical;
          this.data.tokenAddress = this.data.token_address;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  /* async checkApproveSell(): Promise<void> {
    this.showApproveSell = !(await this.nft.isApprovedForAll(await this.walletService.getAccount(), this.market.getMarketplaceAddress(this.data.network), this.data.network, this.data.tokenAddress));
    this.cdr.detectChanges();
  } */

  onChangeInputAmount(): void {
    setTimeout(() => {
      this.listingPrice = Number(this.inputAmount) * 1.05;
      if (this.hasRoyalty) {
        this.receiveAmount =
          this.inputAmount * 0.95 - this.inputAmount * (this.royaltyFee / 100);
      } else {
        this.receiveAmount = this.inputAmount - this.inputAmount * 0.1;
      }
      this.receiveAmount = this.receiveAmount.toFixed(2);
    }, 100);
  }

  async loadHasLoyalty(): Promise<void> {
    if (
      this.data.tokenAddress.toLowerCase() !==
      this.data.tokenAddress.toLowerCase()
    ) {
      return;
    }
    this.hasRoyalty = await this.market.hasRoyalty(
      this.data.tokenAddress,
      this.data.id,
      this.data.network
    );
    if (this.hasRoyalty) {
      this.royaltyFee =
        (await this.market.getRoyaltyFee(this.data.id, this.data.network)) /
        100;
    }
  }
  async checkApprove(): Promise<void> {
    this.isApproved = await this.nft.isApprovedForAll(
      await this.wallet.getAccount(),
      this.market.getMarketplaceAddress(this.data.network),
      this.data.network,
      this.data.tokenAddress
    );
    console.log(this.isApproved);
    this.cdr.detectChanges();
  }

  async approve(): Promise<void> {
    this.loading = true;
    try {
      await this.nft.setApprovalExternalForAll(
        this.data.tokenAddress,
        this.market.getMarketplaceAddress(this.data.network)
      );
      this.checkApprove();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  async checkMinAmount(): Promise<void> {
    const digiBalance = await this.nft.digiBalance();
    if (digiBalance < 3000 * 10 ** 18) {
      this.enoughBalance = false;
    } else {
      this.enoughBalance = true;
    }
  }

  async sell(): Promise<void> {
    this.loading = true;
    const endDate = parseInt(
      this.selectedDate.getTime() / 1000 + '',
      undefined
    );
    const currentDate = parseInt(new Date().getTime() / 1000 + '', undefined);
    const digiBalance = await this.nft.digiBalance();
    if (digiBalance < 3000 * 10 ** 18) {
      alert('You need to hold at least 3,000 $DIGI');
      this.loading = false;
      return;
    }
    try {
      console.log(
        this.data.id,
        this.data.tokenAddress,
        this.math.toBlockchainValue(this.listingPrice),
        endDate - currentDate,
        this.data.network,
        this.paymentCurrency
      );

      await this.market.createSale(
        this.data.id,
        this.data.tokenAddress,
        this.math.toBlockchainValue(this.listingPrice),
        endDate - currentDate,
        this.data.network,
        this.paymentCurrency
      );
      this.router.navigate(['/for-sale']);
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }
}
