<div class="container page-top p-b-40">
  <div class="row">
    <div class="col-12 p-t-10 p-b-6">
      <span class="section-title">
        <span class="icon wallet"> </span>Public Sale</span
      >
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>

  <div class="row justify-center p-t-50" *ngIf="false">
    <div class="col-lg-12 center">
      <p class="pass-title">Seed Sale Password</p>
    </div>

    <div class="col-lg-4">
      <form class="user">
        <div class="form-group">
          <input
            type="password"
            class="form-control form-control-user"
            placeholder="Password..."
            #salePasswordInput
          />
        </div>
        <div class="row justify-center">
          <div class="col-lg-7">
            <button
              class="button full-width"
              type="button"
              href="javascript:;"
              (click)="checkPrivateSalePassword(salePasswordInput.value)"
            >
              Enter
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="row p-t-20">
        <div class="col-lg-12">
          <div class="card min-height shadow mb-3">
            <div class="card-body">
              <div class="row center">
                <div class="col-lg-12">
                  <b>Public Sale Price</b>
                </div>
                <div class="col-lg-12 p-t-10">~0,03$ per $DIGI</div>
              </div>
              <div class="row center">
                <div class="col-lg-12 p-t-20">
                  <b>Tokens for this round</b>
                </div>
                <div class="col-lg-12 p-t-10">24.5M DIGI (300 ETH)</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card min-height shadow mb-3">
            <div class="card-body">
              <div class="row center">
                <div class="col-lg-12">
                  <b>Public Sale Address</b>
                </div>
                <div class="col-lg-12 p-t-10">
                  {{ digiPublicSaleAddress }}
                </div>
              </div>
              <div class="row center">
                <div class="col-lg-12 p-t-20">
                  <b>Token Address</b>
                </div>
                <div class="col-lg-12 p-t-10">
                  {{ digiTokenAddress }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card min-height shadow">
            <div class="card-body">
              <div class="row center">
                <div class="col-lg-12">
                  <b>{{ ethBalance }} ETH</b>
                </div>
                <div class="col-lg-12 p-t-10">
                  Estimated value: {{ estimatedDigi }} DIGI
                </div>
              </div>
              <div class="row center">
                <div class="col-lg-12 p-t-20">
                  <b>{{ digiBalance }} DIGI</b>
                </div>
                <div class="col-lg-12 p-t-10">
                  Estimated value: {{ estimatedEth }} ETH
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 align-self-center">
      <div class="row justify-center">
        <div class="col-lg-12 center">
          <span class="buy-subtitle">Starts at</span>
        </div>

        <div class="col-lg-12 center p-b-40">
          <span class="buy-subtitle">22/04/2021 15:00:00 UTC</span>
        </div>

        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-12 p-b-20 center">
              <p class="buy-title">Buy Digi</p>
              <span class="buy-subtitle">Min: 0.1 ETH - Max: 0.5 ETH</span>
            </div>
          </div>

          <form class="user">
            <div class="form-group">
              <input
                type="number"
                class="form-control form-control-user"
                placeholder="Amount of ETH to buy"
                #ethInput
                (keyup)="onEthInputChange(ethInput.value)"
                [value]="ethInputValue"
                min="0.1"
                max="0.5"
                step="0.01"
              />
            </div>

            <div class="form-group">
              <input
                type="number"
                class="form-control form-control-user"
                placeholder="Amount of DIGI to receive"
                #digiInput
                (keyup)="onDigiInputChange(digiInput.value)"
                [value]="digiInputValue"
                min="8166.6"
                max="40833"
                step="1000"
              />
            </div>

            <div class="row justify-center">
              <div class="col-lg-12" *ngIf="!address">
                <button (click)="connectWallet()" class="button full-width">
                  Connect
                </button>
              </div>

              <div class="col-lg-12" *ngIf="address">
                <button
                  [attr.disabled]="this.loading ? '' : null"
                  class="button full-width"
                  (click)="buy()"
                >
                  <span>Buy Now</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
