import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { WalletService } from '../../services/wallet.service';
import { Network } from '../../types/network.enum';
import { OffchainService } from '../../services/offchain.service';
import { AdminService } from '../../services/admin.service';
import { NftService } from '../../services/nft.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentService } from '../../services/comment.service'
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  showSwitchToEth = false;
  address;
  sellersForApproval = []
  collectionsForApproval = []
  soldOutCollections = []
  loading = false;
  minter: boolean = null;
  minterMatic: boolean = null;
  collectionSize = 0
  currentPage = 1;
  itemsPerPage = 10;

  collectionDataSize = 0
  collectionCurrentPage = 1;
  collectionItemsPerPage = 10;

  constructor(
    private readonly wallet: WalletService,
    private readonly adminService: AdminService,
    private readonly cdr: ChangeDetectorRef,
    private readonly offchain: OffchainService,
    private readonly nft: NftService,
    private readonly router: Router,
    private alertService: AlertService,
    private readonly commentService: CommentService
  ) {}

  ngOnInit(): void {
    this.checkNetwork();
    this.getSellersForVerification(this.currentPage, this.itemsPerPage);
    this.getCollectionsForApproval(this.collectionCurrentPage, this.collectionItemsPerPage);
    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        this.checkNetwork();
      });
    }
  }

  getCollectionsForApproval(page: number, itemPerPage: number) {
    this.adminService.getCollectionsForApproval(page).then((res:any) => {
      this.collectionsForApproval = res.data;
      this.collectionsForApproval = this.collectionsForApproval.sort((a, b) => b.id - a.id);
      this.collectionCurrentPage = res.current_page;
      this.collectionDataSize = res.total;
      this.collectionItemsPerPage = res.per_page;
    });
  }

  getSellersForVerification(page: number, itemPerPage) {

    this.adminService.getSellersForVerification(page).then((res:any) => {
      this.sellersForApproval = res.data;
      this.currentPage = res.current_page;
      this.collectionSize = res.total;
      this.itemsPerPage = res.per_page;
    });
  }


  public onPageChange(pageNum: number): void {
    this.getSellersForVerification(pageNum, this.itemsPerPage);
  }

  public onCollectionPageChange(pageNum: number): void {
    this.getCollectionsForApproval(pageNum, this.collectionItemsPerPage);
  }

  changeSellerStatus(seller:number, status) {
    // TODO: unwrap promise and show success
    this.adminService.approveSeller(seller, status);
    if(status === 'approved') {
      this.alertService.success('Seller approved');
    } else if(status === 'rejected') {
      this.alertService.error('Seller rejected');
    }
    this.getSellersForVerification(this.currentPage, this.itemsPerPage);
  }

  changeCollectionStatus(collection:number, status) {
    // TODO: unwrap promise and show success
    this.adminService.approveCollection(collection, status);
    this.getCollectionsForApproval(this.collectionCurrentPage, this.collectionItemsPerPage);
  }

  async revoke(matic?: boolean): Promise<void> {
    if (matic && await this.wallet.getNetwork() !== Network.MATIC) {
      alert('Connect to matic chain');
      return;
    } else if (!matic && await this.wallet.getNetwork() !== Network.ETH) {
      alert('Connect to eth chain');
      return;
    }
    this.loading = true;
    try {
      await this.nft.revokeMinterRole(this.address);
      this.onChangeInputAddress();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  async grantMinter(matic?: boolean): Promise<void> {
    if (matic && await this.wallet.getNetwork() !== Network.MATIC) {
      alert('Connect to matic chain');
      return;
    } else if (!matic && await this.wallet.getNetwork() !== Network.ETH) {
      alert('Connect to eth chain');
      return;
    }
    this.loading = true;
    try {
      await this.nft.grantMinterRole(this.address);
      this.onChangeInputAddress();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  async onChangeInputAddress(): Promise<void> {
    setTimeout(async () => {
      this.minter = null;
      if (!this.wallet.getWeb3().utils.isAddress(this.address)) {
        console.log('Invalid address');
        return;
      }
      // this.minter = await this.nft.canMint(this.address);
      // this.minterMatic = await this.nft.canMint(this.address, true);
    }, 200);
  }

  async checkNetwork(): Promise<void> {
    const network = await this.wallet.getNetwork();
    if (network !== Network.ETH) {
      this.showSwitchToEth = true;
    } else {
      this.showSwitchToEth = false;
    }
    this.cdr.detectChanges();
  }
}
