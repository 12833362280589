import { Component } from '@angular/core';

@Component({
  selector: 'app-digi-track',
  templateUrl: './digi-track.component.html',
  styleUrls: ['./digi-track.component.scss'],
})
export class AboutDigiTrackComponent {
  
}
