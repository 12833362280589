import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MarketplaceService } from 'src/app/services/marketplace.service';
import { CollectionsService } from 'src/app/services/collections.service';
import { OffchainService } from 'src/app/services/offchain.service';
import { NftService } from 'src/app/services/nft.service';
import { WalletService } from 'src/app/services/wallet.service';
import { MoralisService } from 'src/app/services/moralis.service';
import { VerifiedWalletsService } from 'src/app/services/verified-wallets.service';
// import { MarketCard } from 'src/app/types/market-card.types';
import { Network } from 'src/app/types/network.enum';
import { DigiCard } from 'src/app/types/digi-card.types';
import { FormControl, FormGroup } from '@angular/forms';
import { Alert } from './Alert';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from '../../services/Authentication.service';
import moment from 'moment';
import { environment } from 'src/environments/environment';

const emailVerifySuccess = '/email/verify-success';
const emailVerifyFail = '/email/verify-failure';
interface Filter {
  isOpen: boolean;
  name: string;
}

enum FilterEnum {
  Status = 'Status',
  Price = 'Price',
  OffChain = 'Off-chain Collections',
  OnChain = 'On-chain Collections',
  Category = 'Categories',
  Blockchain = 'Blockchains',
}
@Component({
  selector: '.app-explore',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss'],
})
export class ExplorerComponent implements OnInit {
  static nftListCached: DigiCard[] = null;
  public isCollapsed = true;
  static cacheUntil: Date = null;
  static lastOffset: number;
  showSwitchToEthereum;
  showAlertMessage: boolean = false;
  currentUser: any;
  isVerifiedEmail: boolean = false;
  isCategoryFilter: boolean = false;
  allVerifiedWalletAddresses;
  isFilterSidebarClose: boolean = false;
  currentRouter: string;
  tokenType: string;
  nftList: DigiCard[] = null;
  value: boolean = false;
  nftListMatic: any;
  // nftListEth: any;
  allCollectionsItems: any;
  onChainCollectionsItems: any;
  showSwitchToMatic = false;
  digibleNftAddress;
  VerifiedWalletsService;
  network: Network;
  onChainCollections = [];
  offChainCollections: any = [];
  collectionsCategories = [];
  singleCollectionCategory = [];
  isCategoryFilterSearched: boolean = false;
  isOnChainFilterSearched: boolean = false;
  isOffChainFilterSearched: boolean = false;
  loading = false;
  currentOffset = 0;
  endReached = false;
  readonly limit = 44;
  typeFilter = 'ALL';
  maticNfts = [];
  ethNFTs = [];
  nftArray = [];
  unfilteredNftList: DigiCard[] = null;
  nftListFiltered: DigiCard[] = null;
  mixedCollectionItems: DigiCard[] = null;
  filterenum = FilterEnum;
  all20CollectionItems = [];
  filterBy = [
    { name: 'All', id: 'ALL' },
    { name: 'Show Physical', id: 'PHYSICAL' },
    { name: 'Show DIGITAL', id: 'DIGITAL' },
    { name: 'Price Ascending', id: 'PRICE UP' },
    { name: 'Price Descending', id: 'PRICE DOWN' },
  ];

  priceFilter = new FormGroup({
    minPrice: new FormControl(),
    maxPrice: new FormControl(),
  });
  categoryFilter = '';
  offChainCollectionFilter = '';
  offChainFilter = false;
  onChainFilter = false;
  latestFilter = false;
  approvedStatusFilter = false;
  auctionStatusFilter = false;
  buyNowStatusFilter = false;
  isCategory;

  alerts: Alert[] = [];

  public get price(): boolean {
    return this.priceFilter.value.minPrice !== null && this.priceFilter.value.maxPrice !== null;
  }

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: any,
    private readonly nft: NftService,
    private readonly market: MarketplaceService,
    private readonly collectionsService: CollectionsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    public router: Router,
    private readonly wallet: WalletService,
    private authenticationService: AuthenticationService,
    private moralis: MoralisService,
    private readonly offchain: OffchainService
  ) {
    this.document.body.classList.add('explore');
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRouter = event.url;
      });
  }

  ngOnDestroy() {
    this.document.body.classList.remove('explore');
  }

  ngOnInit(): void {
    this.nft.getNftAddress(true).then((address) => {
      this.digibleNftAddress = address;
    });

    this.loadData();
    this.getAllCollectionCategories();
    this.getOnChainCollections();
    this.getOffChainCollections();
    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        this.loadData();
        this.checkNetwork();
      });
    }
    this.authenticationService?.currentUser.subscribe((x) => {
      this.currentUser = x;
      this.currentUser = this.currentUser?.data
        ? this.currentUser?.data
        : this.currentUser;
      this.isVerifiedEmail = this.currentUser?.verified;
      this.tokenType = this.currentUser?.token_type;
      this.alertValidation();
    });
  }

  toggleFilter() {
    document.body.classList.toggle('c-filter--active');
  }

  alertValidation() {
    if (
      (this.isVerifiedEmail === false &&
        this.currentRouter !== '/login' &&
        this.currentRouter !== '/register' &&
        this.currentRouter !== emailVerifySuccess &&
        this.currentRouter !== emailVerifyFail &&
        this.tokenType === 'Bearer') === true
    ) {
      this.showAlertMessage = true;
    }
  }

  setFilter(e) {
    const inputValue = (
      document.getElementById('searchInp') as HTMLInputElement
    ).value;
    if (inputValue === '') {
      return;
    }
    if (e.code === 'Enter' || e.type == 'click') {
      document.location.href = '/explore?search=' + inputValue;
    }
  }

  filters: Filter[] = [
    { isOpen: false, name: this.filterenum.Status },
    { isOpen: false, name: this.filterenum.Price },
    { isOpen: false, name: this.filterenum.OffChain },
    { isOpen: false, name: this.filterenum.OnChain },
    { isOpen: false, name: this.filterenum.Category },
    { isOpen: false, name: this.filterenum.Blockchain },
  ];

  onClickFilterSidebarArrow() {
    this.isFilterSidebarClose = !this.isFilterSidebarClose;
  }

  toggleMenu(event, name: string) {
    this.onToggleDropdown(name);
    event.preventDefault();
    event.stopPropagation();
    if (event.target.classList.contains('title-span')) {
      event.target.parentNode.nextElementSibling.classList.toggle('active');
    } else if (event.target.classList.contains('c-filter__title')) {
      event.target.nextElementSibling.classList.toggle('active');
    }
  }

  onToggleDropdown(name: string) {
    var index = this.filters.findIndex((item) => item.name === name);
    if (index > -1) {
      this.filters[index].isOpen = !this.filters[index].isOpen;
    }
  }

  isOpen(name: string) {
    const index = this.filters.findIndex((item) => item.name === name);
    if (index > -1) {
      return this.filters[index].isOpen;
    }
  }

  arrowToggleMenu(element: Element) {
    if (element?.classList.contains('active')) {
      element.classList.remove('active');
    } else {
      element?.classList.add('active');
    }
  }

  applyPriceFilter(): void {
    if (this.priceFilter.value.minPrice < this.priceFilter.value.maxPrice) {
      this.value = false;
      this.applyFilters();
      var message = '';
      var minPrice = this.priceFilter.value.minPrice;
      if (minPrice > -1) {
        message += minPrice + '$ ' + 'to';
      }

      var maxPrice = this.priceFilter.value.maxPrice;
      if (maxPrice != '') {
        message += ' ' + maxPrice + '$';
      }

      const index = this.alerts.findIndex(
        (alert) => alert.action === 'priceFilter'
      );
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: message,
          action: 'priceFilter',
        });
      } else {
        this.alerts[index].message = message;
      }
    } else {
      this.value = true;
    }
  }

  onKeyPress(event: any) {
    return event.charCode === 8 || event.charCode === 0 || event.charCode === 13
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }

  applyFilters(): void {
    this.mixedCollectionItems = [
      ...this.allCollectionsItems,
    ]
      .slice()
      .sort(() => Math.random() - 0.5);

    // this.mixedCollectionItems = this.mixedCollectionItems.filter(item => { item.available
    //   moment(this.item.end_date).diff(moment(new Date())) <= 0
    // });
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.priceFilter.value.minPrice === undefined ||
        this.priceFilter.value.minPrice === null ||
        this.priceFilter.value.minPrice <= item.price
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.priceFilter.value.maxPrice === undefined ||
        this.priceFilter.value.maxPrice === null ||
        this.priceFilter.value.maxPrice >= item.price
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.categoryFilter === undefined ||
        this.categoryFilter === null ||
        this.categoryFilter === '' ||
        this.categoryFilter === item?.collection_item_type?.id
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.offChainCollectionFilter === undefined ||
        this.offChainCollectionFilter === null ||
        this.offChainCollectionFilter === '' ||
        this.offChainCollectionFilter === item?.collection_id
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.offChainFilter === undefined ||
        this.offChainFilter === null ||
        this.offChainFilter === false ||
        item?.is20Item === true
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.onChainFilter === undefined ||
        this.onChainFilter === null ||
        this.onChainFilter === false ||
        item?.is20Item === false
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.approvedStatusFilter === undefined ||
        this.approvedStatusFilter === null ||
        this.approvedStatusFilter === false ||
        item?.status === 'approved'
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.auctionStatusFilter === undefined ||
        this.auctionStatusFilter === null ||
        this.auctionStatusFilter === false ||
        item?.available_for_sale === 2
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.buyNowStatusFilter === undefined ||
        this.buyNowStatusFilter === null ||
        this.buyNowStatusFilter === false ||
        item?.available_for_sale === 1
    );
  }

  applyCategoryFilter(category) {
    var index = this.alerts.findIndex(alert => alert.action === "Category");
    if(this.categoryFilter === category?.id || this.categoryFilter === ""){
    this.isCategoryFilter = !this.isCategoryFilter;
    }else if (this.categoryFilter !== category?.id && this.categoryFilter !== "") {
    this.isCategoryFilter = true;
    }
    this.highlightAppliedCategoryFilter(category)
    if(this.isCategoryFilter){
    if (index === -1 ) {
    this.alerts.push({
    type: 'success',
    message: category.name,
    action: "Category"
    });
    }
    else {
    this.alerts[index].message = category.name;
    }
    }
    else{
    if(index > -1) {
    this.alerts.splice(index, 1);
    if(this.categoryFilter !== category?.id){
    this.categoryFilter = null;
    this.applyCategoryFilter(category);
    }else{
    this.clearCategoryFilter();
    return;
    }
    }
    }
    this.categoryFilter = category?.id;
    this.applyFilters();
    }

  highlightAppliedCategoryFilter(category) {
    this.collectionsCategories.forEach((item) => {
      if (item.id !== category.id) {
        item.selected = false;
      } else {
        item.selected = !item.selected;
      }
    });
  }

  clearCategoryFilter() {
    this.categoryFilter = '';
    const index = this.alerts.findIndex((alert) => alert.action === 'Category');
    if (index > -1) {
      this.alerts.splice(index, 1);
    }
    this.collectionsCategories.forEach((item) => {
      item.selected = false;
    });
    this.applyFilters();
  }

  clearOffChainCollectionFilter() {
    this.offChainCollectionFilter = '';
    const index = this.alerts.findIndex(
      (alert) => alert.action === 'offChainCollections'
    );
    if (index > -1) {
      this.alerts.splice(index, 1);
    }
    this.applyFilters();
  }

  applyOffChainCollectionFilter(collection): void {
    const index = this.alerts.findIndex(
      (alert) => alert.message === collection.name
    );
    if (index === -1) {
      this.alerts.push({
        type: 'success',
        message: collection.name,
        action: 'offChainCollections',
      });
    } else {
      this.alerts[index].message = collection.name;
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }

    this.offChainCollectionFilter = collection?.id;
    this.applyFilters();
  }
  clickOnChainStatusFilter(element: Element): void {
    if(element?.classList.contains('status-filter-color')){
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    this.onChainFilter = !this.onChainFilter;
    const index = this.alerts.findIndex(alert => alert.action === 'onChainFilter');
    if(this.onChainFilter){
      if(index === -1 ) {
        this.alerts.push({
          type: 'success',
          message: "On Chain",
          action: "onChainFilter"
        });
      }
     } else {
      if(index > -1) {
        this.alerts.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  clickOffChainStatusFilter(element: Element): void {
    if(element?.classList.contains('status-filter-color')){
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    this.offChainFilter = !this.offChainFilter;
    const index = this.alerts.findIndex(
      (alert) => alert.action === 'offChainFilter'
    );
    if (this.offChainFilter) {
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: 'Off Chain',
          action: 'offChainFilter',
        });
      }
    } else {
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  // clickApprovedStatusFilter(): void {
  //   const index = this.alerts.findIndex(alert => alert.action === 'approvedFilter');
  //   this.approvedStatusFilter = !this.approvedStatusFilter;
  //   if (this.approvedStatusFilter) {
  //     if(index === -1 ) {
  //       this.alerts.push({
  //         type: 'success',
  //         message: "Approved Status",
  //         action: "approvedFilter"
  //       });
  //     }
  //   } else {
  //     if(index > -1) {
  //       this.alerts.splice(index, 1);
  //     }
  //   }
  //   this.applyFilters();
  // }

  clickAuctionStatusFilter(element: Element): void {
    if(element?.classList.contains('status-filter-color')){
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    const index = this.alerts.findIndex(
      (alert) => alert.action === 'auctionFilter'
    );
    this.auctionStatusFilter = !this.auctionStatusFilter;
    if (this.auctionStatusFilter) {
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: "Auction",
          action: "auctionFilter"
        });
      }
    } else {
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  clickBuyNowStatusFilter(element: Element): void {
    if(element?.classList.contains('status-filter-color')){
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    const index = this.alerts.findIndex(
      (alert) => alert.action === 'buyNowFilter'
    );
    this.buyNowStatusFilter = !this.buyNowStatusFilter;
    if (this.buyNowStatusFilter) {
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: "Buy Now",
          action: "buyNowFilter"
        });
      }
    } else {
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  clickAllDataFilter(element: Element): void {
    if(element?.classList.contains('status-filter-color')){
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    const index = this.alerts.findIndex((alert) => alert.action === 'allData');
    if (index === -1) {
      this.alerts.push({
        type: 'success',
        message: 'All Data',
        action: 'allData',
      });
    }else{
      if(index > -1) {
        this.alerts.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  close(alert: Alert) {
    if(alert.action === 'clearCategoryFilter') {
    this.categoryFilter = null;
    }
    if(alert.action === 'allData') {
    var element = document.getElementById("allData");
    if(element?.classList.contains('status-filter-color')){
    element.classList.remove('status-filter-color');
    }
    }
    if(alert.action === 'auction') {
    var element = document.getElementById("auction");
    if(element?.classList.contains('status-filter-color')){
    element.classList.remove('status-filter-color');
    }
    }
    if(alert.action === 'buyNowFilter') {
    var element = document.getElementById("buyNow");
    if(element?.classList.contains('status-filter-color')){
    element.classList.remove('status-filter-color');
    }

    }
    if(alert.action === 'latestFilter') {
    var element = document.getElementById("latest");
    if(element?.classList.contains('status-filter-color')){
    element.classList.remove('status-filter-color');
    }
    }
    if(alert.action === 'onChainFilter') {
    this.onChainFilter = false;
    var element = document.getElementById("onChain");
    if(element?.classList.contains('status-filter-color')){
    element.classList.remove('status-filter-color');
    }
    }
    if(alert.action === 'offChainFilter') {
    this.offChainFilter = false;
    var element = document.getElementById("offChain");
    if(element?.classList.contains('status-filter-color')){
    element.classList.remove('status-filter-color');
    }
    }
    if (alert.action === 'approvedFilter') {
    this.approvedStatusFilter = false;
    }
    if (alert.action === 'priceFilter') {
    this.priceFilter.reset();
    }

    if (alert.action === 'offChainCollection') {
    this.offChainCollectionFilter = null;
    }
    if (alert.action === 'auctionFilter') {
    this.auctionStatusFilter = null;
    }

    if (alert.action === 'buyNowFilter') {
    this.buyNowStatusFilter = null;
    }
    if(alert.action === 'Category'){
    this.categoryFilter = null;
    this.collectionsCategories.forEach(item =>{
    item.selected = false;
    });
    }

    this.applyFilters();
    this.alerts.splice(this.alerts.indexOf(alert), 1);
    }

  clearAllFiltersClick(): void {
    this.mixedCollectionItems = [
      // ...this.all20CollectionItems,
      ...this.allCollectionsItems,
      // ...this.nftListMatic,
    ].slice().sort(() => Math.random() - 0.5);
    this.offChainFilter = false;
    this.onChainFilter = false;
    this.approvedStatusFilter = false;
    this.auctionStatusFilter = false;
    this.buyNowStatusFilter = false;
    this.categoryFilter = '';
    this.isCategoryFilter = false;
    this.offChainCollectionFilter = '';
    this.priceFilter.reset();
    this.alerts = [];
    var element = document.getElementById("allData");
    if(element?.classList.contains('status-filter-color')){
      element.classList.remove('status-filter-color');
    }
    var element = document.getElementById("onChain");
    if(element?.classList.contains('status-filter-color')){
      element.classList.remove('status-filter-color');
    }
    var element = document.getElementById("offChain");
    if(element?.classList.contains('status-filter-color')){
      element.classList.remove('status-filter-color');
    }
    var element = document.getElementById("auction");
    if(element?.classList.contains('status-filter-color')){
      element.classList.remove('status-filter-color');
    }
    var element = document.getElementById("buyNow");
    if(element?.classList.contains('status-filter-color')){
      element.classList.remove('status-filter-color');
    }
    var element = document.getElementById("latest");
    if(element?.classList.contains('status-filter-color')){
      element.classList.remove('status-filter-color');
    }
    this.collectionsCategories.forEach((item) => {
      item.selected = false;
    });
  }

  applyLatestFilter(element: Element): void {
    if(element?.classList.contains('status-filter-color')){
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    this.collectionsService.getLatestCollectionItem().subscribe((res: any) => {
      this.all20CollectionItems = res.data.filter(
        (item) =>
          (item?.available_for_sale === 1 &&
            moment(item?.available_at).diff(moment(new Date())) <= 0) ||
          item.collection?.user_id === this.currentUser?.id ||
          item?.available_for_sale === 2 ||
          item?.available_for_sale === 0
      );
      this.all20CollectionItems.forEach((element) => {
        element.is20Item = true;
      });
      this.mixedCollectionItems = [...this.all20CollectionItems]
        .slice()
        .sort(() => Math.random() - 0.5);
      const index = this.alerts.findIndex(
        (alert) => alert.action === 'latestFilter'
      );
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: 'Latest',
          action: 'latestFilter',
        });
      } else {
        if (index > -1) {
          this.alerts.splice(index, 1);
          this.applyFilters();
        }
      }
    });
  }

  changeFilter(item): void {
    this.loading = true;
    setTimeout(async () => {
      /* if (item === 'ALL') {
        this.nftList = this.nftList;
        this.loading = false;
        return;
      }
      if (item === 'MATIC') {
        const owner = await this.nft.owner(1);
        this.nftListFiltered = await this.nft.getMaticNfts();
        this.loading = false;
        return;
      }
      if (item === 'ETH') {
        this.nftListFiltered = await this.nft.getAllEthNfts();
        this.loading = false;
        return;
      } */
      /*  if (this.typeFilter === 'ALPHABETICAL') {
        this.nftListFiltered = [
          ...(await this.nftList),
          ...this.maticNfts,
        ];
        this.loading = false;
        return;
      } */
      /* if (item === 'PHYSICAL') {
        var filtered = [];
        for (var i = 0; i < this.nftList.length; i++) {
          if (this.nftList[i].physical === true) {
            filtered.push(this.nftList[i]);
          }
        }
        this.nftListFiltered = filtered;
        return;
      }
      if (item === 'FOR SALE') {
        var filtered = [];
        for (var i = 0; i < this.nftList.length; i++) {
          if (this.nftList[i].physical === true) {
            filtered.push(this.nftList[i]);
          }
        }
        this.nftListFiltered = filtered;
        return;
      }
      if (item === 'DIGITAL') {
        var filtered = [];
        for (var i = 0; i < this.nftList.length; i++) {
          if (this.nftList[i].physical === false) {
            filtered.push(this.nftList[i]);
          }
        }
        this.nftListFiltered = filtered;
        return;
      } */
    }, 200);
  }

  getAllCollectionCategories() {
    this.collectionsService.getAllCollectionCategories().then((res: any) => {
      res.data.forEach((element) => {
        this.collectionsCategories.push({
          name: element.name,
          id: element.id,
          selected: false,
        });
      });
      // this.route.queryParams.subscribe((queryParams) => {
      //   this.isCategory = +queryParams.isCategory;
      //   if (queryParams.isCategory) {
      //     this.singleCollectionCategory = this.collectionsCategories.filter(x => x.id === this.isCategory);
      //     this.applyCategoryFilter(this.singleCollectionCategory);
      //   }

      // });
    });
  }

  async getOnChainCollections(): Promise<any> {
    // this.searchReady = false;
    this.VerifiedWalletsService = new VerifiedWalletsService(
      this.offchain,
      this.wallet
    );
    this.allVerifiedWalletAddresses =
      await this.VerifiedWalletsService.getAllVerifiedWalletAddresses();
    const verifiedCollections = [];

    this.allVerifiedWalletAddresses.forEach((data) => {
      this.VerifiedWalletsService.getFullProfile(`${data.address}`).then(
        (res) => {
          verifiedCollections.push(res);
        }
      );
    });

    setTimeout(() => {
      this.onChainCollections = verifiedCollections;
      console.log(verifiedCollections);
      return verifiedCollections;
    }, 100);
  }

  async offchainCollectionFilter(e) {
    console.log(e);
    const inputValue = (
      document.getElementById('offchainCollectionFilter') as HTMLInputElement
    ).value;
    console.log(inputValue);
    if(inputValue === '' && !this.isOffChainFilterSearched){
      return;
    }
    else if(inputValue !== ''){
      this.isOffChainFilterSearched = true;
    }
    else if (inputValue === '' && this.isOffChainFilterSearched) {
      this.getOffChainCollections();
      return;
    }
    if (e.code === 'Enter' || e.type == 'click') {
      const collections = await this.getOffChainCollections();
      const searchResult = collections.filter((collection) => {
        return collection.name.toLowerCase().match(inputValue.toLowerCase());
      });
      setTimeout(() => {
        this.offChainCollections = searchResult;
      }, 100);
    }
  }

  async onchainCollectionFilter(e) {
    console.log(e);
    const inputValue = (
      document.getElementById('onchainCollectionFilter') as HTMLInputElement
    ).value;
    console.log(inputValue);
    if(inputValue === '' && !this.isOnChainFilterSearched){
      return;
    }
    else if(inputValue !== '') {
      this.isOnChainFilterSearched = true;

    }
   else if (inputValue === '' && this.isOnChainFilterSearched) {
      this.getOnChainCollections();
      return;
    }
    console.log(e);
    if (e.code === 'Enter' || e.type == 'click') {
      const onChainCollections =
        await this.VerifiedWalletsService.getAllVerifiedWalletAddresses();
        console.log(onChainCollections);


      const searchResult = onChainCollections.filter((address) => {
        return address.username.toLowerCase().match(inputValue.toLowerCase());
      });
      console.log(onChainCollections);

      setTimeout(() => {
        this.onChainCollections = searchResult;
      }, 100);
    }
  }

  async categorySearchFilter(e) {
    const inputValue = (
      document.getElementById('categorySearchFilter') as HTMLInputElement
    ).value;
    console.log(inputValue);
    if(inputValue === '' && !this.isCategoryFilterSearched){
      return;
    }
    else if(inputValue !== '') {
      this.isCategoryFilterSearched = true;
    }
    else if (inputValue === '' && this.isCategoryFilterSearched) {
      this.collectionsCategories = [];
      this.getAllCollectionCategories();
      return;
    }
    if (e.code === 'Enter' || e.type == 'click') {
      const searchResult = this.collectionsCategories.filter((category) => {
        return category.name.toLowerCase().match(inputValue.toLowerCase());
      });
      setTimeout(async () => {
        this.collectionsCategories = searchResult;
        console.log(this.collectionsCategories);
      }, 100);
    }
  }

  getAll20CollectionItems() {
    this.collectionsService.getAllCollectionItems().subscribe((res) => {
      res.data.forEach((element) => {
        element.is20Item = true;
      });
      this.all20CollectionItems = res.data.filter(
        (item) =>
          (item?.available_for_sale === 1 &&
            moment(item?.available_at).diff(moment(new Date())) <= 0) ||
          item.collection?.user_id === this.currentUser?.id ||
          item?.available_for_sale === 2  ||
          item?.available_for_sale === 0
      );

      this.mergeCollectionItems(this.all20CollectionItems, this.nftList);
    });
  }

  async getOffChainCollections(): Promise<any> {
    this.offChainCollections =
      await this.collectionsService.getAllOffChainCollections();
    return await this.collectionsService.getAllOffChainCollections();
  }

  async checkNetwork(): Promise<void> {
    const network = await this.wallet.getNetwork();
    this.showSwitchToEthereum = network !== Network.ETH;
    this.cdr.detectChanges();
  }

  async loadData(): Promise<void> {
    if (
      ExplorerComponent.cacheUntil > new Date() &&
      ExplorerComponent.nftListCached
    ) {
      this.nftList = ExplorerComponent.nftListCached;
      this.currentOffset = ExplorerComponent.lastOffset;
      this.getAll20CollectionItems();
      return;
    }
    this.currentOffset = 0;
    this.endReached = false;
    this.setCache();
    this.cdr.detectChanges();
    this.getAll20CollectionItems();
  }

  async switchToEthereum(): Promise<void> {
    await this.wallet.switchToEth();
  }

  async mergeCollectionItems(offChainItems, onChainItems) {
    const merge = (first, second) => {
      for (let i = 0; i < second.length; i++) {
        first.push(second[i]);
      }
      return first;
    };
    const itemsFromMoralis =
      await this.moralis.getAllTokensByContractAddressAndNetwork(
        environment.nftAddressMatic,
        'MATIC'
      );
    const items = merge(itemsFromMoralis, offChainItems);
    setTimeout(async () => {
      this.mixedCollectionItems = items.sort(() => Math.random() - 0.5);
      this.allCollectionsItems = this.mixedCollectionItems;
      this.onChainCollectionsItems = this.mixedCollectionItems.filter(item=>item.is20Item === false);
    }, 500);
  }

  private setCache(): void {
    ExplorerComponent.nftListCached = this.nftList;
    ExplorerComponent.lastOffset = this.currentOffset;
    const date = new Date();
    date.setMinutes(date.getMinutes() + 30);
    ExplorerComponent.cacheUntil = date;
  }
}
