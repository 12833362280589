<div class="container page-top">
  <!-- <div class="row c-page-title">
    <div class="col-12 p-t-10 p-b-6">
      <span class="section-title"><span class="icon duels"></span>DigiWax</span>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div> -->
  <div class="row">
    <div class="col">
      <div class="mx-auto mt-4">
        <form class="c-form" #enterDigiWaxForm="ngForm" appIdentityRevealed>
          <div class="row">
            <div class="col-12">
              <div class="mx-auto text-center">
                <h1 class="font-weight-bold">Welcome to DigiWax</h1>
                <div class="border-style-item mx-auto">
                  <span>Entry Opens in... </span>
                  <countdown #cd [config]="config"></countdown>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isGenSubOpen && !userKeys" class="mx-auto text-center mt-4">
            <h1 class="font-weight-bold">General Submission</h1>
            <button
              type="submit"
              [disabled]="enterDigiWaxForm.invalid"
              class="btn btn-primary"
              (click)="subscribeWalletToBoxByName()"
            >
              ENTER
            </button>
          </div>
          <div
            class="row m-auto p-t-40 p-b-40 justify-center"
            *ngIf="userKeys && userKeys.length > 0"
          >
           <!--  <div class="col-12 text-center">
              <!-- <h1 class="font-weight-bold">Enter using your keys below</h1> --
              <span class="border-style-item mx-auto">
                {{ walletAddress }}</span
              >
            </div> -->
            <div
              *ngFor="let nft of userKeys"
              class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
              [@fade]="userKeys"
            >
              <div class="row">
                <div *ngIf="isKeySubOpen" class="col-12">
                  <div class="mx-auto text-center mb-4 mt-4">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      (click)="enterDropWithKey(nft.token_id)"
                    >
                      Enter Using Key
                    </button>
                  </div>
                </div>
                <div class="col-12">
                  <app-moralis-card
                    view="profile"
                    class="c-card-container"
                    [data]="nft"
                  ></app-moralis-card>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
