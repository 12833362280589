import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Comment } from '../types/comments.type';
import { map } from 'rxjs/operators';
import { Conversation, Message, MessagePaginated } from '../types/message.type';
import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class MessageService {

  constructor(private http: HttpClient) { }

  public getChats(): Observable<Conversation[]> {
    return this.http.get<{ data: Conversation[] }>(`${environment.systemLabsAPi}/chat-messages`).pipe(
      map(data => data.data)
    );
  }

  public getMessages(id: string, page = 1): Observable<MessagePaginated> {
    const params = new HttpParams().set('page', `${page}`);
    return this.http.get<MessagePaginated>(`${environment.systemLabsAPi}/get-chat/${id}`, {
      params
    });
  }

  public sendMessage(data: Message): Observable<any> {
    return this.http.post(`${environment.systemLabsAPi}/chat-messages`, data);
  }

  public getUserDetails(id: string): Observable<User> {
    return this.http.get<{ data: User }>(`${environment.systemLabsAPi}/get-chat/${id}/details`).pipe(
      map(data => data.data)
    );
  }
}
