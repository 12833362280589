import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { first } from 'rxjs/operators';
import { AlertService } from '../../services/alert.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/Authentication.service';
import { FavouriteService } from '../../services/favourite.service';

@Component({
  selector: 'app-favourites',
  templateUrl: './my-favourites.component.html',
  styleUrls: ['./my-favourites.component.scss'],
})
export class MyFavouritesComponent implements OnInit {
  id: number;
  currentUser: any;
  public nextPage;
  public currentPage: number = 0;
  public favouriteItem;
  loading = false;
  favoriteDataSize = 0
  favoritePerPage = 10;
  isVerifiedEmail: boolean = false;
  itemName: string = '';

  constructor(
    private readonly route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    private favouriteService: FavouriteService
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
  }

  ngOnInit() {
    this.currentUser = this.currentUser.data;
    this.isVerifiedEmail = this.currentUser.verified;
    this.loadData();
  }

  loadData(itemName:string = '') {
    const next = this.currentPage + 1
    this.favouriteService.getAll('?page='+next, itemName).subscribe(
      response => {
        this.favouriteItem = response.data;
        this.favouriteItem.forEach(element => {
          element.is20Item = true;
        });
        this.nextPage = response.next_page_url;
        this.currentPage = response.current_page;
        this.favoriteDataSize = response.total;
        this.favoritePerPage = response.per_page;
      },
      (err) => {}
    );
  }

  loadMore() {
    const next = this.currentPage + 1;

    this.favouriteService.getAll('?page=' + next).subscribe(
      (response) => {
        this.favouriteItem = this.favouriteItem.concat(response.data);
        this.favouriteItem.forEach((element) => {
          element.is20Item = true;
        });
        this.nextPage = response.next_page_url;
        this.currentPage = response.current_page;
      },
      (err) => {}
    );
  }
}
