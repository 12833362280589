import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { AuthenticationService } from 'src/app/services/Authentication.service';
import { CollectionsService } from 'src/app/services/collections.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-collection-history',
  templateUrl: './collection-history.component.html',
  styleUrls: ['./collection-history.component.scss']
})
export class CollectionHistoryComponent implements OnInit {
  approvedCollections = [];
  rejectedCollections = [];
  collections = [];
  soldOutCollections = []
  public nextPage;
  public loading: boolean = false;
  public approveCollectionCurrentPage: number = 0;
  public rejectCollectionCurrentPage: number = 0;
  approvedCollectionDataSize = 0;
  rejectedCollectionDataSize = 0;
  collectionItemsPerPage = 10;

  soldCollectionDataSize = 0
  soldCollectionCurrentPage = 1;
  soldCollectionItemsPerPage = 10;
  currentUser: any;
  approvedCollectionName: string = "";
  rejectedCollectionName: string = "";
  soldCollectionName: string = "";

  constructor(
    private collectionsService: CollectionsService,
    private readonly adminService: AdminService,
    private authenticationService: AuthenticationService,
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x?.data)
    );
  }

  ngOnInit(): void {
    this.getAdminApprovedCollections(this.approveCollectionCurrentPage);
    this.getAdminRejectedCollections(this.rejectCollectionCurrentPage);
    this.getSoldOutCollections(this.soldCollectionCurrentPage);
  }
  getAdminApprovedCollections(page: number, approvedCollectionName: string = '') {
      this.collectionsService.getAdminApprovedCollections(page, approvedCollectionName).then((res)=> {
      const response = res;
      this.approvedCollections = response.data;
      this.nextPage = response.next_page_url;
      this.approveCollectionCurrentPage = response.current_page;
      this.approvedCollectionDataSize = response.total;
    })
  }

  getAdminRejectedCollections(page: number, rejectedCollectionName: string = '') {
    this.collectionsService.getAdminRejectedCollections(page, rejectedCollectionName).then((res)=> {
    const response = res;
    this.rejectedCollections = response.data;
    this.nextPage = response.next_page_url;
    this.rejectCollectionCurrentPage = response.current_page;
    this.rejectedCollectionDataSize = response.total;
  })
}

getSoldOutCollections(page: number, soldCollectionName: string = '') {
  this.adminService.getSoldOutCollections(page, soldCollectionName).then((res:any) => {
    this.soldOutCollections = res.data;
    this.soldOutCollections = this.soldOutCollections.sort((a, b) => b.id - a.id);
    this.soldCollectionCurrentPage = res.current_page;
    this.soldCollectionDataSize = res.total;
    this.soldCollectionItemsPerPage = res.per_page;
  });
}


public onSoldCollectionPageChange(pageNum: number): void {
  this.getSoldOutCollections(pageNum);
}

  // loadMore() {
  //   const next = this.currentPage + 1
  //   this.collectionsService.getUsercollections('?page='+next).then((res)=> {
  //     const response = res;
  //     this.collections = this.collections.concat(response.data);
  //     this.nextPage = response.next_page_url;
  //     this.currentPage = response.current_page;
  //   })

  // }
}
