<div class="container cart-page" *ngIf="isVerifiedEmail; else notVerified">
  <div class="m-3">
    <h2>Cart</h2>
    <div class="cart-items-container" *ngIf="cartPluckItems.length">
      <div class="cart-item"  *ngFor="let item of cartPluckItems; let ind = index">
        <div class="cart-item-row d-flex">
          <div class="cart-item-row-field">
            <!-- <button
              (click)="handleClickIncreaseBtn(item)"
              class="cart-buttons cart-btn-action"
            >
              +
            </button> -->
            <div class="quantity-count">{{ind + 1}}</div>
            <!-- <div>
              <button
                (click)="handleClickDecreaseBtn(item)"
                class="cart-buttons cart-btn-action"
              >
                -
              </button>
            </div> -->
          </div>
          <div class="cart-item-row-name">
            <h3 class="text-center">{{item?.name}}</h3>
            <h5 class="text-center" *ngIf="item?.available_for_sale === 1">{{(item?.count ? item.count * item?.price : item?.price) | currency}} </h5>
            <h5 class="text-center" *ngIf="item?.available_for_sale === 2">{{(item?.lastWonBet?.data?.last_price) | currency}} </h5>
          </div>
          <div class="cart-item-row-close" (click)="removeItem(item)"><i  class="fa fa-trash"></i></div>
        </div>
      </div>

      <ng-container>
        <div>
            <button class="btn btn-outline-primary" (click)="onOrderSubmit(content)">Order Now</button>
        </div>
    </ng-container>

    </div>

    <div class="empty-card bg-light text-center py-5" *ngIf="!cartPluckItems.length">

      <p class="lead">
          Your Card Is Empty
      </p>
      <a [routerLink]="['/explore']"><button class="btn btn-primary">Check Products</button></a>
    </div>

  </div>
</div>

<ng-template #notVerified>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="mx-auto text-center">
          <h1 class="mt-5">Please verify your email address before continuing, <b>it may be in your spam/junk folder.</b></h1>
        </div>
      </div>
    </div>
  </div>

</ng-template>




<ng-container>
  <div>
      <ng-template #content let-modal>
          <div class="modal-header">
            <h4 class="modal-title text-center" id="payment-modal-basic-title">Order Status</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <ng-container>

                <div class="row justify-center p-b-40">
                  <div class="col-lg">
                    <div class="row justify-center mt-5" *ngIf="isOrderSuccess">
                      <i class="fas fa-check success "></i>
                      <div>
                          <h4 class="verifiedemail text-center">Thank You!</h4>
                          <h4 class="verifiedemail">Your order has been placed!</h4>
                      </div>
              </div>

              <div class="row mt-5" *ngIf="!isOrderSuccess">
                  <div class="col-12 text-center">
                      <i class="fas fa-times-circle warning"></i>
                  </div>

                  <div class="col-12 text-center">
                      <h3 class="verifiedemail text-center">Error!</h3>
                      <h3 class="verifiedemail">Order place failed!</h3>
                  </div>
              </div>
                  </div>
                </div>
                <hr>

                <div class="float-left">
                  <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click'); onClick()">Close</button>
                </div>

                <div class="float-right" *ngIf="isOrderSuccess">
                  <button type="button" class="btn btn-success" (click)="orderList()">View Your Orders</button>
                </div>
              </ng-container>
          </div>
        </ng-template>
        <hr>
  </div>
</ng-container>


<!-- <div  [formGroup]="orderForm">
  <div class="form-group">
    <label for="name">Api key</label>
    <input
      type="text"
      formControlName="api_key"
      class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.api_key.errors }"
    />
    <div *ngIf="submitted && f.api_key.errors" class="invalid-feedback">
      <div *ngIf="f.api_key.errors.required">Api key is required</div>
    </div>
  </div>

  <div class="form-group">
      <label for="label">Api Password</label>
      <input
        type="password"
        formControlName="api_pass"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.api_pass.errors }"
      />
      <div *ngIf="submitted && f.api_pass.errors" class="invalid-feedback">
        <div *ngIf="f.api_pass.errors.required">Api password is required</div>
      </div>
    </div>

  <div class="form-group">
    <button [disabled]="loading" class="btn btn-primary" (click)="onOrderSubmit()">
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
      Confirm Order
    </button>
  </div>
</div> -->
