// ANGULAR/NPM MODULES
import { DatePipe, CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserModule } from '@angular/platform-browser';
import { AccordionModule } from "ngx-accordion";
import { CountdownModule } from 'ngx-countdown';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  DlDateTimeDateModule,
  DlDateTimePickerModule
} from 'angular-bootstrap-datetimepicker';
import { NgxFileDropModule } from 'ngx-file-drop';

// App modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SafePipe } from './safe.pipe';
import { AdminComponent } from './components/admin/admin.component';
import { AuctionsComponent } from './components/auctions/auctions.component';
import { ClaimCardComponent } from './components/claim-card/claim-card.component';
import { CreateAuctionComponent } from './components/create-auction/create-auction.component';
import { CreateCardComponent } from './components/create-card/create-card.component';
import { AlertComponent } from './components/alert/alert.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { CreateSellPriceComponent } from './components/create-sell-price/create-sell-price.component';
import { CreateSellComponent } from './components/create-sell/create-sell.component';
import { DetailsComponent } from './components/details/details.component';
import { DigiWaxComponent } from './components/digiwax/digiwax.component';
import { DuelDetailsComponent } from './components/duel-details/duel-details.component';
import { DuelsComponent } from './components/duels/duels.component';
import { ExplorerComponent } from './components/explorer/explorer.component';
import { MyFavouritesComponent } from './components/my-favourites/my-favourites.component';
// import { becomeASellerComponent } from './components/become-seller/become-seller.component';
import { HomeComponent } from './components/home/home.component';
import { ForSaleComponent } from './components/for-sale/for-sale.component';
import { NewestComponent } from './components/newest/newest.component';
import { PrivateSaleComponent } from './components/private-sale/private-sale.component';
import { ProfileComponent } from './components/profile/profile.component';

// User Account COmponents
import { UserAccountComponent } from './components/user-account/user-account.component';
import { MyCollectionsComponent } from './components/my-collections/my-collections.component';
import { RankingsComponent } from './components/rankings/rankings.component';
import { SearchComponent } from './components/search/search.component';
import { StakingComponent } from './components/staking/staking.component';
import { LayoutComponent } from './layout/layout.component';
import { DigiCardComponent } from './partials/digi-card/digi-card.component';
import { OffChainCardComponent } from './partials/off-chain-card/off-chain-card.component';
import { MoralisCardComponent } from './partials/moralis-card/moralis-card.component';
import { LoadingBlockchainComponent } from './partials/loading-blockchain/loading-blockchain.component';
import { LoadingComponent } from './partials/loading/loading.component';
import { StakeComponent } from './partials/stake/stake.component';

// API Services
import { DuelsService } from './services/duels.service';
import { MarketplaceService } from './services/marketplace.service';
import { MathService } from './services/math.service';
import { MaticService } from './services/matic.service';
import { NftService } from './services/nft.service';
import { OffchainService } from './services/offchain.service';
import { DigiWaxService } from './services/digiwax.service';
import { MoralisService } from './services/moralis.service';
import { StakingService } from './services/staking.service';
import { TokensService } from './services/tokens.service';
import { VerifiedWalletsService } from './services/verified-wallets.service';
import { WalletsService } from './services/wallets.service';
import { WalletService } from './services/wallet.service';
import { FormService } from './services/form.service';
import { AdminService } from './services/admin.service';

import { CreateCollectionComponent } from './components/create-collection/create-collection.component';
import { CreateCollectionItemComponent } from './components/create-collection-item/create-collection-item.component';
import { CollectionComponent } from './components/collection/collection.component';
import { CollectionsComponent } from './components/collections/collections.component';
import { FaqComponent } from './components/about/faq/faq.component';
import { PurchaseComponent } from './components/purchase/purchase.component';

// Helpers
import { HelpersService } from './services/helpers.service';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { TruncatePipe } from './helpers/truncate.pipe';
import { SellerDetailComponent } from './components/seller-detail/seller-detail.component';
import { CollectionDetailComponent } from './components/collection-detail/collection-detail.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { ItemDetailsComponent } from './components/item-details/item-details.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UserOrderDetailComponent } from './components/user-order-detail/user-order-detail.component';
import { CartComponent } from './components/cart/cart.component';
import { CartService } from './services/cart.service';
import { UserCommentListComponent } from './components/user-comment-list/user-comment-list.component';
import { CommentBoxComponent } from './components/comment-box/comment-box.component';
import { CommentReplyBoxComponent } from './components/comment-reply-box/comment-reply-box.component';
import { DateFromNow } from './helpers/date-from-now.pipe';
import { MessagingComponent } from './components/messaging/messaging.component';
import { EducationalComponent } from './components/educational/educational.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DatetimePickerComponent } from './partials/datetime-picker/datetime-picker.component';
import { ItemAuctionComponent } from './partials/item-auction/item-auction.component';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { DateCountDown } from './partials/date-countdown/date-countdown.component';
import { EmailVerifyMessageComponent } from './components/email-verify-message/email-verify-message.component';
import { MyAuctionsComponent } from './components/my-auctions/my-auctions.component';
import { CollectionHistoryComponent } from './components/admin/collection-history/collection-history.component';
import { SellerItemDetailComponent } from './seller-item-detail/seller-item-detail.component';
import { StakingV2Component } from './components/staking-v2/staking-v2.component';
import { StakeV2Component } from './partials/stake-v2/stake-v2.component';
import { TradeComponent } from './components/trade/trade.component';

@NgModule({
  declarations: [
    SafePipe,
    AppComponent,
    RegisterComponent,
    UserAccountComponent,
    MyCollectionsComponent,
    CreateCollectionComponent,
    CreateCollectionItemComponent,
    CollectionComponent,
    AlertComponent,
    LoginComponent,
    ExplorerComponent,
    DigiCardComponent,
    OffChainCardComponent,
    ForSaleComponent,
    NewestComponent,
    DigiCardComponent,
    MoralisCardComponent,
    HomeComponent,
    LayoutComponent,
    LoadingComponent,
    LoadingBlockchainComponent,
    AuctionsComponent,
    MyFavouritesComponent,
    RankingsComponent,
    DigiWaxComponent,
    ProfileComponent,
    PurchaseComponent,
    DetailsComponent,
    CreateSellComponent,
    CreateSellPriceComponent,
    CreateCardComponent,
    CreateAuctionComponent,
    DuelsComponent,
    DuelDetailsComponent,
    PrivateSaleComponent,
    ClaimCardComponent,
    AdminComponent,
    SearchComponent,
    StakeComponent,
    StakingComponent,
    CollectionsComponent,
    FaqComponent,
    TruncatePipe,
    DateFromNow,
    SellerDetailComponent,
    CollectionDetailComponent,
    UsersListComponent,
    ItemDetailsComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    UserOrderDetailComponent,
    CartComponent,
    UserCommentListComponent,
    CommentBoxComponent,
    CommentReplyBoxComponent,
    MessagingComponent,
    EducationalComponent,
    DatetimePickerComponent,
    ItemAuctionComponent,
    DateCountDown,
    EmailVerifyMessageComponent,
    MyAuctionsComponent,
    CollectionHistoryComponent,
    SellerItemDetailComponent,
    StakingV2Component,
    StakeV2Component,
    TradeComponent
  ],
  imports: [
    BrowserModule,
    CountdownModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxFileDropModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    NgSelectModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    NgxImageZoomModule,
    AccordionModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    InfiniteScrollModule,
    MomentTimezonePickerModule
  ],
  providers: [
    DatePipe,
    WalletService,
    OffchainService,
    NftService,
    MoralisService,
    MathService,
    DigiWaxService,
    MarketplaceService,
    MaticService,
    TokensService,
    DuelsService,
    StakingService,
    CartService,
    AdminService,
    VerifiedWalletsService,
    WalletsService,
    HelpersService,
    FormService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
