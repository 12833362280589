import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AdminService } from '../../services/admin.service'
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-seller-detail',
  templateUrl: './seller-detail.component.html',
  styleUrls: ['./seller-detail.component.scss']
})
export class SellerDetailComponent implements OnInit {

  id;
  public seller;
  returnUrl: string = 'admin';

  constructor(
    private readonly route: ActivatedRoute,
    private userService: UserService,
    private adminService: AdminService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(queryParams => {
      this.id = queryParams.id;
      this.loadData();
    });
  }

  loadData() {

    this.adminService.getSelelrDetail(this.id).subscribe(
      response => {
         this.seller = response.data;
      },
      err => {

      }
    )

  }

  changeSellerStatus(seller:number, status) {
    // TODO: unwrap promise and show success
    this.adminService.approveSeller(seller, status);
    this.router.navigate([this.returnUrl]);
    this.alertService.success(status+' successfully', true);
  }

}
