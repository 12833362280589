<div class="container">
  <div class="row" *ngIf="loading">
    <div class="col-lg-12">
      <div class="mx-auto text-center">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!loading">
    <div class="input-group col-12">
      <div class="form-outline col-5">
        <input id="search-focus" placeholder="Search by user name" [(ngModel)]="userName" type="search" class="form-control" />
      </div>
      <button type="button" class="btn btn-primary col-1" (click)="loadData(currentPage, userName)">
        <i class="fas fa-search"></i>
      </button>
    </div>
    <div class="col-lg-9 mb-4 mt-4">
      <h1 class="bold text-blue">User's List</h1>
    </div>
    <div class="col-lg-12">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
            <th scope="col">Timezone</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of usersData; let ind = index ">
            <th scope="row">{{ userNumber + ind + 1 }}</th>
            <td>{{ data?.name }}</td>
            <td>{{ data?.email }}</td>
            <td>{{ data?.status }}</td>
            <td>{{ data?.timezone }}</td>
            <td>
              <span><button
              class="btn btn-primary ml-auto m-2"
              (click)="openUserOrderDetail(data?.id, data?.roles[0].name)"
            >
              Orders Detail
            </button></span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="d-flex justify-content-between p-2" *ngIf="collectionSize >= 10">
    <ngb-pagination class="mx-auto" [collectionSize]="collectionSize" #numPages [pageSize]="itemsPerPage"
      [(page)]="currentPage" (pageChange)="onPageChange(currentPage)"></ngb-pagination>


    <!-- <select class="custom-select w-auto" [(ngModel)]="itemsPerPage" (change)="changePagesize()">
      <option [ngValue]="5">5 items per page</option>
      <option [ngValue]="10">10 items per page</option>
      <option [ngValue]="20">20 items per page</option>
    </select> -->
  </div>
</div>
