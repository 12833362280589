import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CollectionsService {
  constructor(private http: HttpClient) {}

  getAllItemsInCollection(id:number, param = ''): any {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.systemLabsAPi}/collections/${id}/collection-items/`+param)
        .toPromise()
        .then((res: any) => {
          console.log(res);
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getCollectionById(id:number): any {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.systemLabsAPi}/collections/${id}`)
        .toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
  getLatestCollectionItem(): Observable<any> {
    return this.http.get(`${environment.systemLabsAPi}/collection-items/?filter[day]=2`)
   }
   getLatestSellerCollectionItems(id:number): Observable<any> {
    return this.http.get(`${environment.systemLabsAPi}/collections/${id}/collection-items/?filter[day]=2`)
   }


  getApprovedCollections(page = 1, approvedCollectionName?: string): any {
    const params = new HttpParams()
    .set('page', `${page}`).append('search', `${approvedCollectionName}`)
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.systemLabsAPi}/sellers/collections/approved`, {params})
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
  getRejectedCollections(page = 1, rejectedCollectionName: string): any {
    const params = new HttpParams()
    .set('page', `${page}`).append('search', `${rejectedCollectionName}`)
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.systemLabsAPi}/sellers/collections/rejected`, {params})
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getPendingCollections(page = 1, pendingCollectionName: string): any {
    const params = new HttpParams()
    .set('page', `${page}`).append('search', `${pendingCollectionName}`)
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.systemLabsAPi}/sellers/collections/pending`, {params})
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
  getSoldCollections(page = 1, getSoldCollections: string): any {
    const params = new HttpParams()
    .set('page', `${page}`).append('search', `${getSoldCollections}`)
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.systemLabsAPi}/sellers/collections/sold`, {params})
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getAdminApprovedCollections(page = 1, approvedCollectionName: string): any {
    const params = new HttpParams()
    .set('page', `${page}`).append('search', approvedCollectionName)
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.systemLabsAPi}/admin/collections/approved`, {params})
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getAdminRejectedCollections(page = 1, rejectedCollectionName: string): any {
    const params = new HttpParams()
    .set('page', `${page}`).append('search', rejectedCollectionName)
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.systemLabsAPi}/admin/collections/rejected`, {params})
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  getAllCollections() {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.systemLabsAPi}/collections`)
        .toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getAllOffChainCollections() {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.systemLabsAPi}/collection`)
        .toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getAllCollectionCategories(): any {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.systemLabsAPi}/collection-item-types`)
        .toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  createCollection(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.systemLabsAPi}/collections`, data)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }



  createCollectionItem (data:any, id) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.systemLabsAPi}/collections/${id}/collection-items`, data)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

updateCollectionItem (data:any, collectionId, ItemId) {
    return new Promise((resolve, reject) => {
      this.http.put(`${environment.systemLabsAPi}/collections/${collectionId}/collection-items/${ItemId}`, data)
        .toPromise()
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  }

  getAllPublicCollections(page: number): Observable<any> {
   return this.http.get(`${environment.systemLabsAPi}/collection?${page}`)
  }

  getAllPublicCollectionItems(collectionId: string, page: number): Observable<any> {
    return this.http.get(`${environment.systemLabsAPi}/collections/${collectionId}/collection-item?${page}`)
   }

   getCollectionItemById(collectionId: string, itemId: string): Observable<any> {
    return this.http.get(`${environment.systemLabsAPi}/collections/${collectionId}/collection-items/${itemId}`)
   }

   getPublicCollectionItemById(itemId: string): Observable<any> {
   return this.http.get(`${environment.systemLabsAPi}/collection-items/${itemId}`)
  }

   getAllCollectionItems(): Observable<any> {
    return this.http.get(`${environment.systemLabsAPi}/collection-items`)
   }

   isCollectionItemFavorite( itemId: string): Observable<any> {
    return this.http.get(`${environment.systemLabsAPi}/is_favorite/${itemId}`)
   }

   setCollectionItemFavorite(collectionId: string, itemId: string, favourite: string): Observable<any> {
    return this.http.post(`${environment.systemLabsAPi}/collections/${collectionId}/${favourite}/${itemId}`,[]);
   }
}
