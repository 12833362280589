import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/Authentication.service';
import { CollectionsService } from '../../services/collections.service';
import { AvailableForSale } from 'src/app/types/collection-item.type';
import moment from 'moment';
import { Collection } from 'src/app/types/collection.type';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-collections',
  templateUrl: './create-collection-item.component.html',
  styleUrls: ['./create-collection-item.component.scss'],
})
export class CreateCollectionItemComponent implements OnInit {
  AvailableForSale = AvailableForSale;
  id: number;
  currentUser: any;
  collectionId = '';
  itemId = '';

  itemData: any;
  isAvailAtGreater: boolean = false;
  isStartDateGreater: boolean = false;
  isEndDateGreater: boolean = false;
  isEdit: boolean = false;
  categories: any = [];
  returnUrl: string;
  collection: Collection;
  collectionItemForm: FormGroup;
  loading = false;
  currentDate: string;
  submitted = false;
  resCollectionId;
  collectionName = '';
  loadedCategories = false;
  userRole: string;
  userId: number;
  currentUserId: number;
  filterBy = [
    { name: 'All', id: 'ALL', test: 'one' },
    { name: 'Show Physical', id: 'PHYSICAL', test: 'one' },
    { name: 'Show DIGITAL', id: 'DIGITAL', test: 'one' },
  ];

  range = [];

  public get isPriceZero(): boolean {
    return this.collectionItemForm.value.price <= 0;
  }

  constructor(
    private formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private collectionsService: CollectionsService,
    private userService: UserService,
    private collectionService: CollectionsService,
    private alertService: AlertService
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x.data)
    );
    this.currentUserId = this.currentUser.id;
    this.itemId = this.route.snapshot.params.id;
    this.id = this.route.snapshot.params.id;
    this.route.queryParams.subscribe((queryParams) => {
      if(!queryParams.isEdit){
        this.userService.verifyUser(parseInt(this.route.snapshot.params.userId));
      }
      else {
        this.isEdit = true;
        this.collectionId = queryParams.collection;
        this.userService.verifyUser(parseInt(queryParams.userId));
      }

      });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
        this.collectionName =  params.collectionName;
    });
    this.loading = true;
    this.collectionsService.getAllCollectionCategories().then((res) => {
      this.categories = res.data;
      this.loading = false;
    });
    if(this.isEdit === true){
      this.collectionService.getCollectionItemById(this.collectionId, this.itemId).subscribe(itemData => {
        this.itemData = itemData.data;
        this.setValue();
      });
    }

    this.collectionItemForm = this.formBuilder.group({
      name: ['', Validators.required],
      image: ['', Validators.required],
      collection_item_type_id: ['', Validators.required],
      year: ['', Validators.required],
      price: ['', Validators.required],
      description: ['', Validators.required],
      edition: ['', Validators.required],
      graded: ['', Validators.required],
      population: ['', Validators.required],
      publisher: ['', Validators.required],
      available_for_sale: ['', Validators.required],
    });

    this.collectionItemForm.get('available_for_sale').valueChanges.subscribe((value: AvailableForSale) => {
      if (value === AvailableForSale.OnAuction) {
        this.collectionItemForm.addControl('start_date', this.formBuilder.control(null, [Validators.required]));
        this.collectionItemForm.addControl('end_date', this.formBuilder.control(null, [Validators.required]));
        return;
      }

      if (value === AvailableForSale.OnSale) {
        this.collectionItemForm.addControl('available_at', this.formBuilder.control(null, [Validators.required]));
        return;
      }

      this.collectionItemForm.removeControl('available_at');
      this.collectionItemForm.removeControl('start_date');
      this.collectionItemForm.removeControl('end_date');
    })


    var year = new Date().getFullYear();
    for (var i = year; i >= 1970; i--) {
      this.range.push(i);
    }

    this.currentUser = this.currentUser.data;
  }
  setValue(){
    this.collectionItemForm.patchValue({
      name: this.itemData?.name,
      collection_item_type_id: this.itemData?.collection_item_type_id,
      description: this.itemData?.description,
      edition: this.itemData?.edition,
      graded: this.itemData?.graded,
      year: this.itemData?.year,
      population: this.itemData?.population,
      publisher: this.itemData?.publisher,
      price: this.itemData?.price,
      available_for_sale: this.itemData?.available_for_sale,
      available_at: this.itemData?.available_at,
      start_date: this.itemData?.start_date,
      end_date: this.itemData?.end_date,
    })
  }

  onKeyPress(event: any) {
    return (event.charCode === 8 || event.charCode === 0 || event.charCode === 13) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  get f() {
    return this.collectionItemForm.controls;
  }

  loadData() {
    this.userService.getUserById(this.id);
  }

  oncollectionItemSubmit() {
    this.submitted = true;
    this.alertService.clear();

    if(this.collectionItemForm.value.price <= 0){
      return;
    }
    let data = this.collectionItemForm.value;
    this.currentDate = new Date().toString();
    this.currentDate = moment(this.currentDate).format('Y-MM-DD HH:mm:ss');
    if (data.available_for_sale === AvailableForSale.OnAuction) {
      data.start_date = moment(data.start_date).format('Y-MM-DD HH:mm:ss');
      data.end_date = moment(data.end_date).format('Y-MM-DD HH:mm:ss');
      if(data.start_date <= this.currentDate){
        this.isStartDateGreater = true;
        return;
      }
      if(data.end_date <= this.currentDate){
        this.isEndDateGreater = true;
        return;
      }
    } else if (data.available_for_sale === AvailableForSale.OnSale) {
      data.available_at = moment(data.available_at).format('Y-MM-DD HH:mm:ss');
      if(data.available_at <= this.currentDate){
        this.isAvailAtGreater = true;
        return;
      }
    }

    if (this.collectionItemForm.invalid) {
      console.log(this.collectionItemForm.invalid);
      return;
    }
    this.loading = true;
    this.collectionsService
      .createCollectionItem(data, this.id)
      .then((res) => {
        this.router.navigate(['/collection/', this.id, this.currentUserId]);
        this.alertService.success('Collection item has been created successfully', true);
      })
      .catch((error) => {
        this.alertService.error(error.errors.year[0]);
        this.loading = false;
      });
  }
  oncollectionItemUpdate() {
    this.submitted = true;
    this.alertService.clear();

    if(this.collectionItemForm.value.image === "" || this.collectionItemForm.value.image === undefined){
      this.collectionItemForm.removeControl('image');

    }

    if(this.collectionItemForm.value.price && this.collectionItemForm.value.price <= 0){
      this.alertService.error('Price should be greater than 0');
      return;
    }
    let data = this.collectionItemForm.value;

    this.currentDate = moment(this.currentDate).format('Y-MM-DD HH:mm:ss');
    if (data.available_for_sale === AvailableForSale.OnAuction) {

      if(data.start_date === this.itemData?.start_date){
        delete data.start_date;
      }else{
        data.start_date = moment(data.start_date).format('Y-MM-DD HH:mm:ss');
        delete data.available_at;
        if(data.start_date <= this.currentDate){
          this.isStartDateGreater = true;
          return;
        }
      }
      if(data.end_date === this.itemData?.end_date){
        delete data.end_date;
      }else{
        data.end_date = moment(data.end_date).format('Y-MM-DD HH:mm:ss');
        delete data.available_at;
        if(data.end_date <= this.currentDate){
          this.isEndDateGreater = true;
          return;
        }
      }
    } else if (data.available_for_sale === AvailableForSale.OnSale) {
      if(data.available_at === this.itemData?.available_at){
        delete data.available_at;
      }else{
        data.available_at = moment(data.available_at).format('Y-MM-DD HH:mm:ss');
        delete data.start_date;
        delete data.end_date;
        if(data.available_at <= this.currentDate){
          this.isAvailAtGreater = true;
          return;
        }
      }
    }
    this.collectionItemForm.clearValidators();
    this.collectionItemForm.updateValueAndValidity();

    this.loading = true;
    this.collectionsService.updateCollectionItem(data, this.collectionId, this.itemId)
      .then((res: any) => {
        this.resCollectionId = res.data.collection_id;
        this.router.navigate(['/profile', this.resCollectionId], {
          relativeTo: this.route,
          queryParams: {
          is20Collection: true
          },
          queryParamsHandling: 'merge',
        });
        this.alertService.success('Collection item update successfully', true);
      })
      .catch((error) => {
        this.alertService.error(error?.errors?.year[0]);
        this.loading = false;
      });

  }

  handleFileInput(files: FileList, name) {
    let me = this;
    let file = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      this.collectionItemForm.value.image = e.target.result;
      this.collectionItemForm.get(name).setValue(e.target.result);
    };
    reader.onerror = function (error) {
      // console.error('Error: ', error);
    };
  }
}
