<div class="t-comments-box">
  <div class="add-comment">
    <div class="message-icon">
      <i class="fas fa-envelope"></i>
    </div>
    <div class="comment-input" [formGroup]="commentForm">
      <input class="form-control form-control-sm" type="text" formControlName="comment" placeholder="Add a comment.." />
      <div class="comment-send" (click)="addComment()"><i class="fab fa-telegram-plane"></i></div>
    </div>
  </div>
  <ng-container *ngFor="let comment of comments">
    <div class="comment comment-level-1">
      <div class="message-icon">
        <i class="fas fa-envelope"></i>
      </div>
      <div>
        <div class="comment-label">
          <span class="comment-name">{{comment.user.data.name}}</span>
          <span class="comment-time"> - {{comment.created_at | dateFromNow}}</span>
        </div>
        <div class="comment-content">
          {{comment.comment}}
        </div>
      </div>
    </div>
    <app-comment-reply-box [commentId]="comment.id" [id]="id" [type]="type" [replyCount]="comment.count">
    </app-comment-reply-box>
  </ng-container>
</div>