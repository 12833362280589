<div class="education page">
    <section class="p-5">
        <div class="container">
          <div class="row text-center">
            <div class="col-md mb-5">
              <div class="card bg-dark text-light">
                <div class="card-body text-center">
                  <h5 class="card-title">What is a Blockchain/network?</h5>
                  <h6 class="card-subtitle mb-2 text-muted"></h6>
                  <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <button class="btn btn-primary">Read More</button>
                </div>
              </div>
            </div>
            <div class="col-md mb-5">
              <div class="card bg-secondary text-light">
                <div class="card-body text-center">
                  <h5 class="card-title">What is an NFT?</h5>
                  <h6 class="card-subtitle mb-2 text-muted"></h6>
                  <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <button class="btn btn-primary">Read More</button>
                </div>
              </div>
          </div>
          <div class="col-md mb-5">
            <div class="card bg-dark text-light">
              <div class="card-body text-center">
                <h5 class="card-title">How to set up a MetaMask wallet?</h5>
                <h6 class="card-subtitle mb-2 text-muted"></h6>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <button class="btn btn-primary">Read More</button>
              </div>
            </div>
        </div>
          </div>
        </div>
      </section>
  
      <section class="p-5">
        <div class="container">
            <div class="row justify-content-between align-items-center">
              <div class="col-md">
                <img class="img-fluid" src="assets/images/hero.jpeg" />
              </div>
              <div class="col-md p-5">
                  <h1>Ready to read this</h1>
                  <p class="lead">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                  <p class="lead">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                  <button class="btn btn-light">Read More</button>
              </div>
  
            </div>
        </div>
      </section>
  
      <section class="p-5 bg-dark text-light">
        <div class="container">
            <div class="row justify-content-between align-items-center">
              <div class="col-md p-5">
                  <h1>Ready to read this</h1>
                  <p class="lead">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                  <p class="lead">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                  <button class="btn btn-light">Read More</button>
              </div>
  
              <div class="col-md">
                <img class="img-fluid" src="assets/images/matic.png" />
              </div>
            </div>
        </div>
      </section>
  
  
      <section class="p-5">
        <div class="container">
          <h1>Frequently Asked Questions</h1>
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
            <ngb-panel title="What is a Blockchain/network?">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                labore sustainable VHS.
              </ng-template>
            </ngb-panel>
            <ngb-panel title="What is an NFT?">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                labore sustainable VHS.
              </ng-template>
            </ngb-panel>
            <ngb-panel title="How to set up a MetaMask wallet?">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                labore sustainable VHS.
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
          
        </div>
  
      </section>
  
  
      <section class="p-5 bg-primary">
        <div class="container text-light text-center">
          <h2>Instructor</h2>
          <p class="lead">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
  
          <div class="row g-5">
              <div class="col-md-6 col-lg-3">
                <div class="card bg-light text-dark">
                  <div class="card-body text-center">
                    <img src="assets/images/img_avatar.png" class="w-50 rounded-circle mb-3"/>
                    <h5 class="card-title">John Doe</h5>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <button class="btn btn-primary">Read More</button>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="card bg-light text-dark">
                  <div class="card-body text-center">
                    <img src="assets/images/img_avatar2.png" class="w-50 rounded-circle mb-3"/>
                    <h5 class="card-title">John Doe</h5>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <button class="btn btn-primary">Read More</button>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="card bg-light text-dark">
                  <div class="card-body text-center">
                    <img src="assets/images/img_avatar.png" class="w-50 rounded-circle mb-3"/>
                    <h5 class="card-title">John Doe</h5>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <button class="btn btn-primary">Read More</button>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="card bg-light text-dark">
                  <div class="card-body text-center">
                    <img src="assets/images/img_avatar2.png" class="w-50 rounded-circle mb-3"/>
                    <h5 class="card-title">John Doe</h5>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <button class="btn btn-primary">Read More</button>
                  </div>
                </div>
              </div>
          </div>
        </div>
  
  
      </section>
  

</div>