import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { WalletService } from 'src/app/services/wallet.service';
import { MoralisService } from 'src/app/services/moralis.service';
import { DigiWaxService } from 'src/app/services/digiwax.service';
import { environment } from 'src/environments/environment';
import { CountdownConfig, CountdownFormatFn } from 'ngx-countdown';

@Component({
  selector: 'app-digiwax',
  templateUrl: './digiwax.component.html',
  styleUrls: ['./digiwax.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => active', [
        style({ opacity: 0 }),
        animate(10000, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class DigiWaxComponent {
  @Input() config: any = {};
  digiWaxAddress = environment.digiWaxAddress;
  enterDigiWaxForm: FormGroup;
  boxCreatorAddress;
  generalSubmission = false;
  walletAddress;
  isKeySubOpen;
  isGenSubOpen;
  requestId;
  userKeys;
  boxOwner;
  boxName = 'system1';
  // changeDetection: ChangeDetectionStrategy.OnPush
  CountdownTimeUnits: Array<[string, number]> = [
    ['Y', 1000 * 60 * 60 * 24 * 365], // years
    ['M', 1000 * 60 * 60 * 24 * 30], // months
    ['D', 1000 * 60 * 60 * 24], // days
    ['H', 1000 * 60 * 60], // hours
    ['m', 1000 * 60], // minutes
    ['s', 1000], // seconds
    ['S', 1], // million seconds
  ];
  formatDate?: CountdownFormatFn = ({ date, formatStr, timezone }) => {
    let duration = Number(date || 0);

    return this.CountdownTimeUnits.reduce((current, [name, unit]) => {
      if (current.indexOf(name) !== -1) {
        const v = Math.floor(duration / unit);
        duration -= v * unit;
        return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
          return v.toString().padStart(match.length, '0');
        });
      }
      return current;
    }, formatStr);
  };

  constructor(
    private fb: FormBuilder,
    private readonly digiWax: DigiWaxService,
    private readonly moralis: MoralisService,
    private readonly wallet: WalletService
  ) {}

  ngOnInit(): void {
    /* this.config = {
      stopTime: new Date(this.data.auctionOrSaleData.endDate * 1000).getTime(),
      format: 'DDd HHh mm:ss',
      formatDate: this.formatDate,
    }; */
    this.setAccount().then((res) => {
      this.getBoxOwner();
      this.loadData();
      this.getRequestId();
      this.digikeySubscriptionOpenByBoxName();
      this.generalSubscriptionOpenByBoxName();
      // this.WalletSubscribed();
      // this.accessPriceContractAddressByBoxName();
      // this.boxSealedByRequestId();
      // this.breakWaxBox();
      this.oracleSpokeByBoxName();
      // this.updateSubscriptionsByBox();
      this.accessFeePercentage();
      this.accessPriceByBoxName();
      this.checkIfWalletHasKey();
    });
    this.enterDigiWaxForm = this.fb.group({
      attributes: this.fb.array([this.addNFT()], Validators.required),
    });
  }

  async getCardDetails(NFTs) {
    let array: any = [];
    NFTs.forEach(async (nft) => {
      array.push(
        await this.moralis.getNftDataFromMoralis(
          nft.token_address,
          nft.token_id,
          'MATIC'
        )
      );
    });
    this.userKeys = array;
  }

  async checkIfWalletHasKey() {
    try {
      const NFTs: any = await this.moralis.getNFTsForContract(
        'MATIC',
        this.walletAddress,
        environment.nftKeysAddressMatic
      );
      console.log(NFTs);

      this.userKeys = NFTs.result;
      console.log('checkIfWalletHasKey:', this.userKeys);
      this.getCardDetails(NFTs.result);
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('checkIfWalletHasKey:', e);
    }
  }

  async enterDropWithKey(keyTokenId: number) {
    this.subscribeWalletToBoxByRequestIdUsingKey(keyTokenId);
  }

  async setAccount() {
    this.walletAddress = await this.wallet.getAccount();
  }

  async getRequestId() {
    try {
      this.requestId = await this.digiWax.getRequestIdByBoxName(this.boxName);
      console.log('getRequestId:', this.requestId);
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('getRequestId:', e);
    }
  }

  async accessPriceByBoxName() {
    try {
      const price = await this.digiWax.accessPriceByBoxName(this.boxName);
      console.log('PRICE BY BOX NAME:', price);
      return price;
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('PRICE BY BOX NAME:', e);
    }
  }

  async breakWaxBox() {
    try {
      const breakWaxBox = await this.digiWax.breakWaxBox(this.boxName);
      console.log('breakWaxBox:', breakWaxBox);
      return breakWaxBox;
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('breakWaxBox:', e);
    }
  }

  async subscribeWalletToBoxByName() {
    try {
      const result = await this.digiWax.subscribeWalletToBoxByName(
        this.boxName,
        this.walletAddress
      );
      console.log('subscribeWalletToBoxByName:', result);
      return result;
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('subscribeWalletToBoxByName:', e);
    }
  }
  async subscribeWalletToBoxByRequestIdUsingKey(keyTokenId: number) {
    console.log(keyTokenId);
    
    console.log(this.boxName, this.walletAddress, keyTokenId);

    try {
      const result = await this.digiWax.subscribeWalletToBoxByRequestIdUsingKey(
        this.boxName,
        this.walletAddress,
        keyTokenId
      );
      console.log('subscribeWalletToBoxByName:', result);
      return result;
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('subscribeWalletToBoxByName:', e);
    }
  }

  async getBoxOwner() {
    try {
      this.boxOwner = await this.digiWax.getBoxOwner(this.boxName);
      console.log('GET BOX OWNER:', this.boxOwner);
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('GET BOX OWNER:', e);
    }
  }

  async accessPriceContractAddressByBoxName() {
    try {
      const contractAddress =
        await this.digiWax.accessPriceContractAddressByBoxName(this.boxName);
      console.log('accessPriceContractAddressByBoxName:', contractAddress);
      return contractAddress;
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('accessPriceContractAddressByBoxName:', e);
    }
  }

  async WalletSubscribed() {
    try {
      const participantWallets = await this.digiWax.WalletSubscribed(
        this.boxName,
        this.walletAddress
      );
      console.log('WalletSubscribed:', participantWallets);
      return participantWallets;
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('WalletSubscribed:', e);
    }
  }

  async boxSealedByRequestId() {
    try {
      const boxSealed = await this.digiWax.boxSealedByRequestId(this.requestId);
      console.log('boxSealedByRequestId:', boxSealed);
      return boxSealed;
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('boxSealedByRequestId:', e);
    }
  }

  async updateSubscriptionsByBox() {
    try {
      const boxSealed = await this.digiWax.updateSubscriptionsByBox(
        this.boxName,
        this.isKeySubOpen,
        this.isGenSubOpen
      );
      console.log('updateSubscriptionsByBox:', boxSealed);
      return boxSealed;
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('updateSubscriptionsByBox:', e);
    }
  }

  // Check if general subscription is open
  async generalSubscriptionOpenByBoxName() {
    try {
      const isGenSubOpen =
        await this.digiWax.generalSubscriptionOpen_by_boxName_map(this.boxName);
      console.log('generalSubscriptionOpenByBoxName:', isGenSubOpen);
      this.isGenSubOpen = isGenSubOpen;
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('generalSubscriptionOpenByBoxName:', e);
    }
  }

  async digikeySubscriptionOpenByBoxName() {
    try {
      const isKeySubOPen = await this.digiWax.digikeySubscriptionOpenByBoxName(
        this.boxName
      );
      console.log('digikeySubscriptionOpenByBoxName:', isKeySubOPen);
      this.isKeySubOpen = isKeySubOPen;
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('digikeySubscriptionOpenByBoxName:', e);
    }
  }

  async oracleSpokeByBoxName() {
    try {
      const oracleSpoke = await this.digiWax.oracleSpokeByBoxName(this.boxName);
      console.log('oracleSpokeByBoxName:', oracleSpoke);
      return oracleSpoke;
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('oracleSpokeByBoxName:', e);
    }
  }

  async accessFeePercentage() {
    try {
      const feePercentage = await this.digiWax.accessFeePercentage();
      console.log('accessFeePercentage:', feePercentage + '%');
      return feePercentage;
    } catch (e) {
      // TRIGGER POP UP TO SHOW ERROR FROM HERE
      console.log('accessFeePercentage:', e);
    }
  }

  addNFT() {
    // something
  }

  submitEntry() {
    // HERE
  }

  async loadData(): Promise<void> {}
}
