<div class="app-filter">
  <div
    class="app-filter-inner filter-scroll"
    [ngClass]="{
      'alert-msg-show': showAlertMessage,
      'filter-sidebar-close': isFilterSidebarClose
    }"
  >
    <div class="d-flex c-filter__item">
      <div
        style="display: flex; justify-content: center;"
        [ngClass]="{ 'd-none': isFilterSidebarClose }"
      >
        <div class="icon filter-icon">
          <i class="fas fa-filter"></i>
        </div>
      </div>
      <h3
        [ngClass]="{ 'd-none': isFilterSidebarClose }"
        class="c-filter__title section-title bold mb-0 mt-2 flex-1-class"
      >
        Filter Collectibles
      </h3>
      <div
        [ngClass]="{ 'arrow-closed': isFilterSidebarClose }"
        class="filter-arrow"
        *ngIf="isFilterSidebarClose"
        (click)="onClickFilterSidebarArrow()"
      >
        <i class="fas fa-arrow-right"></i>
      </div>
      <div
        class="filter-arrow"
        *ngIf="!isFilterSidebarClose"
        (click)="onClickFilterSidebarArrow()"
      >
        <i class="fas fa-arrow-left"></i>
      </div>
      <!-- <div [ngClass]="{'d-none': isFilterSidebarClose}" (click)="clearAllFiltersClick()" class="clear-all-filters mt-4">
        Clear Filters
      </div> -->
    </div>
    <ul [ngClass]="{ 'd-none': isFilterSidebarClose }" class="c-filter__list">
      <li class="c-filter__item">
        <div
          class="c-filter__title"
          (click)="toggleMenu($event, filterenum.Status)"
        >
          <span #status class="title-span">Status</span>
          <span (click)="arrowToggleMenu(statusArrow)" class="chevron">
            <div *ngIf="!isOpen(filterenum.Status)">
              <i class="fas fa-chevron-down"></i>
            </div>
            <div *ngIf="isOpen(filterenum.Status)">
              <i class="fas fa-chevron-up"></i>
            </div>
          </span>
        </div>
        <div class="c-filters__list" #statusArrow>
          <div
            #allData
            id="allData"
            (click)="clickAllDataFilter(allData)"
            class="btn btn-primary mb-2 w-100"
          >
            All Data
          </div>
          <div
            #onChain
            id="onChain"
            (click)="clickOnChainStatusFilter(onChain)"
            class="btn btn-primary mb-2 w-100"
          >
            On-chain
          </div>
          <div
            #offChain
            id="offChain"
            (click)="clickOffChainStatusFilter(offChain)"
            class="btn btn-primary mb-2 w-100"
          >
            Off-chain
          </div>
          <!-- <div
            (click)="clickApprovedStatusFilter()"
            class="btn btn-primary mb-2 w-100"
          >
            Approved
          </div> -->
          <div
            #auction
            id="auction"
            (click)="clickAuctionStatusFilter(auction)"
            class="btn btn-primary mb-2 w-100"
          >
            Auction
          </div>
          <div
            #buyNow
            id="buyNow"
            (click)="clickBuyNowStatusFilter(buyNow)"
            class="btn btn-primary mb-2 w-100"
          >
            Buy Now
          </div>
          <div
            #latest
            id="latest"
            (click)="applyLatestFilter(latest)"
            class="btn btn-primary w-100"
          >
            Latest
          </div>
        </div>
      </li>
      <li class="c-filter__item">
        <div
          class="c-filter__title"
          (click)="toggleMenu($event, filterenum.Price)"
        >
          <span class="title-span">Price</span>
          <span (click)="arrowToggleMenu(priceArrow)" class="chevron">
            <div *ngIf="!isOpen(filterenum.Price)">
              <i class="fas fa-chevron-down"></i>
            </div>
            <div *ngIf="isOpen(filterenum.Price)">
              <i class="fas fa-chevron-up"></i>
            </div>
          </span>
        </div>
        <div class="c-filters__list price" #priceArrow>
          <p class="ml-auto mr-2">Currency: $/USD</p>
          <p class="text-center">Please select a price range below.</p>
          <div class="d-flex flex-row items-center mb-4">
            <input
              [formControl]="priceFilter.get('minPrice')"
              class="c-filters__input"
              type="number"
              placeholder="Min"
              min="0"
              (keypress)="onKeyPress($event)"
            />
            <p class="mb-0 ml-2 mr-2">to</p>
            <input
              [formControl]="priceFilter.get('maxPrice')"
              class="c-filters__input"
              type="number"
              placeholder="Max"
              min="0"
              (keypress)="onKeyPress($event)"
            />
          </div>
          <div
            class="text-center text-danger"
            style="margin-top: -15px;"
            *ngIf="value"
          >
            <h6 class="pt-0">
              <small>Minimum must be less than the maximum!</small>
            </h6>
          </div>
          <button [disabled]="!price" class="btn btn-primary" [ngClass]="{'btnPrice' : price}" (click)="applyPriceFilter()">
            Apply
          </button>
        </div>
      </li>
      <li class="c-filter__item">
        <div
          class="c-filter__title"
          (click)="toggleMenu($event, filterenum.OffChain)"
        >
          <span class="title-span">Off-chain Collections</span>
          <span (click)="arrowToggleMenu(ofChainArrow)" class="chevron">
            <div *ngIf="!isOpen(filterenum.OffChain)">
              <i class="fas fa-chevron-down"></i>
            </div>
            <div *ngIf="isOpen(filterenum.OffChain)">
              <i class="fas fa-chevron-up"></i>
            </div>
          </span>
        </div>
        <ul class="c-filters__list" #ofChainArrow>
          <div class="c-search mb-4">
            <input
              id="offchainCollectionFilter"
              for="offchainCollectionFilter"
              (keypress)="offchainCollectionFilter($event)"
              class="c-search-bar"
              type="search"
              name="offchainCollectionFilter"
              placeholder="Filter"
              aria-label="Search through collections."
            />
            <div class="c-search-button">
              <span class="icon search c-search__svg"></span>
            </div>
            <button
              (click)="offchainCollectionFilter($event)"
              class="c-search-submit"
            >
              Go
            </button>
          </div>
          <li *ngIf="offChainCollections.length === 0">
            There were no results found...
          </li>
          <li
            class="c-filter__item-inner"
            *ngFor="let collection of offChainCollections; let i = index"
          >
            <a class="d-flex">
              <div class="c-filter__circle">
                <img
                  *ngIf="collection.picture"
                  width="100%"
                  height="100%"
                  [src]="collection.picture"
                  alt=""
                />
                <img
                  *ngIf="!collection.picture"
                  width="100%"
                  height="100%"
                  src="/assets/images/home/home-1.png"
                  alt=""
                />
              </div>
              <span
                (click)="applyOffChainCollectionFilter(collection)"
                class="c-filter-item-title"
              >
                {{ collection.name }}
              </span>
            </a>
          </li>
        </ul>
      </li>
      <li class="c-filter__item">
        <div
          class="c-filter__title"
          (click)="toggleMenu($event, filterenum.OnChain)"
        >
          <span class="title-span">On-chain Collections</span>
          <span (click)="arrowToggleMenu(onChainArrow)" class="chevron">
            <div *ngIf="!isOpen(filterenum.OnChain)">
              <i class="fas fa-chevron-down"></i>
            </div>
            <div *ngIf="isOpen(filterenum.OnChain)">
              <i class="fas fa-chevron-up"></i>
            </div>
          </span>
        </div>
        <ul class="c-filters__list" #onChainArrow>
          <div class="c-search mb-4">
            <input
              id="onchainCollectionFilter"
              for="onchainCollectionFilter"
              (keypress)="onchainCollectionFilter($event)"
              class="c-search-bar"
              type="search"
              name="onchainCollectionFilter"
              placeholder="Filter"
              aria-label="Search through collections."
            />
            <div class="c-search-button">
              <span class="icon search c-search__svg"></span>
            </div>
            <button
              (click)="onchainCollectionFilter($event)"
              class="c-search-submit"
            >
              Go
            </button>
          </div>
          <li
            class="c-filter__item-inner"
            *ngFor="let collection of onChainCollections; let i = index"
          >
            <a class="d-flex" [routerLink]="['/profile', collection.id]">
              <div class="c-filter__circle">
                <img
                  *ngIf="collection.picture"
                  width="100%"
                  height="100%"
                  [src]="collection.picture"
                  alt=""
                />
                <img
                  *ngIf="!collection.picture"
                  width="100%"
                  height="100%"
                  src="/assets/images/home/home-1.png"
                  alt=""
                />
              </div>
              <span class="c-filter-item-title">{{ collection.username }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="c-filter__item">
        <div
          class="c-filter__title"
          (click)="toggleMenu($event, filterenum.Category)"
        >
          <span class="title-span">Categories</span>

          <span (click)="arrowToggleMenu(categoryArrow)" class="chevron">
            <div *ngIf="!isOpen(filterenum.Category)">
              <i class="fas fa-chevron-down"></i>
            </div>
            <div *ngIf="isOpen(filterenum.Category)">
              <i class="fas fa-chevron-up"></i>
            </div>
          </span>
        </div>
        <ul class="c-filters__list" style="font-weight: 100;" #categoryArrow>
          <div class="c-search mb-4">
            <input
              id="categorySearchFilter"
              for="categorySearchFilter"
              (keypress)="categorySearchFilter($event)"
              class="c-search-bar"
              type="search"
              name="categorySearchFilter"
              placeholder="Filter"
              aria-label="Search through collections."
            />
            <div class="c-search-button">
              <span class="icon search c-search__svg"></span>
            </div>
            <button
              (click)="categorySearchFilter($event)"
              class="c-search-submit"
            >
              Go
            </button>
          </div>
          <!--  <span
            (click)="clearCategoryFilter()"
            class="c-filter-item-title category-clear-filter-btn"
            >Clear Filter</span
          > -->
          <li
            class="c-filter__item-inner"
            [ngClass]="{ 'select-item': category?.selected }"
            *ngFor="let category of collectionsCategories; let i = index"
            (click)="applyCategoryFilter(category)"
          >
            <div class="d-flex">
              <div class="c-filter__circle">
                <img
                  *ngIf="!category?.picture"
                  width="100%"
                  height="100%"
                  src="/assets/images/home/home-1.png"
                  alt=""
                />
              </div>
              <span class="c-filter-item-title">{{ category.name }}</span>
            </div>
          </li>
        </ul>
      </li>
      <li class="c-filter__item">
        <div
          class="c-filter__title"
          (click)="toggleMenu($event, filterenum.Blockchain)"
        >
          <span class="title-span">Blockchains</span>
          <span (click)="arrowToggleMenu(blockChainArrow)" class="chevron">
            <div *ngIf="!isOpen(filterenum.Blockchain)">
              <i class="fas fa-chevron-down"></i>
            </div>
            <div *ngIf="isOpen(filterenum.Blockchain)">
              <i class="fas fa-chevron-up"></i>
            </div>
          </span>
        </div>
        <ul class="c-filters__list" #blockChainArrow>
          <li (click)="changeFilter('MATIC')" class="c-filter__item-inner">
            <div class="c-filter__circle blockchains">
              <span class="c-card__safe icon polygon"></span>
            </div>
            <span>Polygon</span>
          </li>
          <li (click)="changeFilter('ETH')" class="c-filter__item-inner">
            <div class="c-filter__circle blockchains">
              <span class="c-card__safe icon ethereum"></span>
            </div>
            <span>Ethereum</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<div class="main-body">
  <div class="pl-4 pr-4">
    <div class="loading-component" *ngIf="mixedCollectionItems === null">
      <app-loading></app-loading>
    </div>
    <div class="row">
      <div class="col-sm-12 filter-alerts">
        <div class="alert-box-item" *ngFor="let alert of alerts" >
          <ngb-alert [type]="alert.type"  (closed)="close(alert)">{{ alert.message }}</ngb-alert>
        </div>
        <div class="alert-box-item" *ngIf="alerts.length != 0">
          <ngb-alert type="light" (closed)="clearAllFiltersClick()">
            Clear All
          </ngb-alert>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="mixedCollectionItems !== null">
      <div class="col-sm-12">
        <p>{{ mixedCollectionItems?.length }} results</p>
      </div>
    </div>
    <div
      class="row row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6"
      *ngIf="mixedCollectionItems !== null"
    >
      <div
        class="alert alert-primary w-100 text-center"
        role="alert"
        *ngIf="!mixedCollectionItems?.length"
      >
        No item to display
      </div>
      <div *ngFor="let item of mixedCollectionItems" class="col">
        <app-digi-card class="c-card-container" [data]="item"></app-digi-card>
      </div>
    </div>
  </div>
  <button (click)="toggleFilter()" class="c-filter-button">Filter</button>
</div>
