<div class="container p-b-40 page-top forgor-password">
    <div class="row justify-center items-center">
      <div class="col-lg-6 p-t-10 p-b-6">
        <h2>Forgot Password</h2>
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="text"
              formControlName="email"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
            </div>
          </div>
          <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary">
              <span
                *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"
              ></span>
              Forgot Password
            </button>
            <a routerLink="/register" class="btn btn-link">Register</a>
            <a routerLink="/login" class="btn btn-link">Login</a>
          </div>
        </form>
      </div>
    </div>
  </div>
  