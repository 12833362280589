<div class="container page-top">
  <div class="row">
    <div class="col-12 p-t-10 p-b-6">
      <span class="section-title"
        ><span class="icon box"></span>Sell NFT</span
      >
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>
  <div class="row justify-center" *ngIf="showSwitchToEth">
    <div class="col-lg-12 text-center mt-4">
      <p>
        This section of the dApp only works if you connect your wallet to the
        Ethereum Network.
      </p>
    </div>
    <div class="mx-auto text-center mt-2">
      <button class="btn btn-primary btn-menu" (click)="switchToEth()">
        <span class="btn-title">Switch to Ethereum</span>
      </button>
    </div>
  </div>
  <div *ngIf="!showSwitchToEth">
    <div class="row" *ngIf="myCards == null">
      <div class="col">
        <div class="mx-auto text-center mt-4 text-white">
          <app-loading></app-loading>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="myCards && myCards.length == 0">
      <div class="col">
        <div class="mx-auto text-center mt-4">
          <b
            >You don't have any NFTs yet.<br />
            You can buy them on the marketplace or in auctions. <br />You can
            also add other collections from your profile.</b
          >
        </div>
      </div>
    </div>
    <div class="row" *ngIf="myCards != null && loading">
      <div class="col">
        <div class="mx-auto text-center mt-4 text-white">
          <app-loading-blockchain></app-loading-blockchain>
        </div>
      </div>
    </div>
    <div class="row justify-center" *ngIf="myCards != null && !loading">
      <div class="col-12 text-center mt-4">
        <p class="m-0">
          Select the token that you want to sell from your collection. The first
          time you sell you will have to approve your NFTs.
        </p>
      </div>
      <div
        *ngFor="let nft of myCards"
        class="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-t-40"
      >
        <app-digi-card *ngIf="!nft.price" [data]="nft"  view="create-sell"></app-digi-card>
        <button
          class="button full-width mb-4"
          (click)="approve()"
          *ngIf="showApprove"
        >
          Approve
        </button>
        <button
          class="button full-width mb-4"
          *ngIf="!showApprove"
          [routerLink]="['/for-sale/create', network, address, nft.id]"
        >
          Sell
        </button>
      </div>
    </div>
  </div>
</div>
