import { Component, OnInit } from '@angular/core';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthenticationService } from 'src/app/services/Authentication.service';
import { CollectionsService } from 'src/app/services/collections.service';

@Component({
  selector: 'app-my-auctions',
  templateUrl: './my-auctions.component.html',
  styleUrls: ['./my-auctions.component.scss']
})
export class MyAuctionsComponent implements OnInit {
  auctions = [];
  isVerifiedEmail: boolean = false;
  currentUser: any;
  auctionDataSize = 0
  currentPage = 1;
  auctionsPerPage = 10;
  auctionNumber: number = 0;
  loading: boolean = false;
  itemName: string = '';
  constructor(
    private readonly auctionService: AuctionService,
    private authenticationService: AuthenticationService,
    ) {
      this.authenticationService.currentUser.subscribe(
        (x) => (this.currentUser = x)
      );
    }

  ngOnInit(): void {
    this.currentUser = this.currentUser.data;
    this.isVerifiedEmail = this.currentUser.verified;
    this.getMyAuctions(this.currentPage);
  }

  getMyAuctions(page: number, itemName: string = '') {
    this.loading = true;
    this.auctionService.getMyAuctions(page, itemName).subscribe((res)=> {
      this.auctions = res.data;
      this.auctionDataSize = res.total;
      this.currentPage = res.current_page;
      this.auctionsPerPage = res.per_page;
      this.auctionNumber = (this.currentPage - 1) * this.auctionsPerPage;
      this.loading = false;
    })
  }

  public onPageChange(pageNum: number): void {
    this.getMyAuctions(pageNum);
  }

}
