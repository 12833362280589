import { Injectable } from '@angular/core';
import { Profile } from '../types/profile.type';
import { OffchainService } from '../services/offchain.service';
import { WalletService } from '../services/wallet.service';
// import { environment } from 'src/environments/environment';

@Injectable()
export class WalletsService {
  constructor(public offchain: OffchainService, public wallet: WalletService) {}
  async getFullProfile(address: string): Promise<any> {
    address = this.wallet.getWeb3().utils.toChecksumAddress(address)
    return new Promise(async (resolve, reject) => {
      await this.offchain
        .getProfileData(address)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updProfileData(
    address: string,
    profileImage: string,
    heroImage: string,
    username: string,
    description: string,
    twitter: string,
    instagram: string,
    email: string,
    twitch: string,
    tiktok: string
  ) {
    console.log(this.wallet.getWeb3().utils.toChecksumAddress(address));
    const ipfs = await this.offchain.updProfile(
      this.wallet.getWeb3().utils.toChecksumAddress(address),
      profileImage,
      heroImage,
      username,
      description,
      twitter,
      instagram,
      email,
      tiktok,
      twitch
    );
    console.log(ipfs);
    return ipfs['status'] == 'success';
  }
}
