import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../../services/alert.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/Authentication.service';
import { CollectionsService } from '../../services/collections.service';
import { CategoryService } from '../../services/category/category.service';
import { Category } from '../../models/category';

@Component({
  selector: 'app-collections',
  templateUrl: './create-collection.component.html',
  styleUrls: ['./create-collection.component.scss'],
})
export class CreateCollectionComponent implements OnInit {
  id: number;
  currentUser: any;
  returnUrl: string;
  collectionForm: FormGroup;
  loading = false;
  submitted: boolean = false;
  categoryList: Category[];
  collectionUser: string;

  constructor(
    private formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private collectionsService: CollectionsService,
    private userService: UserService,
    private categoryService: CategoryService,
    private alertService: AlertService
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    this.route.params.subscribe((queryParams) => {
      this.userService.verifyUser(queryParams.role);
    });
  }

  ngOnInit() {
    this.collectionForm = this.formBuilder.group({
      name: ['', Validators.required],
      logo_image: ['', Validators.required],
      featured_image: [''],
      banner_image: [''],
      description: ['', Validators.required],
      external_url: [''],
      categories: [''],
      website: [''],
      discord: [''],
      twitter: [''],
      instagram: [''],
      medium: [''],
      telegram: [''],
    });
    this.currentUser = this.currentUser.data;
    if(this.currentUser.role[1]){
      this.collectionUser = this.currentUser.role[1];
    }else{
      this.collectionUser = this.currentUser.role[0];
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/my-collections';

    this.getCategoryList();
  }

  getCategoryList() {
    this.categoryService.getAll().subscribe(
      response => {
        this.categoryList = response.data;
      },
      err => {

      }
    )
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.collectionForm.controls;
  }

  loadData() {
    this.userService.getUserById(this.id);
  }

  onCollectionSubmit() {
    this.submitted = true;
    this.alertService.clear();
    if (this.collectionForm.invalid) {
      return;
    }

    this.loading = true;
    this.collectionsService
      .createCollection(this.collectionForm.value)
      .then((res) => {
        this.router.navigate(['/my-collections', this.collectionUser]);
        this.alertService.success('Collection has been created successfully', true);
      }).catch((error) => {
        this.alertService.error(error?.errors?.name[0]);
        this.loading = false;
      })
  }


  handleFileInput(files: FileList, name) {
    let me = this;
    let file = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      this.collectionForm.get(name).setValue(e.target.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }


}
