import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
  loading = true;
  usersData: any[];
  collectionSize = 0
  currentPage = 1;
  itemsPerPage = 10;
  userNumber: number = 0;
  returnUrl = 'user-order-details';
  ActivatedRoute: ActivatedRoute;
  userName: string = "";

  constructor(private userService: UserService,
    private router: Router) {}

  ngOnInit(): void {
    this.loadData(this.currentPage);
  }

  openUserOrderDetail(id: number, role: string) {
      this.router.navigate(['/user-order-details/'+id+'/'+role]);
  }

  public onPageChange(pageNum: number): void {
    this.loadData(pageNum);
  }

  loadData(page: number, userName: string = ''): void {
    this.userService.getAllAdminUsers(page, userName).subscribe((data) => {
      this.usersData = data.data;
      this.loading = false;
      this.currentPage = data.current_page;
      this.collectionSize = data.total;
      this.itemsPerPage = data.per_page;
      this.userNumber = (this.currentPage - 1) * this.itemsPerPage;
    });
  }

}
