<div class="container page-top">
  <div class="row">
    <div class="col-12 p-t-10 p-b-6">
      <div class="section-title">
        <span class="icon user"></span>
        <span>Privacy Policy</span>
      </div>
    </div>
    <div class="solid mt-4 mb-4 mb-4"></div>
  </div>

  <div class="row justify-center p-b-20">
    <div class="col-xl-7 col-lg-6 col-md-10 col-sm-12">
      <div>
        <p>This privacy policy (“Policy”) describes how Digible, LLC and its related companies (“DIGI”)
          collect, use and share personal information of consumer users of this website, www.digible.io
          and all subdomains, such as dev.digible.io, thereof (the “Site”).</p>
        <h1>WHAT WE COLLECT</h1>
        <p> In general, you can visit and use this website without revealing any personal information about
          yourself; however, there are certain times and circumstances where we may select to gather
          information from you. Personal information such as your name, company name, postal address
          and e-mail address may be obtained only when voluntarily submitted. Technical information,
          such as details about your device and IP address, public cryptographic address may be collected
          in virtue of use of certain DIGI technology.
        <ul>
          <li><span class="bold">Information you Give us:</span> In certain cases we provide a web-form that
            collects your name, Company, Industry, Email, and Phone Number,</li>
          <li><span>Information we Collect Automatically:</span> Our Website may collect
            technical information such as: the IP address and location of your device; public
            cryptographic wallet address; the language used in the browser; the version of the
            browser; the type of device; the screen Resolution (window size); and referral site (where
            you, the visitor, came from before you clicked on the Site).
          </li>
          <li>Cookies and Web Beacons: We may collect and log information using
            "cookies," which are small data files stored on your hard drive by the Site. Cookies help
            us make platform and marketing adjustments to improve our Site and your experience
            using it. DIGI may also use cookies to identify users who have already logged on, and
            connect client online accounts with usernames and passwords. The Site may also log
            information using digital images, called Web Beacons, on our Site or in our emails.
            These are used to manage cookies, count visits, and to gain more information about the
            relative effectiveness of marketing.</li>
        </ul>
        <h2>HOW WE USE YOUR INFORMATION</h2>
        <p>DIGI’s representatives will be able to see these details you provide on our web form and use
          them to contact you by phone or email. DIGI uses personal and other information provided, and
          automatically collected such information in the following ways:</p>
        <ul>
          <li>Third Parties: Your personal data that you provide to us via the web form
            may be inputted in a third-party mail services</li>
          <li>To Improve Customer Service: Information you provide helps us
            respond to your customer service requests and support needs more efficiently.</li>
          <li>To Personalize User Experience: We may use information in the
            aggregate to understand how our Users, as a group, use the services and resources
            provided on our Site.</li>
          <li>To Run a Promotion, Contest, Survey or Other Site Feature: Through
            our newsletter and email features, we may use our collected information to create
            marketing materials and send you information that you agreed to receive about topics we
            think will be of interest to you.</li>
          <li>To Send Periodic Emails: We may use the email address to send you
            information and updates pertaining to your order. It may also be used to respond to your
            inquiries, questions, and/or other requests. </li>
          <li>To Send Newsletters: If you choose to subscribe to our newsletter, we
            will also use that contact information to deliver the newsletter on a determined regular
            cycle. Opting-out or making changes to how information is gathered and used is
            described below</li>
          <li>To Serve Advertisements: We use Google Analytics to understand web
            traffic and our marketing efforts.</li>
        </ul>
        <h3>HOW WE SHARE YOUR INFORMATION</h3>
        <p> We may share personal data that you provide with members and affiliates of DIGI, and/or
          business partners for purposes related to those described above. Your personal information that
          you provide via our web form is distributed throughout DIGI for in-house company use, and will
          be stored in our Database.</p>
        <p> We will not sell, rent or lease to others your personally identifiable information. Importantly,
          DIGI reserves the right to disclose any relevant information to law enforcement or other
          governmental officials as we, in our sole discretion, believe necessary or appropriate to comply
          with the laws, and lawful requests and legal processes, as well as in the case of an emergency,
          which includes protecting the safety of our employees and agents, our customers, or any other
          person.</p>
        <h4>OPTING OUT OR CHOICES</h4>
        <p>As a default, all users will be automatically enrolled in the newsletter once they submit a web
          form. If at any time, you would like to unsubscribe from receiving the newsletter or future
          emails, you may do so by contacting us via our Site or following the “unsubscribe” link in your
          email.</p>
        <p> You can typically remove and reject cookies from our Site with your browser settings. Many
          browsers are set to accept cookies until you change your settings. If you remove or reject our
          cookies, it could affect how our Site works for you.</p>
        <h4>CHANGES TO THIS STATEMENT</h4>
        <p> If there are updates to the terms of DIGI’s online Privacy Statement, we will update the
          information on this page with the revision date to provide you with a current understanding of
          what information we collect online, how we use it, and what choices you have. Your continued
          use of DIGI’s website following the posting of changes to this Privacy Statement means you
          accept those changes.</p>
        <p> We will use SSL protocol while interacting with website.</p>
        <h4>YOUR ACCEPTANCE OF THESE TERMS</h4>
        <p>By using DIGI’s Site, you indicate your assent to this Privacy Statement and reasonable
          implications thereof.</p>
        <h4>HOW TO CONTACT US</h4>
        <p>Should you have comments or questions about this Privacy Statement, you may e-mail us at:</p>
        <a href="mailtohello@digible.io">hello@digible.io</a>
        <p>This Policy was Last Updated as of August 1, 2021</p>
      </div>
    </div>
  </div>
</div>
