import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CartService } from 'src/app/services/cart.service';
import { LoginResponse, User } from '../models/user';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<LoginResponse>;
  public currentUser: Observable<LoginResponse>;

  constructor(private cartService: CartService, private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<LoginResponse>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value?.data;
  }

  login(email, password) {
    return this.http
      .post<any>(`${environment.systemLabsAPi}/auth/login`, { email, password })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  verifyEmail(email) {
    return this.http
      .post<any>(`${environment.systemLabsAPi}/auth/reset-token`, {'email': email });
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    window.sessionStorage.removeItem('cart');
    this.cartService.toResetCart();
  }

  forgotPassword(email) {
    return this.http
      .post<any>(`${environment.systemLabsAPi}/auth/forget-password`, { email });
  }

  changePassword(data): Observable<any> {
    return this.http
      .post<any>(`${environment.systemLabsAPi}/auth/forget-password/confirm`, data);

  }
}
