<div *ngIf="isVerifiedEmail; else notVerified">
  <div class="input-group col-12">
    <div class="form-outline col-5">
      <input id="search-focus" placeholder="Search by item name" [(ngModel)]="itemName" type="search" class="form-control" />
    </div>
    <button type="button" class="btn btn-primary col-1" (click)="getAllOrders(currentPage, itemName)">
      <i class="fas fa-search"></i>
    </button>
  </div>
  <div class="col-lg-9 mb-4 mt-4">
    <h1 class="bold text-blue">Order Detail and Transactions</h1>
  </div>


  <div class="order-detail" *ngFor="let order of orders; let i = index">
    <div class="d-flex container-detail">
      <div class="container-image">
        <div class="mt-2 mb-2">
          <h4 class = "text-primary">Order Number: {{oderNumber + i+1}}</h4>
        </div>

        <ng-container>
          <h2>Order Transaction</h2>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">payment_id</th>
                <th scope="col">transaction_number</th>
                <th scope="col">status</th>
                <th scope="col">currency</th>
                <th scope="col">total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of order.transactions, let i = index">
                <th scope="row">{{ i+1 }}</th>
                <td>{{ item.payment_id}}</td>
                <td>{{ item.transaction_number }}</td>
                <ng-container *ngIf="item.status === 1">
                  <td>purchased</td>
                </ng-container>

                <td>{{ item.currency }}</td>
                <td>{{ item.total }}</td>
              </tr>
            </tbody>
          </table>

        </ng-container>


        <ng-container>
          <h2>Order Detail</h2>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">collection Item</th>
                <th scope="col">collection name</th>
                <th scope="col">Status</th>
                <th scope="col">Price</th>
                <th scope="col">Seller Name</th>
                <th scope="col">Seller Email</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of order.order_details, let i = index">
                <th scope="row">{{ i+1 }}</th>
                <td>{{ item?.collectionitem?.name }}</td>
                <td>{{ item?.collectionitem?.collection?.name }}</td>
                <td *ngIf="item?.auction_id">On Auction</td>
                <td *ngIf="!item?.auction_id">Available For Sale</td>
                <td>{{ item?.collectionitem?.price}}</td>
                <td>{{ item?.collectionitem?.collection?.user?.name}}</td>
                <td>{{ item?.collectionitem?.collection?.user?.email}}</td>
              </tr>
            </tbody>
          </table>

        </ng-container>
      </div>

      <div class="container mt-2">

        <div class="row col-8 d-block">
          <div class="float-left pl-5 user-detail">
            <div class="amount-field">
              <label>reference_id: <span>{{order.ref_id}}</span></label>
            </div>
            <div class="amount-field" *ngIf="order?.order_details[0]?.collectionitem?.available_for_sale === 3">
              <label>status: <span>Sold Item</span></label>
            </div>
            <div class="amount-field" *ngIf="order?.order_details[0]?.collectionitem?.available_for_sale === 2">
              <label>status: <span>Auction Item</span></label>
            </div>
            <div class="amount-field" *ngIf="order?.order_details[0]?.collectionitem?.available_for_sale === 1">
              <label>status: <span>Available For Sale Item</span></label>
            </div>
            <div class="amount-field" *ngIf="order?.order_details[0]?.collectionitem?.available_for_sale === 0">
              <label>status: <span>Not For Sale Item</span></label>
            </div>
            <div class="amount-field">
              <label>Buyer name: <span>{{order?.user?.name}}</span></label>
            </div>
            <div class="amount-field">
              <label>Buyer email: <span>{{order?.user?.email}}</span></label>
            </div>
          </div>
        </div>

        <div class="row col-12 d-block">
          <div class="float-right">
            <div class="amount-field">
              <label>discount: <span>{{order.discount}}</span></label>
            </div>
            <div class="amount-field">
              <label>subtotal: <span>{{order.subtotal}}</span></label>
            </div>
            <div class="amount-field">
              <label>tax: <span>{{order.tax + ' '}} (10%) </span></label>
            </div>
            <div class="amount-field">
              <label>total: <span>{{order.total}}</span></label>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
  <div class="d-flex justify-content-between p-2" *ngIf="ordersDataSize >= 10">
    <ngb-pagination class="mx-auto" [collectionSize]="ordersDataSize" [pageSize]="ordersPerPage"
      [(page)]="currentPage" (pageChange)="onPageChange(currentPage)"></ngb-pagination>
  </div>
</div>
<ng-template #notVerified>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="mx-auto text-center">
          <h1 class="mt-5">Please verify your email address before continuing, <b>it may be in your spam/junk
              folder.</b></h1>
        </div>
      </div>
    </div>
  </div>

</ng-template>
