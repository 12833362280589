import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateFromNow',
})
export class DateFromNow implements PipeTransform {
  transform(value: Date): string {
    return moment(value).fromNow();
  }
}
