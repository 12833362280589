export class Categories {
    data: Category[];
    // Add other backend response properties here if present
  }

  export class Category {
    id: number;
    name: string;
    label: string;

    constructor(category: any){
      this.id = category.id;
      this.name = category.name;
      this.label = category.label;
    }
  }
