<div class="container page-top">
  <div class="row c-page-title">
    <div class="col-4 p-t-10 p-b-6">
      <div class="section-title">
        <span class="icon user"></span>
        <span>Digi Team</span>
      </div>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>
  <div class="row p-b-20">
     <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="c-team">
        <div class="circle mb-4"><span class="icon user bg-white"></span></div>
        <h4 class="bold">Cary Granat</h4>
        <h5>Executive, Strategy</h5>
      </div>
    </div>
    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="c-team">
        <div class="circle mb-4"><span class="icon user bg-white"></span></div>
        <h4  class="bold">Edward Jones</h4>
        <h5>Executive, Strategy</h5>
      </div>
    </div>
    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="c-team">
        <div class="circle mb-4"><span class="icon user bg-white"></span></div>
        <h4  class="bold">Serge Pustelink</h4>
        <h5>Co-Founder</h5>
      </div>
    </div>
    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="c-team">
        <div class="circle mb-4"><span class="icon user bg-white"></span></div>
        <h4 class="bold">Alex Lubetsky</h4>
        <h5>Co-Founder</h5>
      </div>
    </div>
    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="c-team">
        <div class="circle mb-4"><span class="icon user bg-white"></span></div>
        <h4 class="bold">Steve Aoki </h4>
        <h5>Collections and Curation</h5>
      </div>
    </div>
    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="c-team">
        <div class="circle mb-4"><span class="icon user bg-white"></span></div>
        <h4 class="bold">_SystemLabs</h4>
        <h5>Engineering</h5>
      </div>
    </div>
  </div>
</div>
