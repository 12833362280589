import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommentsService } from 'src/app/services/comments.service';
import { Comment, CommentParam, CommentType } from 'src/app/types/comments.type';

@Component({
  selector: 'app-comment-reply-box',
  templateUrl: './comment-reply-box.component.html',
  styleUrls: ['./comment-reply-box.component.scss']
})
export class CommentReplyBoxComponent implements OnInit {

  @Input() commentId: string;
  @Input() id: string;
  @Input() type: CommentType = CommentType.Collection;
  @Input() replyCount = 0;
  @Output() replyAdded: EventEmitter<void> = new EventEmitter();

  public replies: Array<Comment> = [];
  public replyForm: FormGroup;
  public repliesExpended = false;
  public addReplyExpended = false;

  constructor(private commentsService: CommentsService) { }

  public ngOnInit(): void {
    this.initForm();
  }

  public getReplies(): void {
    if (this.repliesExpended) {
      this.repliesExpended = false;
      return;
    }

    this.commentsService.getReplies(this.commentId).subscribe(replies => {
      this.replies = replies;
      this.repliesExpended = true;
    })
  }

  public addReplyExpand(): void {
    this.addReplyExpended = true;
  }

  public addReply(): void {
    if (this.replyForm.invalid) {
      return;
    }

    this.commentsService.postReply(this.commentId, this.replyForm.value).subscribe(res => {
      this.replyForm.get('comment').reset(null);
      this.addReplyExpended = false;
    })
  }

  private initForm(): void {
    this.replyForm = new FormGroup({
      [CommentParam[this.type]]: new FormControl(this.id, Validators.required),
      comment: new FormControl('', [Validators.required]),
    });
  }
}
