<div class="seller-detail">
    <div class="d-flex container-detail">
        <div class="container-image">

            <ng-container>
                <span class="status-tab">
                    <button
                      class="btn btn-primary ml-auto m-2"
                      (click)="changeSellerStatus(seller.id, 'approved')"
                    >
                      Approve
                    </button>

                    <button
                      class="btn btn-danger ml-auto m-2"
                      (click)="changeSellerStatus(seller.id, 'rejected')"
                    >
                      Reject
                    </button>
                  </span>
            </ng-container>

            <ng-container>

                <span
      *ngIf="seller && seller.logo_image"
      class="c-profile__avatar"
      [ngStyle]="{ 'background-image': 'url(' + seller.logo_image + ')' }"
    ></span>

            </ng-container>

            <ng-container>
                <div class="c-profile__social">
          <div class="social" *ngIf="seller">
            <a
              [href]="seller.twitter_link"
              *ngIf="seller.twitter_link"
              target="_blank"
              ><i class="icon fab fa-twitter"></i
            ></a>

            <a
              [href]="seller.twitch_link"
              *ngIf="seller.twitch_link"
              target="_blank"
              ><i class="icon fab fa-twitch"></i
            ></a>

            <a
              [href]="seller.insta_link"
              *ngIf="seller.insta_link"
              target="_blank"
              ><i class="icon fab fa-instagram"></i
            ></a>
          </div>
        </div>
            </ng-container>
        </div>

        <div class="container" *ngIf="seller">
            <div class="field">
              <label>Name: <span>{{seller.name}}</span></label>
            </div>
            <div class="field">
                <label>Surname: <span>{{seller.surname}}</span></label>
            </div>
            <div class="field">
                <label>Wallet Address: <span>{{seller.wallet_address}}</span></label>
            </div>
            <div class="field">
                <label>Phone Number: <span>{{seller.phone_number}}</span></label>
            </div>
            <div class="field">
                <label>Type: <span>{{seller.type}}</span></label>
            </div>
            <div class="field">
                <label>Address: <span>{{seller.addresses.data[0].address}}</span></label>
            </div>
            <div class="field">
                <label>Address2: <span>{{seller.addresses.data[0].address2}}</span></label>
            </div>
            <div class="field">
                <label>Country: <span>{{seller.addresses.data[0].country}}</span></label>
            </div>
            <div class="field">
                <label>State: <span>{{seller.addresses.data[0].state}}</span></label>
            </div>
            <div class="field">
                <label>City: <span>{{seller.addresses.data[0].city}}</span></label>
            </div>
            <div class="field">
                <label>Postal Code: <span>{{seller.addresses.data[0].postalcode}}</span></label>
            </div>
            <div class="field">
                <label>kind: <span>{{seller.addresses.data[0].kind}}</span></label>
            </div>
          </div>

    </div>
</div>
