import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { constants } from 'buffer';
import { filter } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/services/Authentication.service';
const emailVerifySuccess = '/email/verify-success';
@Component({
  selector: 'app-email-verify-message',
  templateUrl: './email-verify-message.component.html',
  styleUrls: ['./email-verify-message.component.scss']
})
export class EmailVerifyMessageComponent implements OnInit {
  loginForm: FormGroup;
  isVerifiedSuccess: boolean = false;
  isEmailFail: boolean = false;
  currentRouter: string;
  submitted = false;
  istoken: string;
  loading = false;
  returnUrl: string;
  currentUser: any;
  useremail: string;
  paramEmail: string;


  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private readonly route: ActivatedRoute,
    ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.currentRouter = event.url.split('?')[0];
  });

  this.authenticationService.currentUser.subscribe(
    (x) => (this.currentUser = x)
  );

  this.route.queryParams.subscribe((queryParams) => {
    if(queryParams.token){
      this.paramEmail = queryParams.email;
      if(this.paramEmail !== this.currentUser.data.email){
        this.authenticationService.logout();
        this.router.navigate(['/login']);
      }
      this.currentUser.data.access_token = this.route.snapshot.queryParamMap.get('token');
      this.currentUser.data.verified = true;
      localStorage.removeItem('currentUser');
      localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
    }
  });
  }

  ngOnInit(): void {
    if(this.currentRouter === emailVerifySuccess) {
      this.isVerifiedSuccess = true;
    }

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
    });


  }

  showEmailForm() {
    this.isEmailFail = true;
  }


  onSubmit(){
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService
      .verifyEmail(this.f.email.value)
      .subscribe(
        (data) => {
          this.router.navigate([this.returnUrl]);
          this.alertService.success('Please check your email for verify your email id.', true);
          this.loading = false;
        },
        (error) => {
          this.alertService.error(error?.message || error);
          this.loading = false;
        }
      );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

}
