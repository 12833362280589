<div class="container page-top">
  <div class="row c-page-title">
    <div class="col-12 p-t-10 p-b-6">
      <span class="section-title"><span class="icon duels"></span>Digiduel</span>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>
  <div class="row">
    <div class="col">
      <div class="mx-auto text-center mt-4">
        <div class="c-duels">
          <h2 class="c-duels__title">Coming soon</h2>
        </div>
      </div>
    </div>
  </div>
</div>
