<div class="container page-top">
  <div class="row c-page-title">
    <div class="col-12 p-t-10 p-b-6">
      <div class="section-title">
        <span class="icon user"></span>
        <span>FAQ</span>
      </div>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>

  <div class="row justify-center p-b-20">
    <div class="col-xl-7 col-lg-9 col-md-10 col-sm-12">
      <div class="row">
        <div class="col-12">
          <accordion
            [showArrows]="true"
            [closeOthers]="true"
            [expandAll]="false"
          >
            <accordion-group>
              <accordion-heading>
                <accordion-toggle class="c-faq__title"
                  >1. What are the main ways to Buy NFTs on the Digible
                  platform?</accordion-toggle
                >
              </accordion-heading>
              <p>
                <span class="bold">A:</span> The platform offers 2 primary ways
                to buy/sell NFTs. Fixed Price Sales “buys” (can be found on the
                /explore page, which run on the Ethereum chain). Auctions “bids”
                (can be found on the /auctions page, which run on the
                Polygon-Matic side chain). Auctions also support “buy it now”
                transactions. When an auction offers a buy it now price, it
                still takes place on the Matic network and utilizes bridged
                $MATIC (for gas) & bridged $USDT (for payment). Each type of
                transaction (fixed-sale / auction) utilizes a different
                blockchain and thus requires different types of digital assets
                to participate: Fixed-Price Sales utilize $ETH & $USDT |
                Auctions utilize bridged $MATIC & $USDT
              </p>
            </accordion-group>
          </accordion>
        </div>
        <div class="col-12">
          <accordion
            [showArrows]="true"
            [closeOthers]="true"
            [expandAll]="false"
          >
            <accordion-group>
              <accordion-heading>
                <accordion-toggle class="c-faq__title"
                  >2. What is the difference between Physically-backed NFTs and
                  Digital NFTs?</accordion-toggle
                >
              </accordion-heading>
              <p>
                <span class="bold">A:</span> Digital NFTs are the ‘standard’
                non-fungible tokens one may be used to interacting with in the
                Ethereum Ecosystem. They can be images, videos, GIFs, 3d art,
                audio files, in-game items, map packs, and pretty much anything
                else in the virtual world we can imagine. Physically-Backed NFTs
                are a subset of NFTs that are backed by real-world physical
                items. We like to describe them as like the “title” for your
                physical item. For example, the title of your car, represents
                and establishes ownership of your vehicle. If you park your car
                in a garage, it's still your car because you still possess the
                Title which dictates ownership, it doesn’t matter where the car
                is physically, it's the title which establishes ownership.
                Similarly physically- backed NFTs act as titles to the physical
                items they represent. The owner of the NFT (i.e. the wallet
                which owns the NFT) is the true owner of the item, regardless of
                where the item is physically located. Another way to think about
                physically-backed NFTs is analogous to valet stubs. When you
                valet your car, you receive a slip of paper which represents
                your ownership of the vehicle. The valet may hold the keys and
                have physical control of the car, but the slip is what 23 proves
                your ownership. Physically-backed NFTs work similarly, a
                custodian [valet] is in charge of securing the physical item,
                while the owner retains the NFT [stub] which establishes
                ownership. When you want your item [car] back in your physical
                possession, you redeem your NFT [stub], which [NFT/stub] is then
                destroyed in the process. When you want to valet your car again,
                another NFT [stub] is reissued.
              </p>
            </accordion-group>
          </accordion>
        </div>
        <div class="col-12">
          <accordion
            [showArrows]="true"
            [closeOthers]="true"
            [expandAll]="false"
          >
            <accordion-group>
              <accordion-heading>
                <accordion-toggle class="c-faq__title"
                  >3. Am I buying real items? I thought NFTs are digital
                  items.</accordion-toggle
                >
              </accordion-heading>
              <p>
                <span class="bold">A:</span> Digital NFTs are virtual items that
                are not “real” in a physical sense. Physically backed NFTs on
                the other hand are linked 1:1 to real world physical items. See
                FAQ - What is the difference between Physically-backed NFTs and
                Digital NFTs?
              </p>
            </accordion-group>
          </accordion>
        </div>
        <div class="col-12">
          <accordion
            [showArrows]="true"
            [closeOthers]="true"
            [expandAll]="false"
          >
            <accordion-group>
              <accordion-heading>
                <accordion-toggle  class="c-faq__title"
                  >4. Am I buying pokemon NFTs? Is this licensed by
                  Pokemon?</accordion-toggle
                >
              </accordion-heading>
              <p>
                <span class="bold">A:</span> When you purchase a
                physically-backed NFT of a Pokemon Card, for example, you are
                purchasing the NFT “title” that is backed by a physical item.
                You are buying an NFT that represents and is backed 1:1 to
                physical items, in this case a graded Pokemon card. You are not
                necessarily buying an official Pokemon NFT, rather the digital
                NFT ( “ticket stub” or “title” ) which is backed by a physical
                card stored via a trusted custodian. There is no licensing from
                Pokemon required as this is not a Pokemon . Do Ebay sellers have
                licenses from Pokemon to sell Pokemon cards? Of course not, as
                they are not using the Pokemon brand to create a new item,
                rather to resell an official pokemon card they now possess. The
                concept here is similar. Digible is not issuing official Pokemon
                NFTs, rather “digital titles/stubs” that represent authentic
                pokemon cards that are being resold/auctioned.
              </p>
            </accordion-group>
          </accordion>
        </div>
        <div class="col-12">
          <accordion
            [showArrows]="true"
            [closeOthers]="true"
            [expandAll]="false"
          >
            <accordion-group>
              <accordion-heading d>
                <accordion-toggle class="c-faq__title"class="c-faq__title"
                  >5. How do I set up my wallet to be able to buy on the
                  platform?</accordion-toggle
                >
              </accordion-heading>
              <p><span class="bold">A:</span> See Setup Wallet</p>
            </accordion-group>
          </accordion>
        </div>
        <div class="col-12">
          <accordion
            [showArrows]="true"
            [closeOthers]="true"
            [expandAll]="false"
          >
            <accordion-group>
              <accordion-heading>
                <accordion-toggle class="c-faq__title"
                  >6. How do I buy NFTs on the platform?</accordion-toggle
                >
              </accordion-heading>
              <p>
                <span class="bold">A:</span> See Buyer Guide - For Participation
                in Buys
              </p>
            </accordion-group>
          </accordion>
        </div>
        <div class="col-12">
          <accordion
            [showArrows]="true"
            [closeOthers]="true"
            [expandAll]="false"
          >
            <accordion-group>
              <accordion-heading>
                <accordion-toggle class="c-faq__title"
                  >7. How do I bid on auctions for NFTs on the
                  platform?</accordion-toggle
                >
              </accordion-heading>
              <p>
                <span class="bold">A:</span> See Buyer Guide- For Participation
                in Auctions
              </p>
            </accordion-group>
          </accordion>
        </div>
        <div class="col-12">
          <accordion
            [showArrows]="true"
            [closeOthers]="true"
            [expandAll]="false"
          >
            <accordion-group>
              <accordion-heading>
                <accordion-toggle class="c-faq__title"
                  >8. How do I list NFTs for sale
                  (fixed-price)?</accordion-toggle
                >
              </accordion-heading>
              <p>
                <span class="bold">A:</span> See Seller Guide - Create Fixed
                Sale
              </p>
            </accordion-group>
          </accordion>
        </div>
        <div class="col-12">
          <accordion
            [showArrows]="true"
            [closeOthers]="true"
            [expandAll]="false"
          >
            <accordion-group>
              <accordion-heading>
                <accordion-toggle class="c-faq__title"
                  >9. How do I create auctions for NFTs?</accordion-toggle
                >
              </accordion-heading>
              <p>
                <span class="bold">A:</span> See Seller Guide - Create Auction
              </p>
            </accordion-group>
          </accordion>
        </div>
        <div class="col-12">
          <accordion
            [showArrows]="true"
            [closeOthers]="true"
            [expandAll]="false"
          >
            <accordion-group>
              <accordion-heading>
                <accordion-toggle class="c-faq__title"
                  >10. How do I transfer my NFTs from Ethereum to Matic, and
                  back?</accordion-toggle
                >
              </accordion-heading>
              <p>
                <span class="bold">A:</span> Follow the links to learn how: - To
                transfer from Ethereum to Matic. - To transfer from Matic to
                Ethereum.
              </p>
            </accordion-group>
          </accordion>
        </div>
        <div class="col-12">
          <accordion
            [showArrows]="true"
            [closeOthers]="true"
            [expandAll]="false"
          >
            <accordion-group>
              <accordion-heading>
                <accordion-toggle class="c-faq__title"
                  >11. Can I sell external NFTs ( bought on other platforms such
                  as Opensea) on the platform?</accordion-toggle
                >
              </accordion-heading>
              <p>
                <span class="bold">A:</span> YES! You can. Follow the link to
                learn how: - To import an external NFT and list for fixed price
                sale - To import an external NFT and list for auction
              </p>
            </accordion-group>
          </accordion>
        </div>
        <div class="col-12">
          <accordion
            [showArrows]="true"
            [closeOthers]="true"
            [expandAll]="false"
          >
            <accordion-group>
              <accordion-heading>
                <accordion-toggle class="c-faq__title"
                  >12. Can I sell Digible NFTs on other platforms (such as
                  Opensea)?</accordion-toggle
                >
              </accordion-heading>
              <p>
                <span class="bold">A:</span> YES! You can. NFTs bought on the
                Digible platform live in your wallet, you will see them appear
                in your profile when you browse to the external platform and
                connect with the same MetaMask wallet that you used to acquire
                the NFT on the digible platform. To learn how to sell NFTs on
                the specific external platform you are interested in, google for
                and follow the instructions of each platform.
              </p>
            </accordion-group>
          </accordion>
        </div>
      </div>
    </div>
  </div>
</div>
