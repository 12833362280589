<div class="container">
  <div class="row" *ngIf="loading">
    <div class="col-lg-12">
      <div class="mx-auto text-center">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
  <div class="row justify-center p-b-40 mt-4" *ngIf="!loading">
    <div class="col-lg-9 mb-4">
      <h1 class="bold text-blue">Sellers for approval</h1>
      <hr />
      <ul class="d-flex flex-column list-unstyled">
        <li
          class="d-flex justify-content-between w-100 border-bottom pb-2 pt-2 items-center"
          *ngFor="let seller of sellersForApproval"
        >
          <span class="bold mr-4">{{ seller.name }}</span>
          <span>

            <button
            class="btn btn-primary ml-auto m-2"
            [routerLink]="['/admin/seller-detail', seller.id]"
          >
            Detail
          </button>

          <button
            class="btn btn-primary ml-auto m-2"
            (click)="changeSellerStatus(seller.id, 'approved')"
          >
            Approve
          </button>

          <button
            class="btn btn-danger ml-auto m-2"
            (click)="changeSellerStatus(seller.id, 'rejected')"
          >
            Reject
          </button>
        </span>
        </li>
      </ul>
      <div class="d-flex justify-content-between p-2" *ngIf="collectionSize >= 10">
        <ngb-pagination class="mx-auto" [collectionSize]="collectionSize" [pageSize]="itemsPerPage"
          [(page)]="currentPage" (pageChange)="onPageChange(currentPage)"></ngb-pagination>


        <!-- <select class="custom-select w-auto" [(ngModel)]="itemsPerPage" (change)="changePagesize()">
          <option [ngValue]="5">5 items per page</option>
          <option [ngValue]="10">10 items per page</option>
          <option [ngValue]="20">20 items per page</option>
        </select> -->
      </div>
    </div>
    <div class="col-lg-9">
      <h1 class="bold text-blue">Collections for Approval</h1>
      <hr />
      <ul class="d-flex flex-column list-unstyled">
        <li
          class="d-flex justify-content-between w-100 border-bottom pb-2 pt-2 items-center"
          *ngFor="let collection of collectionsForApproval"
        >
          <span class="bold mr-4">{{ collection.name }}</span>
          <span>
            <button
            class="btn btn-primary ml-auto m-2"
            [routerLink]="['/admin/collection-detail', collection.id]"
          >
            Detail
          </button>

          <button
            class="btn btn-primary ml-auto m-2"
            (click)="changeCollectionStatus(collection.id, 'approved')"
          >
            Approve
          </button>

          <button
            class="btn btn-danger ml-auto m-2"
            (click)="changeCollectionStatus(collection.id, 'rejected')"
          >
            Reject
          </button>
        </span>
        </li>
      </ul>
      <div class="d-flex justify-content-between p-2" *ngIf="collectionDataSize >= 10">
        <ngb-pagination class="mx-auto" [collectionSize]="collectionDataSize" [pageSize]="collectionItemsPerPage"
          [(page)]="collectionCurrentPage" (pageChange)="onCollectionPageChange(collectionCurrentPage)"></ngb-pagination>
      </div>
    </div>

    <div class="col-lg-9">
      <h1 class="bold text-blue">Comment for Approval</h1>
      <hr />
        <app-user-comment-list>
        </app-user-comment-list>
    </div>
  </div>
</div>
