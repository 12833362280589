<div class="container page-top">
  <div class="row c-page-title">
    <div class="col-12 p-t-10 p-b-6">
      <div class="section-title">
        <span class="icon user"></span>
        <span>Digi Duel</span>
      </div>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>

  <div class="row justify-center p-b-20">
    <div class="col-xl-6 col-lg-6 col-md-10 col-sm-12" >
      <span class="c-about__description">
        Coming Soon...
      </span>
    </div>
  </div>
</div>
