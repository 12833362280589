import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExecOptionsWithStringEncoding } from 'child_process';
import { WalletService } from 'src/app/services/wallet.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class DigiWaxService {
  constructor(
    private http: HttpClient,
    private readonly wallet: WalletService
  ) {
    this.setDigiWaxContract();
  }
  digiWaxAddress = environment.digiWaxAddress;
  digiWaxContract;

  async setDigiWaxContract() {
    this.digiWaxContract = await this.getDigiWaxContract(
      environment.digiWaxAddress,
      true,
      'MATIC'
    );
    console.log(this.digiWaxContract);
  }

  async getParticipantWalletsByRequestId() {
    // await
  }

  async accessPriceByBoxName(boxName: string): Promise<any> {
    const result = await this.digiWaxContract.methods
      .accessPrice_by_boxName_map(boxName)
      .call();
    return result;
  }

  async accessFeePercentage(): Promise<any> {
    const result = await this.digiWaxContract.methods
      .access_fee_percentage()
      .call();
    return result;
  }

  async breakWaxBox(boxName: string): Promise<any> {
    const result = await this.digiWaxContract.methods
      .breakWax_Box(boxName)
      .call();
    return result;
  }

  async getBoxOwner(boxName: string): Promise<any> {
    const result = await this.digiWaxContract.methods
      .boxOwner_map(boxName)
      .call();
    return result;
  }

  async subscribeWalletToBoxByRequestIdUsingKey(
    boxName: string,
    subscriberAddress: string,
    digiKeyTokenId: number
  ) {
    console.log(
      boxName,
      subscriberAddress,
      environment.nftKeysAddressMatic,
      digiKeyTokenId
    );

    const result = await this.digiWaxContract.methods
      .subscribeWalletToBoxByRequestIdUsingKey(
        boxName,
        subscriberAddress,
        environment.nftKeysAddressMatic,
        digiKeyTokenId
      )
      .call();
    return result;
  }

  async accessPriceContractAddressByBoxName(boxName: string) {
    const result = await this.digiWaxContract.methods
      .accessPriceContractAddress_by_boxName_map(boxName)
      .call();
    return result;
  }

  async subscribeWalletToBoxByName(boxName: string, walletAddress: string) {
    const result = await this.digiWaxContract.methods
      .subscribeWalletToBoxByName(boxName, walletAddress)
      .call();
    return result;
  }

  async updateSubscriptionsByBox(
    boxName: string,
    isGenSubOpen: boolean,
    isKeySubOpen: boolean
  ) {
    const result = await this.digiWaxContract.methods
      .updateSubscriptionsByBox(boxName, isGenSubOpen, isKeySubOpen)
      .call();
    return result;
  }

  async oracleSpokeByBoxName(boxName: string) {
    const result = await this.digiWaxContract.methods
      .oracleSpoke_by_boxName_map(boxName)
      .call();
    return result;
  }

  // Check if General Subscription is open
  async generalSubscriptionOpen_by_boxName_map(boxName: string): Promise<any> {
    const result = await this.digiWaxContract.methods
      .generalSubscriptionOpen_by_boxName_map(boxName)
      .call();
    return result;
  }

  // Check if DigiKey Subscription is open
  async digikeySubscriptionOpenByBoxName(boxName: string): Promise<any> {
    const result = await this.digiWaxContract.methods
      .digikeySubscriptionOpen_by_boxName_map(boxName)
      .call();
    return result;
  }

  // Get participant Wallets By Box Name and Array index
  /* async participantWalletsByBoxName(boxName: string): Promise<any> {
    const result = await this.digiWaxContract.methods
      .participantWallets_by_boxName_map(boxName, 1)
      .call();
    console.log(result);
    return result;
  } */

  // Get participant Wallets By Box Name and Array index
  async WalletSubscribed(boxName: string, walletAddress: string): Promise<any> {
    console.log('WalletSubscribed');
    //const result = await this.digiWaxContract.events.allEvents('WalletSubscribed');
    const fromBlock =
      (await this.wallet.getMaticInfuraWeb3().eth.getBlockNumber()) -
      environment.blocksInEvents;

    return new Promise(async (resolve, reject) => {
      this.digiWaxContract.getPastEvents(
        'WalletSubscribed',
        {
          boxname: boxName,
          wallet: walletAddress,
          usedKey: true,
          fromBlock: 0,
          toBlock: 'latest'
        },
        (error, events) => {
          if (error) {
            reject(error);
          } else {
            resolve(events);
          }
        }
      );
    });
  }

  // Check boxSealed By RequestId
  async boxSealedByRequestId(requestId: string): Promise<any> {
    const result = await this.digiWaxContract.methods
      .boxSealed_By_requestId_map(requestId)
      .call();
    return result;
  }

  async getRequestIdByBoxName(boxName: string) {
    const requestId = await this.digiWaxContract.methods
      .requestId_by_boxName_map(boxName)
      .call();
    return requestId;
  }

  private async getDigiWaxContract(
    contractAddress: string,
    readonly?: boolean,
    network?: string
  ): Promise<any> {
    const abi = require('../../assets/abis/digiwaxabi.json');
    if (readonly) {
      if (network === 'MATIC') {
        return new (this.wallet.getMaticInfuraWeb3().eth.Contract)(
          abi,
          environment.digiWaxAddress
        );
      } else if (network === 'ETH') {
        return new (this.wallet.getInfuraWeb3().eth.Contract)(
          abi,
          environment.digiWaxAddress
        );
      }
    } else {
      if (network === 'MATIC') {
        return new (this.wallet.getWeb3().eth.Contract)(
          abi,
          environment.digiWaxAddress
        );
      } else if (network === 'ETH') {
        return new (this.wallet.getWeb3().eth.Contract)(
          abi,
          environment.digiWaxAddress
        );
      }
    }
  }
}
