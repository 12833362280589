import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Comment, CommentType } from '../types/comments.type';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CommentsService {

  constructor(private http: HttpClient) { }

  public getComments(id: string, type: CommentType): Observable<Comment[]> {
    return this.http.get<{ data: Comment[] }>(`${environment.systemLabsAPi}/${type}/${id}/comment`).pipe(
      map(res => res.data)
    );
  }

  public postComment(data: Comment): Observable<any> {
    return this.http.post(`${environment.systemLabsAPi}/comments`, data);
  }

  public getReplies(commentId: string): Observable<Comment[]> {
    return this.http.get<{ data: Comment[] }>(`${environment.systemLabsAPi}/comment/${commentId}`).pipe(
      map(res => res.data)
    );
  }

  public postReply(commentId: string, data): Observable<any> {
    return this.http.post(`${environment.systemLabsAPi}/comments/${commentId}/reply`, data);
  }
}
