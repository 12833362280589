<div class="row clearfix h-100 chat-container">
  <div class="col-lg-12">
    <div class="mx-auto text-center" *ngIf="isConversationsLoading">
      <app-loading></app-loading>
    </div>
    <div class="card chat-app h-100" *ngIf="!isConversationsLoading">
      <div id="plist" class="people-list" [ngClass]="{
        'chat-active': messages
      }">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-search"></i></span>
          </div>
          <input type="text" class="form-control" placeholder="Search...">
        </div>
        <ul class="list-unstyled chat-list mt-2 mb-0">
          <li class="clearfix d-flex align-items-center" [ngClass]="{
            active: conversation?.reciever ? selectedConversation?.reciever?.id === conversation.reciever?.id : selectedConversation?.sender?.id === conversation?.sender?.id
          }" *ngFor="let conversation of getSortedConversations" (click)="openConversation(conversation)">
            <span class="icon user"></span>
            <div class="about">
              <div class="name">{{conversation.conversation_name}}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="chat d-flex flex-column" [ngClass]="{
        'chat-hide': !messages
      }">
        <div class="chat-header clearfix">
          <div class="row">
            <div class="col-lg-6" *ngIf="selectedConversation">
              <div class="message-send d-none back-button p-1" (click)="backToChats()"><i
                  class="fas fa-chevron-left"></i>
              </div>
              <span class="icon user"></span>
              <div class="chat-about h-100 d-flex align-items-center">
                <h6 class="mb-0">{{selectedConversation?.reciever?.name || selectedConversation?.sender.name}}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-auto text-center" *ngIf="isMessagesLoading">
          <app-loading></app-loading>
        </div>
        <div #chatHistory class="chat-history" *ngIf="!isMessagesLoading" infiniteScroll [infiniteScrollUpDistance]="1"
          [infiniteScrollThrottle]="100" [infiniteScrollContainer]="chatHistory" (scrolledUp)="getPreviousMessage()">
          <div *ngIf="!messages" class="mx-auto text-center">Send Messages</div>
          <ul class="mb-0">
            <li class="clearfix text-center" *ngIf="isPreviousMessagesLoading">
              <div class="mx-auto text-center">
                <app-loading></app-loading>
              </div>
            </li>
            <li class="clearfix" *ngFor="let message of messages">
              <div class="message-data" [ngClass]="{
                'text-right': currentUser.id === message.sender_id
              }">
                <span class="message-data-time">{{ message.created_at | dateFromNow }}</span>
              </div>
              <div class="message other-message" [ngClass]="{
                'float-right': currentUser.id === message.sender_id
              }"> {{message.message}}
              </div>
            </li>
          </ul>
        </div>
        <div class="chat-message clearfix">
          <div class="message-input" [formGroup]="messageForm" *ngIf="selectedConversation">
            <input class="form-control form-control-sm" type="text" formControlName="message"
              placeholder="Enter message.." />
            <div class="message-send" (click)="sendMessage()"><i class="fab fa-telegram-plane"></i></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>