import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { Category } from '../../models/category';
import { catchError, first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  getAll(id: number, page: number, item: string): Observable<any>  {
    const params = new HttpParams()
    .set('page', `${page}`).append('search', `${item}`);
    return this.http.get(`${environment.systemLabsAPi}/orders/buyer/`+id, {params});

  }

  getSellerItemHistory(id: any, page: number, itemName: string): Observable<any>  {
    const params = new HttpParams()
    .set('page', `${page}`).append('search', `${itemName}`);
    return this.http.get(`${environment.systemLabsAPi}/orders/seller/`+id, {params});
  }
}
