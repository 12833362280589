import { User } from "../models/user";

export enum CommentStatus {
    Approved = 'approved',
    Pending = 'pending',
    Rejected = 'rejected',
}

export enum CommentType {
    Collection = 'collection',
    CollectionItem = 'collection-item'
}

export enum CommentParam {
    'collection' = 'collection_id',
    'collection-item' = 'collection_item_id',
}

export interface Comment {
    id?: number;
    comment: string;
    count: number;
    parent_id: null
    user_id: number;
    status: { data: { id: number; name: CommentStatus } }
    updated_at: Date;
    created_at: Date;
    user: { data: User };
}

export interface Reply {
    id?: number;
}
