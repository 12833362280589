<div class="page-top">
  <div class="container p-b-40">
    <div class="row">
      <div class="col-9 p-t-10 p-b-6">
        <div class="section-title c-page-title">
          <span class="icon light"></span>
          <span>Search Results</span>
        </div>
      </div>
      <div class="col-lg-3 right">
        <ng-select
          placeholder="Filter By"
          [items]="filterBy"
          bindLabel="name"
          bindValue="id"
          (change)="changeFilter()"
          [(ngModel)]="typeSearch"
        >
        </ng-select>
      </div>
      <div class="solid mt-4 mb-4"></div>
    </div>
    <div class="row" *ngIf="!searchReady">
      <div class="col">
        <div class="mx-auto text-center mt-4 text-white">
          <app-loading></app-loading>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="nftList && nftList.length == 0">
      <div class="col">
        <div class="mx-auto text-center mt-4 text-white">
          <b>No data available</b>
        </div>
      </div>
    </div>
    <div class="row" id="list-result" *ngIf="nftList != null && searchReady">
      <div
        *ngFor="let nft of nftList"
        class="col-xl-4 col-lg-4 col-md-6 col-sm-6"
      >
        <app-moralis-card
          class="c-card-container"
          [data]="nft"
          id="{{ nft.id }}"
          price="{{ nft.price }}"
          auction="{{ nft.auction }}"
        ></app-moralis-card>
      </div>
      <div
        class="col-xl-4 col-lg-4 col-md-6 col-sm-6"
        *ngIf="searchReady && !searchEnd"
        id="end-search-app"
      >
        <app-loading></app-loading>
      </div>
    </div>
    <!-- <div class="row center p-t-10">
      <div class="col-lg-12">
        <button *ngIf="loading" class="btn btn-primary mt-4 mb-4">. . .</button>
        <button
          *ngIf="!loading && nftList != null && !endReached"
          class="btn btn-primary mt-4 mb-4"
          (click)="loadMore()"
        >
          Load more
        </button>
      </div>
    </div> -->
  </div>
</div>
