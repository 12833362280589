
<div class="input-group mr-2">
  <input [placeholder]="placeholder" [ngClass]="isError ? 'ng-invalid is-invalid' : 'ng-valid'" class="form-control"
    (blur)="inputBlur($event)" [ngModel]="date | date: 'dd/MM/yyyy HH:mm:ss'" (change)="onInputChange($event)"
    [disabled]="disabled" />

  <div class="input-group-append">
    <button class="btn btn-outline-secondary" [placement]="['top']" [ngbPopover]="calendarContent" [disabled]="disabled" type="button">
      <i class="fa fa-calendar"></i>
    </button>
  </div>
</div>


<ng-template #calendarContent>
  <div>
    <div>
      <ngb-datepicker #dp name="datepicker"  [minDate]="minDate" [(ngModel)]="dateStruct" (ngModelChange)="onDateChange($event)">
      </ngb-datepicker>
      <ngb-timepicker [meridian]="false" [seconds]="false" #tp name="timepicker" [(ngModel)]="timeStruct"
        (ngModelChange)="onTimeChange($event)" [seconds]="seconds" [hourStep]="hourStep" [minuteStep]="minuteStep">
      </ngb-timepicker>
    </div>
  </div>
</ng-template>
