<div class="row">

    <div class="col-lg-12">

      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Comment</th>
          <th scope="col">User</th>
          <th scope="col">Action</th>

        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of commentForApproval, let i = index">
          <th scope="row">{{ commentNumber + i+1 }}</th>
          <td>{{ item.comment}}</td>
          <td>{{ item.user.name}}</td>
          <td>
            <span>

              <button
                class="btn btn-primary ml-auto m-2"
                (click)="changeCommentStatus(item.id, 'approved')"
              >
                Approve
              </button>

              <button
                class="btn btn-danger ml-auto m-2"
                (click)="changeCommentStatus(item.id, 'rejected')"
              >
                Reject
              </button>
            </span>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-between p-2" *ngIf="collectionSize >= 10">
        <ngb-pagination class="mx-auto" [collectionSize]="collectionSize" [pageSize]="itemsPerPage"
          [(page)]="currentPage" (pageChange)="onPageChange(currentPage)"></ngb-pagination>
      </div>
    </div>
  </div>
