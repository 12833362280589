<div class="container page-top">
  <div class="row c-page-title">
    <div class="col-12 p-t-10 p-b-6">
      <div class="section-title">
        <span class="icon user"></span>
        <span>Digi grade</span>
      </div>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>

  <div class="row justify-center p-b-20">
    <div class="col-xl-6 col-lg-6 col-md-10 col-sm-12">
      <span class="c-about__description">
        DigiGrade is a service where Digible will inspect, prepare, and send the card to PSA for official grading. <br /><br />
        Your card will be officially graded via PSA, before it goes online
        at our marketplace.
      </span>
    </div>
  </div>
</div>
