import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuctionService {
    constructor(private http: HttpClient) { }

    public makeOffer(data): Observable<any> {
        return this.http.post(`${environment.systemLabsAPi}/auctions`, data);
    }

    public getCurrentBid(): Observable<any> {
        return this.http.get(`${environment.systemLabsAPi}/auction/current`);
    }

    wonAuction(id: string): any {
      const data = {
        collection_item_id: id
      }
        return new Promise((resolve, reject) => {
          this.http
            .put(`${environment.systemLabsAPi}/auction/update-won`, data)
            .toPromise()
            .then((res: any) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
            });
        });
      }

      getMyAuctions(page: number, item?:string):Observable<any>{
        const data = {
          itemName: item
        }
        const params = new HttpParams().set('page', `${page}`,).append('search', `${item}`);
        return this.http.get(`${environment.systemLabsAPi}/auction/current`, {params});
      }
}
