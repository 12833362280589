import { AuthenticationService } from './../../services/Authentication.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AvailableForSale } from './../../types/collection-item.type';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import moment from 'moment';
import { AuctionService } from 'src/app/services/auction.service';
import { User } from 'src/app/models/user';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-item-auction',
  templateUrl: './item-auction.component.html',
  styleUrls: ['./item-auction.component.scss'],
})
export class ItemAuctionComponent implements OnInit {
  @Input() public itemData = null;
  // @Output() public buyItem: EventEmitter<void> = new EventEmitter();
  public readonly symbol = environment.stableCoinSymbol;
  public readonly AvailableForSale = AvailableForSale;
  public loadingLastBids = false;
  public bids = [];
  isAddToCart: boolean = false;
  cartCollectionItems = [];
  public currentUser: User;
  isFistCall: boolean = true;

  public bidForm: FormGroup;

  public constructor(private cartService: CartService,private readonly authService: AuthenticationService, private readonly auctionService: AuctionService) {
    this.currentUser = this.authService.currentUserValue;
  }

  public get auctionStarted(): boolean {
    return moment(this.itemData.start_date).diff(moment(new Date())) <= 0;
  }

  public get auctionEnded(): boolean {
    return moment(this.itemData.end_date).diff(moment(new Date())) <= 0;
  }

  public get lastBidData(): number {
    return this.itemData?.lastbet?.data || this.bids[this.bids.length - 1]
  }

  public get lastPrice(): number {
    return this.itemData?.lastbet?.data.last_price || this.bids[this.bids.length - 1]?.last_price
  }

  public ngOnInit(): void {
    this.bids = this.itemData?.auction.data;
    this.bidForm = new FormGroup({
      collection_item_id: new FormControl(this.itemData.id, [Validators.required]),
      last_price: new FormControl(this.lastPrice || this.itemData.price || 0, [Validators.required, Validators.min((this.lastPrice + 0.01) || this.itemData.price)])
    });

  }

  wonAuction(){
    this.isFistCall = false;
      this.auctionService.wonAuction(this.itemData?.id).subscribe(res => {
        console.log(res);
        // this.addAuction(res.data);
      });
  }

  public makeOffer(): void {
    if (this.bidForm.invalid) {
      return;
    }
    this.auctionService.makeOffer(this.bidForm.value).subscribe(res => {
      this.addAuction(res.data);
      this.bidForm.get('last_price').setValidators([Validators.required, Validators.min(+this.bidForm.value.last_price + 0.01)])
      this.bidForm.get('last_price').setValue(null);
      this.bidForm.get('last_price').markAsUntouched()
    });
  }

  // public buyItemAction(): void {
  //   this.cartCollectionItems = this.cartService.cartItems;
  //   const index = this.cartCollectionItems.findIndex(item => item.id === this.itemData.id);
  //   if(index === -1){
  //     this.buyItem.emit()
  //   }else{
  //     this.isAddToCart = true;
  //   }

  // }

  private addAuction(auction): void {
    auction = {
      ...auction, buyer: { data: this.currentUser }
    };

    this.bids.push(auction);

    this.itemData = {
      ...this.itemData,
      lastbet: { data: auction },
    };
  }
}
