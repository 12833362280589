<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="input-group col-12 mb-5">
        <div class="form-outline col-5">
          <input id="search-focus" placeholder="Search by item name" [(ngModel)]="itemName" type="search" class="form-control" />
        </div>
        <button type="button" class="btn btn-primary col-1" (click)="getSellerItemHistory(currentPage, itemName)">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <div class="col-lg-9 mb-4 mt-4">
        <h1 class="bold text-blue text-center">Seller Sold Out Items History</h1>
      </div>
      <div class="col-lg-12 mb-4 mt-4">
        <div class="container-image">
          <ng-container>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Date</th>
                  <th scope="col">Buyer name</th>
                  <th scope="col">Sale Total</th>
                  <th scope="col">Collecton Name</th>
                  <th scope="col">Item Name</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of orders; let i = index">
                  <tr (click)="item.showDetail = !item.showDetail">
                    <th scope="row">{{ saleItemNumber + i+1 }}</th>
                    <td>{{item?.purchased_at |  date: "hh:mm:ss a dd/MM/YYYY"}}</td>
                    <td>{{item?.user?.name}}</td>
                    <td>{{item?.total}}</td>
                    <td>{{item?.order_details[0]?.collectionitem?.collection?.name}}</td>
                    <td>{{ item?.order_details[0]?.collectionitem?.name }}</td>
                  </tr>

                    <tr *ngIf="item?.showDetail" class="bg-secondary text-white">
                      <td><label>reference_id: <span>{{item.ref_id}}</span></label></td>
                      <td><label>Buyer name: <span>{{item?.user?.name}}</span></label></td>
                      <td><label>Buyer email: <span>{{item?.user?.email}}</span></label></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                      <tr *ngIf="item?.showDetail" class="bg-secondary text-white">
                        <td></td>
                      <td ><label>seller name: <span>{{item?.seller?.name}}</span></label></td>
                      <td><label>seller email: <span>{{item?.seller?.email}}</span></label></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr *ngIf="item?.showDetail" class="bg-secondary text-white">
                      <td></td>
                      <td></td>
                      <td>
                        <div class="field" *ngIf="item.status === 1">
                          <label>status: <span>purchased</span></label>
                        </div>
                        <div class="amount-field">
                          <label>discount: <span>{{item.discount}}</span></label>
                        </div>
                        <div class="amount-field">
                          <label>subtotal: <span>{{item.subtotal}}</span></label>
                        </div>
                        <div class="amount-field">
                          <label>tax: <span>{{item.tax + ' '}} (10%) </span></label>
                        </div>
                        <div class="amount-field">
                          <label>total: <span>{{item.total}}</span></label>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>

                    </tr>


                </ng-container>

              </tbody>
            </table>

          </ng-container>
        </div>
        <div class="d-flex justify-content-between p-2" *ngIf="ordersDataSize >= 10">
          <ngb-pagination class="mx-auto" [collectionSize]="ordersDataSize" [pageSize]="ordersPerPage"
            [(page)]="currentPage" (pageChange)="onPageChange(currentPage)"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
