import { Component } from '@angular/core';

@Component({
  selector: 'app-digi-team',
  templateUrl: './digi-team.component.html',
  styleUrls: ['./digi-team.component.scss'],
})
export class AboutDigiTeamComponent {
  
}
