import { Component } from '@angular/core';

@Component({
  selector: 'app-digi-duel',
  templateUrl: './digi-duel.component.html',
  styleUrls: ['./digi-duel.component.scss'],
})
export class AboutDigiDuelComponent {
  
}
