import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/Authentication.service';
import { ConfirmedValidator } from '../register/confirmed.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string = 'login';
  private token: string;
  public passwordConfirmError = false;
  fieldTextType: boolean = false;
  passwordFieldTextType: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
   }

   // convenience getter for easy access to form fields
  get f() {
    return this.changePasswordForm.controls;
  }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required,  Validators.minLength(6)],
      confirm_password: ['', Validators.required,  Validators.minLength(0)],
      token: ['', Validators.required]
    }, {
      validator: ConfirmedValidator('password', 'confirm_password')
    });

    this.route.params.subscribe(queryParams => {
      this.token = queryParams.token;
    });
  }


  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();
    this.changePasswordForm.get('token').setValue(this.token);

    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      return;
    }

    if(this.f.password.value != this.f.confirm_password.value) {
      this.passwordConfirmError = true
      return
    }

    this.passwordConfirmError = false;
    this.loading = true;
    this.authenticationService
      .changePassword(this.changePasswordForm.value)
      .subscribe(
        (data) => {
          this.alertService.success('Password change successfully');
          this.loading = false;
          this.router.navigate([this.returnUrl]);
        },
        (error) => {
          this.alertService.error(error.message);
          this.loading = false;
        }
      );
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  passwordToggleFieldTextType() {
    this.passwordFieldTextType = !this.passwordFieldTextType;
  }

}
