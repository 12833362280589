<div class="container page-top">
  <div class="row">
    <div class="col-8 col-lg-10 p-t-10 p-b-6">
      <div class="section-title">
        <span class="icon user"></span>
        <span>Road Map</span>
      </div>
    </div>
    <div class="col-4 col-lg-2 p-t-10 p-b-6">
      <a class="btn btn-primary" href="/assets/pdf/DigibleRoadMap.pdf">View PDF</a></div>
    <div class="solid mt-4 mb-4"></div>
  </div>

  <script src="https://cpwebassets.codepen.io/assets/common/stopExecutionOnTimeout-1b93190375e9ccc259df3a57c1abc0e64599724ae30d7ea4c6877eb615f89387.js"></script>
  <script
    src="https://cdpn.io/cp/internal/boomboom/pen.js?key=pen.js-745dfde2-6f26-5466-657f-a5aae7220bc4"
    crossorigin=""
  ></script>

  <div class="row justify-center p-b-20">
    <div class="col-12">
      <div class="timeline">
        <div class="timeline--entry">
          <div class="timeline--entry__title">Q1 2022</div>
          <div class="timeline--entry__detail">
            <ul>
              <li>More DigiDrops&trade;/DigiWax&trade;</li>
              <li>Crypto/NFT Partnerships</li>
              <li>UI/UX Improvements</li>
              <li>DEX/CEX Listing</li>
              <li>Web 2.0 Integration</li>
              <li>Digible Affiliate Programme</li>
              <li>Use $DIGI to buy and sell NFTs</li>
              <li>Steve Aoki Cardhouse Drop</li>
              <li>Digible PR campaign</li>
              <li>BSc Chain <></li>
            </ul>
          </div>
        </div>
        <div class="timeline--entry">
          <div class="timeline--entry__title">Q2 2022</div>
          <div class="timeline--entry__detail">
            <ul>
              <li>DigiGames&trade;</li>
              <li>NFT Exchange</li>
              <li>Trading Card Metaverse</li>
              <li>TCG Partnerships</li>
              <li>TCG Pricing Prcale Provider</li>
              <li>DigiLoot&trade; Promotions</li>
              <li>Card Seller Partnerships</li>
              <li>Solana <></li>
            </ul>
          </div>
        </div>
        <div class="timeline--entry">
          <div class="timeline--entry__title">Q3 2022</div>
          <div class="timeline--entry__detail">
            <ul>
              <li>Additional DEX listings</li>
              <li>Additional CEX listings</li>
              <li>Market making</li>
              <li>Partnerships & Licensing</li>
              <li>Curated collections</li>
              <li>More collectible types</li>
            </ul>
          </div>
        </div>
        <div class="timeline--entry">
          <div class="timeline--entry__title">Q4 2022</div>
          <div class="timeline--entry__detail">
            <ul>
              <li>$DIGI DAO</li>
              <li>Mobile App</li>
              <li>Market making</li>
              <li>Platform/ UI Improvements</li>
              <li>DigiGames&trade; improvements</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
