<a class="c-card" (click)="onItemCardOffchainClicked()">
  <div class="d-flex flex-row items-center p-2">
    <div class="c-collection__circle">
        <img
        *ngIf="data && data.collection.logo_path"
        width="100%"
        height="100%"
        [src]="data.collection.logo_path"
        alt=""
      />
      <img
        *ngIf="data && !data.collection.logo_path"
        width="100%"
        height="100%"
        src="/assets/images/home/home-1.png"
        alt=""
      />
    </div>
    <span *ngIf="data.collection.name" class="c-card__info">{{
      data.collection.name
    }}</span>
  </div>
  <figure class="c-card__figure">
    <img class="c-card__figure__video" [src]="data.image_path" alt="image" />
  </figure>
  <div class="c-card__body">
    <div class="c-card__top">
      <h1 class="c-card__title">
        <span>{{ data.name }}</span>
      </h1>
       <div class="c-card__price" *ngIf="data.price">
        <span class="mr-2">${{ data.price }} </span>
      </div>
    </div>
    <div class="c-card__description">
      <p *ngIf="data && data.description">
        {{ data.description | truncate: [120, "..."] }}
      </p>
    </div>
    <div *ngIf="data && !data.description" class="c-card__description">
      <p>No description has been created for this NFT.</p>
    </div>
  </div>
  <!-- <div class="c-card__footer">
    <div class="c-card__footer-inner">
      <div *ngIf="physical" class="d-flex flex-row">
        <span class="c-card__safe icon safe mr-2"></span>
        <span class="c-card__info">Physical Item</span>
      </div>
      <div *ngIf="!physical" class="d-flex flex-row">
        <span class="c-card__safe icon nft mr-2"></span>
        <span class="c-card__info">NFT ONLY</span>
      </div>
      <div *ngIf="isInEth" class="d-flex flex-row">
        <span class="c-card__safe icon ethereum mr-2"></span>
        <span class="c-card__info">ERC-20</span>
      </div>
      <div *ngIf="isInMatic" class="d-flex flex-row">
        <span class="c-card__safe icon polygon mr-2"></span>
        <span class="c-card__info">MATIC</span>
      </div>
    </div>
  </div> -->
</a>
