<div class="countdown">
  <ng-container *ngFor="let time of display; let i = index">
    <div *ngIf="displayNumbers[i] > 0" class="measurements {{time}}">
      <p class="measurements-number">
        {{ (showZero && displayNumbers[i] < 10) ? '0' + displayNumbers[i].trim() : displayNumbers[i]}} </p>
          <span *ngIf="display[i+1]" class="divider"> {{divider}} </span>
          <p class="measurements-text">
            {{time}}
          </p>
    </div>
  </ng-container>
</div>