import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FavouriteService {

  constructor(private http: HttpClient) {
  }

  getAll(param = '', itemName: string = ''): Observable<any>  {
    const params = new HttpParams().set('search', `${itemName}`);
    return this.http.get(`${environment.systemLabsAPi}/my_favorites`+param, { params} );
  }


}
