import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommentsService } from 'src/app/services/comments.service';
import { Comment, CommentParam, CommentType } from 'src/app/types/comments.type';

@Component({
  selector: 'app-comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.scss']
})
export class CommentBoxComponent implements OnInit {

  @Input() id: string;
  @Input() type: CommentType = CommentType.Collection;
  @Output() commentAdded: EventEmitter<void> = new EventEmitter();

  public comments: Array<Comment> = [];
  public commentForm: FormGroup;

  constructor(private commentsService: CommentsService) { }

  public ngOnInit(): void {
    this.initForm();
    this.getComments();
  }

  public addComment(): void {
    if (this.commentForm.invalid) {
      return;
    }

    this.commentsService.postComment(this.commentForm.value).subscribe(res => {
      this.commentAdded.emit();
      this.commentForm.get('comment').reset(null);
    });
  }

  private initForm(): void {
    this.commentForm = new FormGroup({
      [CommentParam[this.type]]: new FormControl(this.id, Validators.required),
      comment: new FormControl('', [Validators.required]),
    });
  }

  private getComments(): void {
    this.commentsService.getComments(this.id, this.type).subscribe(comments => {
      this.comments = comments;
    })
  }

}
