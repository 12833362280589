<div class="page-top">
  <div class="container p-b-40" *ngIf="isVerifiedEmail; else notVerified">
    <div class="row pur">
      <div class="col-9 p-t-10 p-b-6">
        <span class="section-title">Buy $DIGI</span>
      </div>
      <div class="solid mt-4 mb-4 mb-4"></div>
    </div>

    <div class="content-purchase">
      <div class="left-block-purchase">
        <div class="left-first-block">
          <div class="lfb">
            <img
              src="../../../assets/images/uniswap.png"
              class="swapLogo mb-4 block"
            />
            <a
              href="https://app.uniswap.org/#/swap?outputCurrency=0x3cbf23c081faa5419810ce0f6bc1ecb73006d848"
              target="_blank"
              class="btn btn btn-primary"
            >
              <span>Buy through Uniswap</span>
            </a>
          </div>
          <div class="lfb">
            <img
              src="../../../assets/images/quickswap.png"
              class="swapLogo mb-4 block"
            />
            <a
              href="https://quickswap.exchange/#/swap?inputCurrency=0x4d8181f051e617642e233be09cea71cc3308ffd4"
              target="_blank"
              class="btn btn-primary"
            >
              <span>Buy through Quickswap</span>
            </a>
          </div>
          <div class="lfb">
            <img
              src="../../../assets/images/debit-card.png"
              class="swapLogo mb-4 block"
            />
            <span class="btn btn-primary">
              <span>Debit/Credit COMING SOON</span>
            </span>
          </div>
        </div>
        <!--   <div class="left-second-block lfb">
          <span class="credit-card-title">Debit/credit card</span>
          <div>
            <div class="mt-4">
              <h5 class="bold text-center">COMING SOON!</h5>
            </div>
            <!--  <div class="credit-card-circle-block"> class="credit-card-block"
              <div class="purple-circle"></div>
              <a href="#" class="btn-cr-car"><b>6,000</b> Sellers Bundle <b>▸</b></a>
              <span class="cr-cr-dsc">3k erc-20 $DIGI, 3k matic $DIGI</span>
            </div>
            <div class="credit-card-circle-block">
              <div class="yellow-circle"></div>
              <a href="#" class="btn-cr-car"><b>3,000</b> Sellers Bundle <b>▸</b></a>
              <span class="cr-cr-dsc">choice of erc-20 or matic</span>
            </div>
            <div class="credit-card-circle-block">
              <div class="green-circle"></div>
              <a href="#" class="btn-cr-car">Custom Amount <b>▸</b></a>
              <span class="cr-cr-dsc" style="visibility: hidden;">choice</span>
            </div> --
          </div>
          <!-- <div class="cr-cr-lower-block">
            <div class="low-desc-bl">
              <div class="imp-txt">Important</div>
              <div>Only holders of 3,000 or more can list items for fixed price sale on ERC-20 and 3,000 matic $DIGI for auction</div>
            </div>
          </div> --
        </div> --
      </div>
     <!--  <div class="right-block-purchase lfb">
        <div class="right-block-title">Rankings</div>
        <div class="row" *ngIf="!searchReady">
          <div class="col">
            <div class="mx-auto text-center mt-4 mb-4">
              <app-loading></app-loading>
            </div>
          </div>
        </div>
        <div *ngIf="searchReady">
          <div
            class="rankings-content-block"
            *ngFor="let usr of listUsers; let i = index"
          >
            <div class="ranking-left-block" *ngIf="i < 10">
              <div class="ranking-block-top-number">{{ i + 1 }}</div>
              <div class="ranking-block-top-img">
                <img *ngIf="usr.picture" src="{{ usr.picture }}" />
              </div>
              <div class="ranking-block-top-name">{{ usr.username }}</div>
              <div class="ranking-block-top-address" alt="{{ usr.id }}">
                {{ usr.id | slice: 0:6 }}...{{ usr.id | slice: 36:64 }}
              </div>
              <!--  <div class="ranking-block-top-balance">{{ usr.digible }} <span class="valuta">$DIGI</span></div> --
              <div class="ranking-block-top-network">
                <img src="../../../assets/images/matic.png" />
              </div>
            </div>
            <div class="ranking-right-block" *ngIf="i < 10">
              <a href="/profile/{{ usr.id }}" class="button-collection-ranking"
                >Collection ▸</a
              >
            </div>
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </div>
  <ng-template #notVerified>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="mx-auto text-center">
            <h1>Please verify your email address before continuing, <b>it may be in your spam/junk folder.</b></h1>
          </div>
        </div>
      </div>
    </div>
 </ng-template>
</div>
