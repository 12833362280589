import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommentService {

  constructor(private http: HttpClient) {
  }

  getCommentForApproval(page = 1, perItemPage = 10): Observable<any>  {
    const params = new HttpParams()
    .set('page', `${page}`)
  // .set('limit', `${itemsPerPage}`);
    return this.http.get(`${environment.systemLabsAPi}/admin/comments/pending`, {params});
  }

  changeCommentStatus(id, data): Observable<any> {
    return this.http.put(`${environment.systemLabsAPi}/admin/comments/action/${id}?_method=PUT`, data);
  }


}
