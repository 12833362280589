<div class="wraper">
    <div class="arrowup"></div>
    <div class="arrowdown"></div>
    <div class="window">
        <ul class="list">

        </ul>
        <ul class="list">
            <li class="box green">GREEN</li>
            <li class="box red">RED</li>
            <li class="box green">GREEN</li>
            <li class="box red">RED</li>
            <li class="box green">GREEN</li>
            <li class="box red">RED</li>
        </ul>
    </div>
</div>
<p style="text-align: center">
    <div class="win">
    <ul>
    </ul>
</div>