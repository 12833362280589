import { Component, OnInit } from '@angular/core';
import { CollectionsService } from 'src/app/services/collections.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-collection-detail',
  templateUrl: './collection-detail.component.html',
  styleUrls: ['./collection-detail.component.scss']
})
export class CollectionDetailComponent implements OnInit {

  public id: number;
  public collection;
  isCollectionSold: boolean = false;
public profile;

returnUrl: string = 'admin';

  constructor(
    private collectionsService: CollectionsService,
    private readonly route: ActivatedRoute,
    private adminService: AdminService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(queryParams => {
      if(queryParams.status){
        this.isCollectionSold = true;
      }
      this.id = queryParams.id;
      this.loadData();
    });
  }

  loadData() {
    this.collectionsService.getCollectionById(this.id).then((res) => {
      this.collection = res;
    });

  }

  changeCollectionStatus(collection:number, status) {
    // TODO: unwrap promise and show success
    this.adminService.approveCollection(collection, status);
    this.router.navigate([this.returnUrl]);
    this.alertService.success(status+' successfully', true);
  }

}
