import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CartService {
  noOfAddedItems = new Subject<number>();
  itemsInCart = new Subject<any>();
  cartItems = [];
  itemCount = 0;
  collectionItemCount = 0;
  constructor(private http: HttpClient) {
    const items = JSON.parse(sessionStorage.getItem('cart'));
    if (items?.length) {
      this.cartItems = items;
      this.itemCount = items.length;
      this.noOfAddedItems.next(this.itemCount);
      this.itemsInCart.next(this.cartItems);
    }
  }

  addToCart(item: any) {
    if(this.cartItems.length === 0) {
      item.count = 1;
      this.cartItems.push(item);
    } else {
      let isItemExistInArray = this.cartItems.some(arrItem => arrItem.id === item.id);
      if (isItemExistInArray) {
        this.cartItems.forEach(element => {
          if(element.id === item.id && element.count > 0) {
            element.count += Number(1);
          }
        });
      } else {
        item.count = 1;
        this.cartItems.push(item);
      }

    }


    // this.cartItems.push(item);
    this.collectionItemCount = this.cartItems.filter(count => count.id === item.id).length;
    this.itemCount = this.cartItems.length;
    this.itemsInCart.next(this.cartItems);
    this.noOfAddedItems.next(this.itemCount);
    sessionStorage.setItem('cart', JSON.stringify(this.cartItems));
  }

  toResetCart() {

    for (var i = this.cartItems.length - 1; i > -1; i--) {
      this.itemCount -= Number(1);
      this.cartItems.splice(i, 1);
      }
      this.itemCount = this.cartItems.length;
      this.itemsInCart.next(this.cartItems);
      this.noOfAddedItems.next(this.itemCount);
      sessionStorage.setItem('cart', JSON.stringify(this.cartItems));
  }

  removeFromCart(item: any) {

    let isItemExistInArray = this.cartItems.some(arrItem => arrItem.id === item.id);
      if (isItemExistInArray) {
        this.cartItems.forEach(element => {
          if(element.id === item.id && element.count != 1) {
            element.count -= Number(1);
          } else {
            const index = this.cartItems.findIndex(
                      (cartItem) => item?.id === cartItem?.id
                    );
                    if (index > -1) {
                      this.cartItems.splice(index, 1);
                    }
          }
        });
      }

    this.itemCount = this.cartItems.length;
    this.itemsInCart.next(this.cartItems);
    this.noOfAddedItems.next(this.itemCount);
    sessionStorage.setItem('cart', JSON.stringify(this.cartItems));
  }

  getCartItems(): any {
    return this.cartItems;
  }

  getCartNoOfItems(): number {
    return this.itemCount;
  }

  getCartTotal(): number {
    let total =  0;
    this.cartItems.forEach(item => {
      if(item.price) {
        total += item.price;
      }
    })
    return total;
  }

  getCartItemTotal(item: any): number {

    let total =  0;
    this.cartItems.forEach(cartItem => {
      if(cartItem.id === item.id) {
        total  = item.price * cartItem.count;
      }
    })
    return total;
  }

  getCartItemTotalCount(item: any): number {

    let total =  0;
    this.cartItems.forEach(cartItem => {
      if(cartItem.id === item.id) {
        total = cartItem.count
      }
    })
    return total;
  }


  placeUserOrder(currency: string, secretKey: string, items: any): Observable<any> {
    const itemData = {
      currency: currency,
      secretKey: secretKey,
      items: items,
    }
    return this.http.post(`${environment.systemLabsAPi}/orders/checkout`, itemData);
  }

  resetCart() :void {
    this.cartItems = [];
    this.itemCount = this.cartItems.length;
    this.itemsInCart.next(this.cartItems);
    this.noOfAddedItems.next(this.itemCount);
    sessionStorage.setItem('cart', JSON.stringify(this.cartItems));
  }
}
