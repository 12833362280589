<div class="container page-top" *ngIf="isVerifiedEmail; else notVerified">
  <div class="row c-page-title">
    <div class="col-12 p-t-10 p-b-6">
      <div class="section-title">
        <span class="icon user"></span>
        <span>Become A Verified Seller</span>
      </div>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>
  <div class="row justify-center p-b-20">
    <div class="col-xl-7 col-lg-9 col-sm-12">
      <form #sellerForm="ngForm" appIdentityRevealed class="c-card">
        <div class="text-center">
          <h1 class="c-card__title">Digible Verified Seller Registration</h1>
          <p class="c-card__paragraph bold text-danger">
            ‼️THIS IS REQUIRED TO BE HAVE VERIFIED SELLER STATUS ‼️
          </p>
        </div>
        <div [hidden]="sellerForm.submitted">
          <div class="mb-2 mt-2">
            <p class="bold">Registration Process:</p>
            <ol>
              <li>
                <span class="mr-2">📥 </span> Submit the form with the required
                information.
              </li>
              <li>
                <span class="mr-2">🕔</span> Await confirmation email from
                admin@digible.io that your profile has been successfully
                activated.
              </li>
              <li>
                <span class="mr-2">✅</span> Connect with the registered
                MetaMask Wallet Address and navigate to your Profile to see your
                custom profile name and social links. You should also be able to
                see the “Create” button at the top of the platform pages.
              </li>
            </ol>
          </div>
          <div class="c-card__body">
            <div class="row">
              <div class="form-group col-md-6">
                <div
                  class="cross-validation"
                  [class.cross-validation-error]="
                    sellerForm.errors?.identityRevealed &&
                    (sellerForm.touched || sellerForm.dirty)
                  "
                >
                  <div class="form-group">
                    <label for="username">Username</label>
                    <div
                      *ngIf="
                        username.invalid && (username.dirty || username.touched)
                      "
                    >
                      <div
                        *ngIf="username.errors?.required"
                        class="text-danger"
                      >
                        Name is required.
                      </div>
                      <div
                        *ngIf="username.errors?.minlength"
                        class="text-danger"
                      >
                        Name must be at least 4 characters long.
                      </div>
                      <div
                        *ngIf="username.errors?.forbiddenName"
                        class="text-danger"
                      >
                        Name cannot be Bob.
                      </div>
                    </div>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      class="form-control"
                      required
                      minlength="4"
                      appForbiddenName="bob"
                      [(ngModel)]="creator.username"
                      #username="ngModel"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="email" class="block">Email</label>
                <div
                  *ngIf="email.invalid && (email.dirty || email.touched)"
                >
                  <div *ngIf="username.errors?.required" class="text-danger">
                    Email is required.
                  </div>
                </div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  class="form-control"
                  required
                  [(ngModel)]="creator.email"
                  #email="ngModel"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="profile_twitter" class="block">Twitter</label>
                <input
                  [(ngModel)]="creator.twitter"
                  name="profile_twitter"
                  id="profile_twitter"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="profile_instagram" class="block">Instagram</label>
                <input
                  [(ngModel)]="creator.instagram"
                  name="profile_instagram"
                  id="profile_instagram"
                  cols="30"
                  rows="10"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="profile_twitch" class="block">Twitch</label>
                <input
                  [(ngModel)]="creator.twitch"
                  name="profile_twitch"
                  id="profile_twitch"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="profile_tiktok" class="block">TikTok</label>
                <input
                  [(ngModel)]="creator.tiktok"
                  name="profile_tiktok"
                  id="profile_tiktok"
                />
              </div>
              <div class="form-group col-md-12">
                <label for="description">Description</label>
                <div
                  *ngIf="email.invalid && (email.dirty || email.touched)"
                >
                  <div *ngIf="username.errors?.required" class="text-danger">
                    A Description is required.
                  </div>
                </div>
                <textarea
                  class="form-control"
                  #description="ngModel"
                  required
                  [(ngModel)]="creator.description"
                  name="description"
                  id="description"
                ></textarea>
              </div>
              <div class="form-group col-12">
                <p>
                  Digible is always looking for partners to help expand our
                  network's capabilities. As an operator you will receive: a)
                  10% of all net digable operational earnings from
                  sales/auctions/custody for physical cards processed by the
                  digisafe location; b) Labels for fully paid shipping with
                  insurance paid by customer; c) “Checkmark” on platform to
                  indicate authorized seller & digisafe operator. We will
                  contact you further about this opportunity if there is
                  sufficient availability / interest in your area.
                </p>
              </div>
              <div class="col-12">
                <div class="radios form-group">
                  <input
                    [(ngModel)]="creator.digisafe_1"
                    name="profile_digisafe"
                    id="profile_digisafe1"
                    type="checkbox"
                    class="mr-2"
                  />
                  <label for="profile_tiktok" class="block"
                    >I have a physical secure location - You will have custodial
                    responsibilities to store items in secure, confidential,
                    environmentally controlled location(s) for physical
                    collectibles.</label
                  >
                </div>
                <div class="form-group radios">
                  <input
                    [(ngModel)]="creator.digisafe_2"
                    name="profile_digisafe"
                    id="profile_digisafe2"
                    type="checkbox"
                    class="mr-2"
                  />
                  <label for="profile_tiktok" class="block"
                    >I have/ can get insurance - Digisafe inventory must be
                    insured. You will be asked later to provide copy of binder
                    and all riders.</label
                  >
                </div>
                <div class="form-group radios">
                  <input
                    [(ngModel)]="creator.digisafe_3"
                    name="profile_digisafe"
                    id="profile_digisafe3"
                    type="checkbox"
                    class="mr-2"
                  />
                  <label for="profile_tiktok" class="block"
                    >I have researched all the required certificates and
                    licenses required by law if any for the jurisdictions I plan
                    to operate in as a collectibles storage/processing
                    facility.</label
                  >
                </div>
                <div class="form-group radios">
                  <input
                    [(ngModel)]="creator.digisafe_4"
                    name="profile_digisafe"
                    id="profile_digisafe4"
                    type="checkbox"
                    class="mr-2"
                  />
                  <label for="profile_tiktok" class="block"
                    >I guarantee I will be able to process shipping/processing
                    requests within 48 hours from the time of request.</label
                  >
                </div>
                <div class="form-group radios">
                  <input
                    [(ngModel)]="creator.digisafe_5"
                    name="profile_digisafe"
                    id="profile_digisafe5"
                    type="checkbox"
                    class="mr-2"
                  />
                  <label for="profile_tiktok" class="block"
                    >I guarantee that I will visually authenticate all items to
                    be processed by my facility + verify authentication by
                    scanning barcode. I will do my utmost best to help ensure
                    that fraudulent / fake items do not get added to the
                    platform.</label
                  >
                </div>
                <div class="form-group radios">
                  <input
                    [(ngModel)]="creator.digisafe_6"
                    name="profile_digisafe"
                    id="profile_digisafe6"
                    type="checkbox"
                    class="mr-2"
                  />
                  <label for="profile_tiktok" class="block"
                    >I understand checking these boxes does not mean I will be
                    guaranteed an opportunity to participate as a Digisafe
                    Operator.</label
                  >
                </div>
                <div class="form-group radios">
                  <input
                    [(ngModel)]="creator.digisafe_7"
                    name="profile_digisafe"
                    id="profile_digisafe7"
                    type="checkbox"
                    class="mr-2"
                  />
                  <label for="profile_tiktok" class="block"
                    >I will provide my location for my proposed Digisafe
                    location (city, state/region, country) in the other text box
                    below.</label
                  >
                </div>
                <div class="form-group radios d-flex flex-column">
                  <label for="profile_tiktok" class="block">Other</label>
                  <input
                    [(ngModel)]="creator.other"
                    name="profile_other"
                    id="profile_other"
                    type="text"
                    class="mr-2 w-100"
                  />
                </div>
              </div>
              <div class="col-12 form-group ">
                <div class="radios flex-column">
                  <label for="profile_tiktok" class="block bold"
                    >Apply to become a Digisafe Operator</label
                  >
                  <p>
                    Please describe your facility (e.g. hobby shop, dealer,
                    museum/exhibition, custodian/vault/storage operator, etc)
                  </p>
                  <textarea
                    [(ngModel)]="creator.operator"
                    name="profile_digisafe"
                    id="profile_operator"
                    type="text"
                    class="mr-2 w-100"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <button
                  type="submit"
                  [disabled]="sellerForm.invalid"
                  class="btn btn-primary w-100"
                  (click)="SubmitForm()"
                >
                  Submit
                </button>
                <!--  -->
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #notVerified>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="mx-auto text-center">
          <h1 class="mt-5">Please verify your email address before continuing, <b>it may be in your spam/junk folder.</b></h1>
        </div>
      </div>
    </div>
  </div>
  
</ng-template>