import { Component } from '@angular/core';

@Component({
  selector: 'app-digi-grade',
  templateUrl: './digi-grade.component.html',
  styleUrls: ['./digi-grade.component.scss'],
})
export class AboutDigiGradeComponent {
  
}
