<div class="container page-top">
  <div class="row c-page-title">
    <div class="col-12 p-t-10 p-b-6">
      <div class="section-title">
        <span class="icon safe"></span>
        <span>Digi Safe</span>
      </div>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>

  <div class="row justify-center p-b-20">
    <div class="col-xl-6 col-lg-6 col-md-10 col-sm-12">
      <span class="c-about__description">
        DigiSafe is where your valuable physical card will be stored if
        you chose for Digible to store it for you. <br /><br />
        All physical cards will be stored at DigiSafe securely. You will
        receive a digital version of your card which will be in a form of
        an NFT, of which you will be able to track details of your
        physical card at any given time.
      </span>
    </div>
  </div>
</div>
