import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Category } from '../../models/category';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  getAll(currentPage = 1, itemsPerPage = 10, categoryName?: string): Observable<any> {
    const params = new HttpParams()
      .set('page', `${currentPage}`)
      .set('per_page', `${itemsPerPage}`)
      .append('search', categoryName);
    return this.http.get(`${environment.systemLabsAPi}/collection-item-types`, {
      params
    })
      .pipe(
        map(({ data: {
          data, ...res
        } }: any) => ({
          pagination: res,
          data: data.map(cat => (new Category(cat))),
        }))
      )
  }

  get(id): Observable<any> {
    return this.http.get(`${environment.systemLabsAPi}/admin/collection-item-types/${id}`);
  }

  create(data): Observable<any> {
    return this.http.post(`${environment.systemLabsAPi}/admin/collection-item-types`, data);
  }

  update(id, data): Observable<any> {
    return this.http.put(`${environment.systemLabsAPi}/admin/collection-item-types/${id}`, data);
  }

  delete(id): Observable<any> {
    return this.http.delete(`${environment.systemLabsAPi}/admin/collection-item-types/${id}`);
  }


}
