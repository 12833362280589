<div class="collection-detail">
    <div class="d-flex container-detail">
        <div class="container-image">

            <ng-container>
                <span class="status-tab" *ngIf="!isCollectionSold">
                    <button
                      class="btn btn-primary ml-auto m-2"
                      (click)="changeCollectionStatus(collection.id, 'approved')"
                    >
                      Approve
                    </button>

                    <button
                      class="btn btn-danger ml-auto m-2"
                      (click)="changeCollectionStatus(collection.id, 'rejected')"
                    >
                      Reject
                    </button>
                  </span>
            </ng-container>

            <ng-container>

                <span
      *ngIf="collection && collection?.logo_path"
      class="c-profile__avatar"
      [ngStyle]="{ 'background-image': 'url(' + collection?.logo_path + ')' }"
    ></span>


            </ng-container>

            <ng-container>
                <div class="c-profile__social">
          <div class="social" *ngIf="collection">
            <a
              [href]="collection?.twitter"
              *ngIf="collection?.twitter"
              target="_blank"
              ><i class="icon fab fa-twitter"></i
            ></a>

            <a
              [href]="collection.twitch"
              *ngIf="collection.twitch"
              target="_blank"
              ><i class="icon fab fa-twitch"></i
            ></a>

            <a
              [href]="collection.tiktok"
              *ngIf="collection.tiktok"
              target="_blank"
              ><i class="icon fab fa-tiktok"></i
            ></a>

            <a
              [href]="collection.instagram"
              *ngIf="collection.instagram"
              target="_blank"
              ><i class="icon fab fa-instagram"></i
            ></a>

            <a [href]="collection.website" *ngIf="collection.website" target="_blank"
              ><i class="icon fa fa-desktop"></i
            ></a>
          </div>
        </div>
            </ng-container>
        </div>

        <div class="container" *ngIf="collection">
            <div class="field">
              <label>Collection Name: <span>{{collection.name}}</span></label>
            </div>
            <div class="field">
                <label>Description: <span>{{collection.description}}</span></label>
            </div>
            <div class="field">
                <label>Categories: <span>{{collection.categories}}</span></label>
            </div>
            <div class="field">
                <label>User Name: <span>{{collection.user.data.name}}</span></label>
            </div>
            <div class="field">
                <label>User Email: <span>{{collection.user.data.email}}</span></label>
            </div>
          </div>

    </div>






















</div>
