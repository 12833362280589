import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from '../../services/Authentication.service';
import { OrderService } from 'src/app/services/order/order.service'


@Component({
  selector: 'app-user-order-detail',
  templateUrl: './user-order-detail.component.html',
  styleUrls: ['./user-order-detail.component.scss']
})
export class UserOrderDetailComponent implements OnInit {

  id: number;
  role: string;
  url: string;
  isVerifiedEmail: boolean = false;
  currentUser: any;
  orders = [];
  ordersDataSize = 0
  currentPage = 1;
  ordersPerPage = 10;
  oderNumber: number = 0;
  itemName: string = '';

  constructor(
    private readonly route: ActivatedRoute,
    private orderService: OrderService,
    private authenticationService: AuthenticationService,
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
  }

  ngOnInit(): void {
    this.currentUser = this.currentUser.data;
    this.isVerifiedEmail = this.currentUser.verified;
    this.route.params.subscribe((queryParams) => {
      this.id = queryParams.id;
      this.role = queryParams.role;
    });
    this.getAllOrders(this.currentPage);

  }

  getAllOrders(page: number, itemName: string = '') {
    this.orderService.getAll(this.id, page, itemName).subscribe(
      response => {
        this.orders = response.data;
        this.orders = this.orders.sort((a, b) => b.id - a.id);
        this.ordersDataSize = response.total;
        this.currentPage = response.current_page;
        this.ordersPerPage = response.per_page;
        this.oderNumber = (this.currentPage - 1) * this.ordersPerPage;
      },
      err => {

      }
    );

  }
  public onPageChange(pageNum: number): void {
    this.getAllOrders(pageNum);
  }
}
