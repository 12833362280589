<ng-container *ngIf="auctionStarted">
  <div class="card-body">
    <div class="row p-b-10">
      <ng-container *ngIf="!lastBidData">
        <div class="col-4 sub-title">
          Starter bid:
        </div>
        <div class="col-6 f-s-20">
          <div class="c-details__price d-flex align-items-center">
            <span class="mr-2">${{ itemData?.price || 0 }} </span>
            <span *ngIf="symbol == 'USDT'" class="icon usdt"></span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="lastBidData">
        <div class="col-4 sub-title">
          Highest bid:
        </div>
        <div class="col-6 f-s-20">
          <div class="c-details__price d-flex align-items-center">
            <span class="mr-2">${{ lastPrice }} </span>
            <span *ngIf="symbol == 'USDT'" class="icon usdt"></span>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="auctionEnded && isFistCall">
      {{wonAuction()}}
    </div>

    <div class="row p-b-10" *ngIf="auctionEnded">
      <div class="col-4 sub-title">Winner:</div>
      <div class="col-6" *ngIf="!lastBidData">None</div>
      <div class="col-6" *ngIf="currentUser && lastBidData?.buyer_id === currentUser?.id">
        <div class="icon-creator"></div>
        <a>
          <span>
            YOU ARE THE HIGHEST BIDDER
          </span>
        </a>
      </div>
      <div class="col-6" *ngIf="lastBidData && lastBidData?.buyer_id !== currentUser?.id">
        <div class="icon-creator"></div>
        <a>
          <span>{{ lastBidData?.buyer?.data?.name }}</span>
        </a>
      </div>
    </div>
    <div class="row p-b-10">
      <div *ngIf="!auctionEnded" class="col-4 sub-title">End Date (Local): </div>
      <div class="col-6">
        <date-countdown [showZeros]="true" [end]="itemData?.end_date"
          units="Year | Month | Days | Hours | Minutes | Seconds">
        </date-countdown>
      </div>
    </div>
    <div class="row">
      <div class="col-12 sub-title">Last Bids:</div>
      <div class="col-12" *ngIf="loadingLastBids">
        <div class="mx-auto text-center">
          <app-loading></app-loading>
        </div>
      </div>
      <div class="col-12" *ngFor="let bid of bids">
        <div class="row">
          <div class="col-md-4">
            <div class="c-details__price d-flex align-items-center">
              <span class="mr-2">${{ bid?.last_price }} </span>
              <span *ngIf="symbol == 'USDT'" class="icon usdt"></span>
            </div>
          </div>
          <div class="col-lg-4">
            <a>
              {{ bid?.buyer?.data?.name}}
            </a>
          </div>
          <div class="col-lg-4">
            {{ bid.created_at | date: 'MMM dd, YYYY, h:mm:ss a'}}
          </div>
        </div>
        <div class="row" *ngIf="!lastBidData">
          <div class="col-lg-4">
            <p>There have been no bids yet.</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="auctionEnded" class="text-center text-info mt-3">
      <h5><b>The auction has been finished!</b></h5>
    </div>

    <!-- <div class="row" *ngIf="currentUser && auctionEnded && lastBidData?.buyer_id === currentUser?.id">
      <div class="col-12">
        <div class="row item-detail">
          <div class="col-12">
            <div class="container">
              <div class="field"></div>
              <div class="add-to-cart-container">
                <button class="cart-buttons" (click)="buyItemAction()"> Buy item </button>
              </div>
              <div class="text-success mt-2" *ngIf="isAddToCart"><b>You has successfully add to cart please order now
                  from add to cart.</b></div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <ng-container *ngIf="!auctionEnded && currentUser?.id !== itemData?.collection.user_id">
    <div class="card-header py-3">
      <h5 class="mt-2 font-weight-bold text-primary txt-title mb-0">
        Sale Actions
      </h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6 p-t-5">
          <form class="user" [formGroup]="bidForm">
            <div class="form-group">
              <input type="number" step="0.01"
                [ngClass]="{ 'is-invalid': bidForm?.touched && bidForm?.controls.last_price?.errors }"
                class="form-control form-control-user" formControlName="last_price" [placeholder]="'0.00 ' + symbol"
                name="last_price" />
              <div *ngIf="bidForm?.touched && bidForm?.controls.last_price?.errors" class="invalid-feedback">
                <div *ngIf="bidForm?.controls.last_price.errors.required">
                  Bid amount is required to make offer
                </div>
                <div *ngIf="bidForm?.controls.last_price.errors.min">
                  Entered amount should be equal or greater than
                  {{bidForm?.controls.last_price.errors.min.min}} {{symbol}}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row center">
        <div class="col-md-6 p-t-5">
          <button class="btn btn-primary full-width" [disabled]="bidForm?.invalid" (click)="makeOffer()">
            BID NOW
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="!auctionStarted">
  <div class="card-body">
    <div class="row p-b-10">
      <div class="col-4 sub-title">Auction Start Date (Local):</div>
      <div class="col-6">
        {{ itemData.start_date | date: 'MMM dd, YYYY, h:mm:ss a' }}
      </div>
    </div>
    <div class="row p-b-10">
      <div class="col-4 sub-title">Auction End Date (Local):</div>
      <div class="col-6">
        {{ itemData.end_date | date: 'MMM dd, YYYY, h:mm:ss a'}}
      </div>
    </div>
  </div>
</ng-container>
