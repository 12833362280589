import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SellerResponse, Seller } from '../models/seller';

@Injectable({ providedIn: 'root' })
export class AdminService {
  private currentsellersForVerification: BehaviorSubject<any>;
  public sellersForVerification: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentsellersForVerification = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.sellersForVerification =
      this.currentsellersForVerification.asObservable();
  }

  public get currentSellersForVerification(): any {
    return this.currentsellersForVerification.value;
  }

  verifySeller(data: any) {
    return this.http.post(
      `${environment.systemLabsAPi}/users/seller-verify-request`,
      data
    );
  }

  approveSeller(seller: number, status) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.systemLabsAPi}/admin/sellers/action/${seller}?_method=PUT`, { status: status})
        .toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  approveCollection(collection: number, status) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.systemLabsAPi}/admin/collections/action/${collection}?_method=PUT`, { status: status})
        .toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
  getSoldOutCollections(page = 1, soldCollectionName?: string) {
    const params = new HttpParams()
    .set('page', `${page}`).append('search', `${soldCollectionName}`);
    // .set('limit', `${itemsPerPage}`);
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.systemLabsAPi}/admin/collections/sold`, {params})
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getCollectionsForApproval (page = 1, itemPerPage = 10) {
    const params = new HttpParams()
    .set('page', `${page}`)
    // .set('limit', `${itemsPerPage}`);
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.systemLabsAPi}/admin/collections/pending`, {params})
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getSellersForVerification(page = 1, itemPerPage = 10) {
    const params = new HttpParams()
    .set('page', `${page}`)
  // .set('limit', `${itemsPerPage}`);
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.systemLabsAPi}/admin/sellers/pending`, {params})
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getSelelrDetail(id: any):Observable<any>  {
    return this.http.get(
      `${environment.systemLabsAPi}/admin/sellers/${id}/details`
    );
  }
}
