<div *ngIf="itemData" class="item-detail">
  <div class="d-flex container-detail">
    <div class="container-image">
      <img [src]="itemData?.image_path" alt="Item Image" />
    </div>
    <div class="container">
      <div class="field">
        <h2>{{ itemData?.name }}</h2>
      </div>
      <div class="d-flex" *ngIf = "isAuctionOpen">
        <div class="available-for-sale-flag p-3" *ngIf="itemData?.available_for_sale === 2">
          On auction
        </div>
        <div
          class="available-for-sale-flag"
          *ngIf="itemData?.available_for_sale === 1"
        >
        Available for sale
        </div>
        <div
          class="not-available-for-sale-flag"
          *ngIf="itemData?.available_for_sale === 3"
        >
          Sold Out
        </div>
        <div
          class="not-available-for-sale-flag"
          *ngIf="itemData?.available_for_sale === 0"
        >
          Not Available
        </div>&nbsp;
        <div *ngIf="itemData?.available_for_sale === 2" class="pl-3">
          <h4>{{ itemData?.start_date | date: 'MMM dd, YYYY, h:mm:ss a'}} To <br>{{ itemData?.end_date | date: 'MMM dd, YYYY, h:mm:ss a'}}</h4>
        </div>
        <span *ngIf="itemData?.available_for_sale === 1">
          Available at: {{ itemData?.available_at | date: 'MMM dd, YYYY, h:mm:ss a'}}
        </span>
      </div>

      <h5>{{ itemData?.price | currency }}</h5>
      <div>Edition: {{ itemData?.edition }}</div>
      <p>
        {{ itemData?.description }}
      </p>
      <h5 class="auction-close" *ngIf = "!isAuctionOpen && itemData?.available_for_sale === 2">The auction has been closed</h5>
      <div *ngIf = "itemData?.available_for_sale === 1 && isAuctionOpen && isAdmin !== 'admin'">Current Count: {{ count }}</div>
      <div *ngIf="itemData?.available_for_sale === 1 && isAuctionOpen && isAdmin !== 'admin'" class="add-to-cart-container">
        <button
          *ngIf="!count"
          (click)="handleClickAddToCart()"
          class="cart-buttons"
        >
          Add to cart
        </button>
        <!-- <ng-container *ngIf="count"></ng-container> -->
          <!-- <button (click)="handleClickIncreaseBtn()" class="cart-buttons">
            +
          </button> -->
          <button
          *ngIf="count"
            (click)="handleClickDecreaseBtn()"
            class="cart-buttons"
          >
          Remove from cart
          </button>
        <span class="ml-5" *ngIf = "isAuctionOpen && isAdmin !== 'admin'">Total: {{ total | currency }}</span>
      </div>
    </div>
  </div>
</div>
