<div class="container page-top">
  <div class="row">
    <div class="col-12 p-t-10 p-b-6">
      <div class="section-title">
        <span class="icon crown"></span>
        <span>Rankings</span>
      </div>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>
  <div class="row">
    <div class="col">
      <div class="mx-auto text-center mt-4 text-white">
        <h2>Coming soon</h2>
      </div>
    </div>
  </div>
</div>
