import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommentService } from 'src/app/services/comment.service'

@Component({
  selector: 'app-user-comment-list',
  templateUrl: './user-comment-list.component.html',
  styleUrls: ['./user-comment-list.component.scss']
})
export class UserCommentListComponent implements OnInit {
  @Input() admin: boolean;

  @Output() reloadComment: EventEmitter<boolean> = new EventEmitter<boolean>();

  collectionSize = 0
  currentPage = 1;
  itemsPerPage = 10;
  commentNumber: number = 0;

  commentForApproval = [];

  constructor(private commentService: CommentService) { }

  ngOnInit(): void {
    this.getCommentForApproval(this.currentPage, this.itemsPerPage);
  }


  getCommentForApproval(page: number, perItemPage: number) {
    this.commentService.getCommentForApproval(page, perItemPage).subscribe(
        response => {
            this.commentForApproval = response.data;
            this.currentPage = response.current_page;
            this.collectionSize = response.total;
            this.itemsPerPage = response.per_page;
            this.commentNumber = (this.currentPage - 1) * this.itemsPerPage;
        },
        err => {}
    );
  }

  onPageChange(page): void {
    this.getCommentForApproval(page, this.itemsPerPage);
  }

  changeCommentStatus(id: number, status: string) {
    let data = {'status': status};
    this.commentService.changeCommentStatus(id, data).subscribe(
      response => {
        this.getCommentForApproval(this.currentPage, this.itemsPerPage);

      },
      err => {

      }
    )

  }

}
