import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MathService } from '../../services/math.service';
import { NftService } from '../../services/nft.service';
import { WalletService } from '../../services/wallet.service';
import { Network } from '../../types/network.enum';
import { environment } from 'src/environments/environment';
import { Moralis } from 'moralis';

@Component({
  selector: 'app-create-auction',
  templateUrl: './create-auction.component.html',
  styleUrls: ['./create-auction.component.scss'],
})
export class CreateAuctionComponent implements OnInit {
  data: any = {};
  preData: any = {};
  id;
  network;
  showSwitchToMatic;
  buyNowOption;
  buyNowPrice;
  minPrice;
  isApproved = false;
  loading = false;
  selectedDate;
  enoughBalance = true;
  canApprove = false;
  paymentCurrency;
  address;
  currencyDropDownMatic = [
    {
      name: 'DIGI',
      id: environment.maticCoinContractAddresses.digiAddressMatic,
    },
    {
      name: 'USDC',
      id: environment.maticCoinContractAddresses.usdcAddressMatic,
    },
    {
      name: 'USDT',
      id: environment.maticCoinContractAddresses.usdtAddressMatic,
    },
    {
      name: 'LINK',
      id: environment.maticCoinContractAddresses.linkAddressMatic,
    },
    /* { name: 'MATIC', id: environment.maticCoinContractAddresses.maticCoinAddress },
    { name: 'ETH', id: environment.maticCoinContractAddresses.ethAddressMatic }, */
  ];
  currencyDropDownEth = [
    { name: 'DIGI', id: environment.ethCoinContractAddresses.digiEthAddress },
    { name: 'USDC', id: environment.ethCoinContractAddresses.usdcEthAddress },
    { name: 'USDT', id: environment.ethCoinContractAddresses.usdtEthAddress },
    /* { name: 'MATIC', id: environment.ethCoinContractAddresses.maticEthAddress },
    { name: 'ETH', id: environment.ethCoinContractAddresses.ethCoinAddress }, */
  ];
  fee;
  Odet;
  royaltyFee;
  hasRoyalty = false;
  isYours;
  listingPrice;
  receiveAmount;
  listingPriceBuyNow;
  receiveAmountBuyNow;
  allowedCurrency;
  currencyDropDownValue;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly wallet: WalletService,
    private readonly cdr: ChangeDetectorRef,
    private readonly nft: NftService,
    private readonly router: Router,
    private readonly math: MathService
  ) {}

  ngOnInit(): void {
    this.setDefault();

    this.route.params.subscribe((queryParams) => {
      this.preData = {
        id: queryParams.id,
        network: queryParams.network,
        tokenAddress: queryParams.tokenAddress,
      };
    });
    this.loadData();
  }
  currencyHandler() {
    this.checkAllowed()
  }

  setDefault () {
    this.paymentCurrency = this.currencyDropDownMatic[0].id
  }

  async loadData(): Promise<void> {
    let chain;
    if (environment.production) {
      chain = 'Matic';
    } else {
      chain = 'mumbai';
    }
    this.data = {
      ...(await Moralis.Web3API.token.getTokenIdMetadata({
        address: this.preData.tokenAddress,
        token_id: this.preData.id,
        chain: chain,
      })),
      ...this.preData,
    };
    this.checkApprove();
    this.checkAllowed();
    this.checkMinAmount();
    this.loadFees();
    this.loadRoyalty();
    this.getCardDetails();
  }

  async getCardDetails(): Promise<void> {
    this.address = await this.wallet.getAccount();
    if (this.data.token_uri) {
      let url = this.data.token_uri;
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          this.data.image = data.image;
          this.data.name = data.name;
          this.data.description = data.description;
          this.data.owner = this.data.owner_of;
          this.data.ownerAddress = this.data.owner_of;
          this.data.network = this.data.network;
          this.data.physical = data.physical;
          this.data.tokenAddress = this.data.token_address;
          if (this.data.owner_of.toLowerCase() === this.address.toLowerCase()) {
            this.isYours = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async loadFees(): Promise<void> {
    this.fee = await this.nft.getFee(this.data.network);
  }

  async loadRoyalty(): Promise<void> {
    this.hasRoyalty = await this.nft.hasRoyalty(
      this.data.id,
      this.data.network,
      this.data.tokenAddress
    );
    if (this.hasRoyalty) {
      this.royaltyFee =
        (await this.nft.getRoyaltyFee(this.data.id, this.data.network)) / 100;
    }
  }

  async checkMinAmount(): Promise<void> {
    const digiBalance = await this.nft.digiBalance();
    if (digiBalance < 3000 * 10 ** 18) {
      this.enoughBalance = false;
    } else {
      this.enoughBalance = true;
    }
  }
  async checkAllowed() {
    this.allowedCurrency = await this.nft.checkAllowedForAuction(environment.auctionAddressMatic, this.data.network, this.paymentCurrency);
  }

  async approveCurrency(): Promise<void> {
    this.loading = true;
    let currency;
    try {
      await this.nft.approve(
        await this.nft.getAuctionAddress(),
        this.data.network,
        this.paymentCurrency
      );
    } catch (e) {
      console.log(e);
    }
    this.checkAllowed();
    this.loading = false;
  }

  async checkApprove(): Promise<void> {
    this.isApproved = await this.nft.isApprovedForAll(
      await this.wallet.getAccount(),
      await this.nft.getAuctionAddress(),
      this.data.network,
      this.data.tokenAddress
    );
    console.log(this.isApproved);
    
    this.cdr.detectChanges();
  }

  async approve(): Promise<void> {
    this.loading = true;
    try {
      await this.nft.setApprovalForAll(
        await this.nft.getAuctionAddress(),
        this.data.network,
        this.data.tokenAddress
      );
      this.checkApprove();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  onChangeInputAmount(): void {
    this.checkIfCanApprove();
    setTimeout(() => {
      this.listingPrice = Number(this.minPrice) * 1.05;
      if (this.hasRoyalty) {
        this.receiveAmount =
          this.minPrice * 0.95 - this.minPrice * (this.royaltyFee / 100);
      } else {
        this.receiveAmount = this.minPrice - this.minPrice * 0.1;
      }
      this.receiveAmount = this.receiveAmount.toFixed(2);
    }, 100);
  }

  onChangeBuyNowInputAmount(): void {
    this.checkIfCanApprove();
    setTimeout(() => {
      this.listingPriceBuyNow = Number(this.buyNowPrice) * 1.05;
      if (this.hasRoyalty) {
        this.receiveAmountBuyNow =
          this.buyNowPrice * 0.95 - this.buyNowPrice * (this.royaltyFee / 100);
      } else {
        this.receiveAmountBuyNow = this.buyNowPrice - this.buyNowPrice * 0.1;
      }
      this.receiveAmountBuyNow = this.receiveAmountBuyNow.toFixed(2);
    }, 100);
  }

  async create(): Promise<void> {
    this.loading = true;

    const currentDate = parseInt(new Date().getTime() / 1000 + '', undefined);

    const endDate = parseInt(
      this.selectedDate.getTime() / 1000 + '',
      undefined
    );

    const duration = endDate - currentDate;

    try {
      let buyNowPrice = '0';
      if (this.buyNowOption) {
        buyNowPrice = this.math.toBlockchainValue(this.listingPriceBuyNow);
      }
      await this.nft.createAuction(
        this.data.id,
        this.math.toBlockchainValue(this.listingPrice),
        buyNowPrice,
        duration,
        this.data.network,
        this.paymentCurrency,
        this.data.tokenAddress
      );
      this.router.navigate(['/auctions']);
    } catch (e) {
      console.error(e);
    }

    this.loading = false;
  }

  async switchToMatic(): Promise<void> {
    await this.wallet.switchToMatic();
  }

  async switchToEth(): Promise<void> {
    await this.wallet.switchToEth();
  }

  async checkIfCanApprove(): Promise<void> {
    if (this.buyNowOption) {
      this.canApprove =
        this.buyNowPrice !== '' &&
        this.minPrice !== '' &&
        typeof this.selectedDate === 'object' &&
        this.selectedDate instanceof Date;
    }

    this.canApprove =
      this.minPrice !== '' &&
      typeof this.selectedDate === 'object' &&
      this.selectedDate instanceof Date;
  }
}
